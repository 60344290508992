import { Link } from 'react-router-dom';
import { getFormattedPhone , Utils } from 'src/shared/lib';
import { ColumnsType } from 'antd/lib/table/interface';
import { DateType } from 'src/shared/ui/datePicker';
import { RenderLegalStatus } from 'src/shared/ui/renderLegalStatus';

import { LegalEntity } from './types';

export const mainColumnName = 'name';

export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export const columnsToShow = ['name', 'inn', 'point_id', 'ceo_last_name', 'registered_at', 'creator', 'status'];

export const typeSelectOptions = [
  { value: 'USER', label: 'Пользователь' },
  { value: 'SUPPLIER', label: 'Поставщик' },
];

export const statuses = {
  not_registered: 'Не зарегистрирован',
  registered: 'Зарегистрирован',
  registration_error: 'Ошибка регистрации',
};

export const statusOptions = Object.entries(statuses).map(([key, value]) => ({
  label: value,
  value: key,
}));

export const columns: ColumnsType<LegalEntity> = [
  {
    title: 'Сокращенное наименование',
    dataIndex: 'name',
    key: 'name',
    render: (data, { id }) =>
      data ? (
        <Link to={`/profile/legalEntity/${id}`} state={{ prevPath: true }}>
          {data}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Полное наименование',
    dataIndex: 'full_name',
    key: 'full_name',
    render: data => data || '-',
  },
  {
    title: 'Код предприятия',
    dataIndex: 'point_id',
    key: 'point_id',
    render: data => data || '-',
  },
  {
    title: 'ИНН',
    dataIndex: 'inn',
    key: 'inn',
    render: data => data || '-',
  },
  {
    title: 'Дата регистрации юр.лица в системе',
    dataIndex: 'registered_at',
    key: 'registered_at',
    render: (data: DateType) => Utils.dateFormat(data),
  },
  {
    title: 'ФИО руководителя',
    dataIndex: 'ceo_last_name',
    key: 'ceo_last_name',
    render: (data, { ceo_first_name: ceoFirstName }) => (data ? `${ceoFirstName} ${data}` : '-'),
  },
  {
    title: 'Ответственный',
    dataIndex: 'creator',
    key: 'creator',
    render: (value, data) =>
      value && data.creator_type === 'SUPPLIER' ? (
        <Link to={`/profile/supplier/${value.id}`} state={{ prevPath: true }}>
          {value?.name || '-'}
        </Link>
      ) : (
        value?.name || '-'
      ),
  },
  {
    title: 'Телефон',
    dataIndex: 'ceo_phone',
    key: 'ceo_phone',
    render: data => getFormattedPhone(data) || '-',
  },
  {
    title: 'Веб-сайт',
    dataIndex: 'site_url',
    key: 'site_url',
    render: data =>
      data ? (
        <a href={data} target="_blank" rel="noreferrer">
          {data}
        </a>
      ) : (
        '-'
      ),
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    render: status => (status ? RenderLegalStatus(status) : '-'),
  },
];

export const initColumnSettings = columns.reduce((prev, curr) => {
  if (curr.key && columnsToShow.includes(curr.key.toString())) {
    prev[curr.key] = true;
  } else if (curr.key) {
    prev[curr.key] = false;
  }
  return prev;
}, {} as Record<string, boolean>);
