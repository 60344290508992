import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { Table, Spin, Empty, Card, Input, Tooltip, Button, Dropdown } from 'antd';
import { ClearOutlined, MoreOutlined } from '@ant-design/icons';
import { Hooks , getNumbers } from 'src/shared/lib';
import { NumberTypeEnum } from 'src/shared/types';
import { TableSettings } from 'src/entities/tableSettings';
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface';
import { ChangeLegalEntity } from 'src/features/changeLegalEntity';
import { MainModal } from 'src/shared/ui';
import { changeSuppLegalFx } from 'src/features/changeLegalEntity/model';
import { initStateLegal, defaultInitState, PER_PAGE } from 'src/shared/config';

import {
  $suppliers,
  $pavilions,
  $legalEntities,
  $sources,
  $visibleColumns,
  initPagePavilions,
  initPageSources,
  submitChangeRequestData,
  submitLegalData,
  changeVisibleColumns,
  getSuppliersFx,
  createSupplierFx,
  submitCreation,
  $isOpenModal,
  handleModal,
  $pageSize,
  changePageSize,
} from './model';
import { columns, mainColumnName, defaultRowSelectValue, nullLegalId, statusOptions } from './config';
import { Supplier } from './types';
import { EditModal } from './ui';
import { LegalEntityDetailed } from '../legalEntitiesProfile/types';
import { useSupplierStatuses } from './hooks';
import styles from './ProfilesSuppliers.module.less';

interface ProfilesSuppliersProps {
  id?: string;
  legalProfile?: LegalEntityDetailed;
}

export const ProfilesSuppliers = ({ id, legalProfile }: ProfilesSuppliersProps) => {
  const [nameValue, setNameValue] = useState('');
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const isOpenCreation = useStore($isOpenModal);
  const suppliers = useStore($suppliers);
  const pavilions = useStore($pavilions);
  const legalEntities = useStore($legalEntities);
  const sources = useStore($sources);
  const loadingCreation = useStore(createSupplierFx.pending);
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const visibleColumnsKeys = useStore($visibleColumns);
  const isLoading = useStore(getSuppliersFx.pending);
  const isChangeLegalLoading = useStore(changeSuppLegalFx.pending);
  const pageSize = useStore($pageSize);
  const [searchParams, setSearchParams] = Hooks.useSearchParamsObject();
  const [isModal, setIsModal] = useState(false);
  const [selectedSuppIds, setSelectedSuppIds] = useState<string[] | number[]>([]);

  useSupplierStatuses();
  
  useEffect(() => {
    initPagePavilions(defaultInitState);
    initPageSources(defaultInitState);
    submitLegalData(initStateLegal);
    
  }, []);

  useEffect(() => {
    const { page, suppId, statuses, ...filters } = searchParams;
    const resultStatuses = statuses ? statuses.split(',') : [];
    const currPage = page ? +page : 1;
    const resultIds = suppId ? [suppId] : [];

    const reqData = id
      ? {
          ...filters,
          statuses: resultStatuses,
          ...{ legal_entity_id: id },
          pagination: { page: currPage, per_page: pageSize },
          ids: resultIds,
        }
      : { ...filters, statuses: resultStatuses, pagination: { page: currPage, per_page: pageSize }, ids: resultIds };

    submitChangeRequestData(reqData);
  }, [searchParams, pageSize, id]);

  useEffect(() => {
    const resultVisibleColumns: ColumnsType<Supplier> = [];

    columns.forEach(column => {
      if (
        column.key &&
        (!Object.prototype.hasOwnProperty.call(visibleColumnsKeys, column.key) || visibleColumnsKeys[column.key])
      ) {
        resultVisibleColumns.push(column);
      }
    });

    setVisibleColumns(resultVisibleColumns);
  }, [visibleColumnsKeys]);

  const searchNameHandler = (value: string) => {
    setSearchParams({ ...searchParams, name: value || null, page: 1 });
  };

  const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => setNameValue(e.target.value);

  const searchEmailHandler = (value: string) => {
    setSearchParams({ ...searchParams, email: value || null, page: 1 });
  };

  const searchPhoneHandler = (value: string) => {
    setSearchParams({ ...searchParams, phone: value ? getNumbers(value) : null, page: 1 });
  };

  const handleChangePage = (value: number) => {
    setSearchParams({ ...searchParams, page: value.toString() });
  };

  const handleChangePageSize = (currentSize: number, size: number) => {
    changePageSize(size);
  };

  const handleResetFilters = () => {
    setSearchParams({ page: 1 });
    setIsActiveFilter(true);
    setNameValue('');
    submitLegalData(initStateLegal);
  };

  const searchPavilionHandler = (value: string) => {
    const reqData = value ? { ...defaultInitState, query: value } : { ...defaultInitState };
    initPagePavilions(reqData);
  };

  const handleSearchLegal = (value: string) => {
    const reqData = value && value !== nullLegalId ? { ...initStateLegal, full_name: value } : { ...initStateLegal };
    submitLegalData(reqData);
  };

  const handleSearchSource = (value: string) => {
    const reqData = value ? { ...defaultInitState, search: value } : { ...defaultInitState };
    initPageSources(reqData);
  };

  const handleSelectPavilion = (value: number) => {
    setSearchParams({ ...searchParams, pavilion_id: value, page: 1 });
  };

  const handleSelectLegal = (value: number) => {
    const resultValue = value || nullLegalId;
    setSearchParams({ ...searchParams, legal_entity_id: resultValue, page: 1 });
  };

  const handleSelectSource = (value: number) => {
    const data = sources.find(source => source.id === value);
    setSearchParams({ ...searchParams, suppId: data?.supplier_id || '', page: 1 });
  };

  const resetSourceHandler = () => {
    const filters = { ...searchParams };
    delete filters.suppId;
    setSearchParams({ ...filters, page: 1 });
  };

  const resetPavilionHandler = () => {
    const { pavilion_id: pavilionId, ...restParams } = searchParams;
    setSearchParams({ ...restParams, page: 1 });
  };

  const resetLegalHandler = () => {
    const filters = { ...searchParams };
    delete filters.legal_entity_id;
    setSearchParams({ ...filters, page: 1 });
    submitLegalData(initStateLegal);
  };

  const handleSelectStatus = (value: string[]) =>
    setSearchParams({ ...searchParams, statuses: value || null, page: 1 });

  const handleLegalModal = (value: boolean) => setIsModal(value);

  const handleSelectChange = (selectedRowKeys: React.Key[], selectedRows: Supplier[]) => {
    const ids = selectedRows.map(({ id }) => id);
    setSelectedSuppIds(ids);
  };

  const rowSelection: TableRowSelection<Supplier> = {
    checkStrictly: true,
    onChange: handleSelectChange,
  };

  const getEmailSearch = Hooks.useColumnSearch<Supplier>(
    searchEmailHandler,
    'email',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.email,
  );

  const getPhoneSearch = Hooks.useColumnSearch<Supplier>(
    searchPhoneHandler,
    NumberTypeEnum.PHONE,
    isActiveFilter,
    setIsActiveFilter,
    searchParams.phone,
  );

  const getPavilionsSearch = Hooks.useColumnAutocomplete<Supplier>(
    searchPavilionHandler,
    handleSelectPavilion,
    resetPavilionHandler,
    pavilions?.items || [],
    isActiveFilter,
    setIsActiveFilter,
  );

  const getSourcesSearch = Hooks.useColumnAutocomplete<Supplier>(
    handleSearchSource,
    handleSelectSource,
    resetSourceHandler,
    sources,
    isActiveFilter,
    setIsActiveFilter,
  );

  const getLegalSearch = Hooks.useColumnAutocomplete<Supplier>(
    handleSearchLegal,
    handleSelectLegal,
    resetLegalHandler,
    legalEntities,
    isActiveFilter,
    setIsActiveFilter,
    true,
  );

  const getStatusSearch = Hooks.useColumnMultiSelect<Supplier>(
    handleSelectStatus,
    statusOptions,
    'Выбрать статус',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.status,
  );

  const filteredColumns: ColumnsType<Supplier> = visibleColumns.map(column => {
    switch (column.key) {
      case 'email':
        return { ...column, ...getEmailSearch(column.key, column.title) };
      case 'phone':
        return { ...column, ...getPhoneSearch(column.key, column.title) };
      case 'pavilions':
        return { ...column, ...getPavilionsSearch(column.key, column.title) };
      case 'sources':
        return { ...column, ...getSourcesSearch(column.key, column.title) };
      case 'legalEntity':
        return { ...column, ...getLegalSearch(column.key, column.title) };
      case 'status':
        return { ...column, ...getStatusSearch(column.key, column.title) };
      default:
        return column;
    }
  });

  const resultColumns = legalProfile
    ? [
        ...filteredColumns,
        {
          title: '',
          key: 'settings',
          render: (data: Supplier) => (
            <Dropdown
              overlayClassName={styles.menu}
              placement="bottom"
              menu={{
                items: [
                  {
                    key: 'edit',
                    label: 'Сменить юр. лицо',
                    onClick: () => {
                      setSelectedSuppIds([data.id]);
                      handleLegalModal(true);
                    },
                  },
                ],
              }}>
              <MoreOutlined />
            </Dropdown>
          ),
        },
      ]
    : filteredColumns;

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={`${styles[`${id ? 'legal' : ''}`]}`}>
      <Card className={styles.body}>
        {suppliers ? (
          <>
            <div className={styles.searchSettings}>
              <Input.Search
                value={nameValue}
                className={styles.search}
                placeholder="Поиск"
                onChange={handleSearchName}
                onSearch={searchNameHandler}
                allowClear
              />
              <div className={styles.wrapper}>
                {legalProfile ? (
                  <Button
                    className={styles.button}
                    onClick={() => handleLegalModal(true)}
                    loading={isChangeLegalLoading}
                    disabled={!selectedSuppIds.length}>
                    Сменить юр. лицо
                  </Button>
                ) : null}
                {!id ? (
                  <Button type="primary" onClick={() => handleModal(true)}>
                    Добавить поставщика
                  </Button>
                ) : null}
                <Tooltip overlayStyle={{ position: 'fixed' }} title="Очистить все фильтры">
                  <ClearOutlined className={styles.clear} onClick={handleResetFilters} />
                </Tooltip>
                <TableSettings
                  columns={columns}
                  disabledColumns={[mainColumnName]}
                  visibleColumnsKeys={visibleColumnsKeys}
                  setVisibleColumnsKeys={changeVisibleColumns}
                />
              </div>
            </div>

            <Table
              rowSelection={legalProfile ? rowSelection : defaultRowSelectValue}
              rowKey={record => record.id}
              bordered
              scroll={{ x: 1200 }}
              columns={resultColumns}
              dataSource={suppliers?.items || []}
              pagination={{
                onChange: handleChangePage,
                onShowSizeChange: handleChangePageSize,
                current: suppliers?.pagination?.current_page || 1,
                pageSize: suppliers?.pagination?.per_page || PER_PAGE,
                total: suppliers?.pagination?.total || 0,
                showSizeChanger: true,
              }}
              locale={{ emptyText: <Empty description="Нет данных" /> }}
            />
          </>
        ) : (
          <Empty description="Поставщики не найдены" />
        )}
        <EditModal
          isOpen={isOpenCreation}
          loading={loadingCreation}
          onClose={() => handleModal(false)}
          handleSupplier={submitCreation}
        />
      </Card>
      {legalProfile ? (
        <MainModal isOpen={isModal} closeHandler={() => handleLegalModal(false)}>
          <ChangeLegalEntity
            selectedSuppIds={selectedSuppIds}
            legalProfile={legalProfile}
            closeHandler={() => handleLegalModal(false)}
          />
        </MainModal>
      ) : null}
    </div>
  );
};
