import { useEffect, useRef, useState } from 'react';
import { Button, Space, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnTitle } from 'antd/lib/table/interface';
import type { InputRef } from 'antd';
import type { BaseSelectRef } from 'rc-select';
import type { ColumnType } from 'antd/es/table';
import { defaultSelectValue } from 'src/shared/config';

import styles from './useColumn.module.less';

function useColumnMultiSelect<DataType>(
  selectHandler: (value: string[]) => void,
  options: { label: string; value: string }[],
  placeholder: string,
  isActiveFilter?: boolean,
  setIsActiveFilter?: (value: boolean) => void,
  defaultValue?: string | number,
  defaultValues?: string[],
) {
  const searchInput = useRef<InputRef>(null);
  const selectRef = useRef<BaseSelectRef>(null);
  const [value, setValue] = useState<string[]>([]);
  const [isActive, setIsActive] = useState(!!defaultValue);

  useEffect(() => {
    if (defaultValues) {
      setValue(defaultValues);
      setIsActive(true);
    }
  }, []);

  useEffect(() => {
    if (isActiveFilter && setIsActiveFilter) {
      setValue([]);
      setIsActive(false);
      setIsActiveFilter(false);
    }
  }, [isActiveFilter, setIsActiveFilter]);

  const handleSelectSearch = () => {
    selectHandler(value);
    setIsActive(true);
  };

  const handleMultiSelect = (value: string[]) => {
    setValue(value);
    if (selectRef?.current) {
      selectRef.current.blur();
    }
  };

  const handleReset = (clearFilters: () => void) => {
    setValue([]);
    selectHandler([]);
    clearFilters();
    setIsActive(false);
  };

  const getColumnSearchProps = (dataIndex: keyof DataType, titleText: ColumnTitle<DataType>): ColumnType<DataType> => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className={styles.useColumnMultiSelect}>
        <Select
          ref={selectRef}
          mode="multiple"
          value={value.length ? value : defaultSelectValue}
          placeholder={placeholder}
          onChange={handleMultiSelect}
          options={options}
          placement="topLeft"
        />
        <Space>
          <Button
            onClick={handleSelectSearch}
            type="primary"
            icon={<SearchOutlined />}
            form="search"
            key="submit"
            size="small">
            Поиск
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
            Сбросить
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => <SearchOutlined style={{ color: isActive ? '#597393' : '' }} />,
    onFilter: (value, record) =>
      String(record[dataIndex])
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    title: () => <span style={{ textDecoration: isActive ? 'underline' : '' }}>{titleText?.toString()}</span>,
  });

  return getColumnSearchProps;
}

export { useColumnMultiSelect };
