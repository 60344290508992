export const getSearchTypeName = (data: string) => {
  switch (data) {
    case 'email':
      return 'email';
    case 'phone':
      return 'телефону';
    case 'price':
      return 'стоимости';
    case 'count':
      return 'количеству';
    case 'client':
      return 'клиенту';
    case 'client.name':
      return 'клиенту';
    case 'code':
      return 'номеру';
    case 'user':
      return 'пользователю';
    case 'name':
      return 'названию';
    case 'ceo_last_name':
      return 'ФИО руководителя';
    case 'ceo_phone':
      return 'телефону';
    case 'site_url':
      return 'веб-сайту';
    case 'product_id':
      return 'ID товара';
    case 'created_by':
      return 'ответственному';
    case 'pavilions':
    case 'pavilion':
    case 'pavilion_name':
      return 'павильону';
    case 'legalEntity':
      return 'юр.лицу';
    case 'categories':
      return 'категории';
    case 'supplier':
    case 'supplier_name':
      return 'поставщику';
    case 'sources':
      return 'источнику';
    case 'order_id':
      return 'номеру заказа';
    case 'amount':
      return 'сумме операции';
    case 'bank_commission':
      return 'комиссии банка';
    case 'market_place_commission':
      return 'комиссии МП';
    case 'market_place_amount':
      return 'к перечислению МП';
    case 'legal_entity_amount':
      return 'к перечислению организации';
    case 'id':
      return 'payment ID';
    case 'client_payment_id':
      return 'payment ID';
    case 'terminal_name':
      return 'терминалу';
    case 'source':
      return 'источнику';
    case 'error':
      return 'ошибке';
    case 'url':
      return 'ссылке';
    case 'full_name':
      return 'полному наименованию';
    case 'point_id':
      return 'Shop ID';
    case 'inn':
      return 'ИНН';
    case 'creator':
      return 'ответственному';
    case 'legalEntity.full_name':
      return 'по юрлицу';
    default:
      return data;
  }
};
