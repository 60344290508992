import { createStore, createEffect, createEvent, sample } from 'effector';
import { tasksApi } from 'src/shared/api';
import { setSuccess, setError } from 'src/features/notifications';
import { AxiosError } from 'axios';
import { v4 as uuid } from 'uuid';

import { DetailedTask, TaskQr } from './types';
import { initReqData } from './config';

export const $task = createStore<DetailedTask | null>(null);
export const $tasksId = createStore<string>(initReqData);
export const $qrCode = createStore<TaskQr | null>(null);

export const initTaskPage = createEvent<string>();
export const submitTransTaskData = createEvent<tasksApi.TransTaskReqData>();
export const submitRefuseTaskData = createEvent<string>();
export const refetchTaskData = createEvent();
export const submitTasksQrReqData = createEvent<string>();
export const clearQrData = createEvent();

export const getTaskFx = createEffect(tasksApi.getTask);
export const transTaskFx = createEffect<tasksApi.TransTaskReqData, Object, AxiosError<{ message: string }>>(
  tasksApi.transferTask,
);
export const getTasksQrFx = createEffect<string, TaskQr, AxiosError<{ message: string }>>(tasksApi.getTasksQr);

$task.on(getTaskFx.doneData, (_, data: DetailedTask) => {
  const history = data?.history?.map(item => ({ ...item, id: uuid() })) || [];
  const resultData = { ...data, history };
  return resultData;
});
$tasksId.on(initTaskPage, (_, data) => data);
$qrCode.on(getTasksQrFx.doneData, (_, data) => data);
$qrCode.reset(clearQrData);

sample({
  clock: [initTaskPage, refetchTaskData],
  source: $tasksId,
  target: getTaskFx,
});

sample({
  clock: submitTransTaskData,
  target: transTaskFx,
});

sample({
  clock: submitTasksQrReqData,
  target: getTasksQrFx,
});

sample({
  clock: [transTaskFx.doneData],
  source: $tasksId,
  target: getTaskFx,
});

sample({
  clock: transTaskFx.doneData,
  fn: () => 'Количество товара успешно изменено',
  target: setSuccess,
});

sample({
  clock: [transTaskFx.failData, getTasksQrFx.failData],
  fn: data => data.response?.data?.message || 'Ошибка!',
  target: setError,
});
