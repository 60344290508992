import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Input, Spin, Dropdown, Tooltip, Empty, Button } from 'antd';
import { MoreOutlined, ClearOutlined } from '@ant-design/icons';
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface';
import { NumberTypeEnum } from 'src/shared/types';
import { Product, Clients } from 'src/pages/products';
import { Hooks , getFinalFilterPrice , getIsoTimeZoneTime } from 'src/shared/lib';
import { TableSettings } from 'src/entities/tableSettings';
import { useStore } from 'effector-react';


import {
  $visibleColumns,
  changeVisibleColumns,
  initPage,
  $categories,
  $suppliers,
  submitChangeSuppData,
} from './model';
import { columns, mainColumnName, PER_PAGE, initState, selectStatusOptions } from './config';
import styles from './ProductsTable.module.less';

const { Search } = Input;

interface ProductsTableProps {
  isLoading: boolean;
  data?: Product[];
  clients?: Clients[];
  withSearch?: boolean;
  Settings?: JSX.Element;
  filters?: { [key: string]: string };
  pagination?: { current_page: number; total: number; per_page: number };
  handleChangePage?: (value: number, size: number) => void;
  handleChangePageSize?: (currentSize: number, size: number) => void;
  handleSearch?: (obj: { [key: string]: string | string[] }, type?: string) => void;
  resetSearch?: (type: string[]) => void;
  resetFilters: () => void;
  handleActivity?: (id: string[]) => void;
  type?: string;
  isSuppProducts?: boolean;
}

export const ProductsTable = ({
  data,
  clients,
  isLoading,
  withSearch = true,
  Settings,
  pagination,
  filters,
  handleChangePage,
  handleChangePageSize,
  handleSearch,
  resetSearch,
  resetFilters,
  handleActivity,
  type,
  isSuppProducts,
}: ProductsTableProps) => {
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const visibleColumnsKeys = useStore($visibleColumns);
  const [searchValue, setSearchValue] = useState('');
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const categories = useStore($categories);
  const suppliers = useStore($suppliers);

  useEffect(() => {
    initPage();
    submitChangeSuppData(initState);
  }, []);

  const searchPriceHandler = (value: string): void => {
    if (value) {
      const resultValue = getFinalFilterPrice(value);
      handleSearch?.({ price: resultValue });
    } else {
      resetSearch?.(['price']);
    }
  };

  const handleResetFilters = () => {
    resetFilters();
    setIsActiveFilter(true);
    setSearchValue('');
    submitChangeSuppData(initState);
  };

  const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  const searchCreatedDateHandler = (data: { date_after: string; date_to: string }) => {
    if (data.date_after || data.date_to) {
      handleSearch?.({
        created_at_from: data?.date_after ? getIsoTimeZoneTime({ day: data.date_after }, 'from') : '',
        created_at_to: data?.date_to ? getIsoTimeZoneTime({ day: data.date_after }, 'to') : '',
      });
    } else {
      resetSearch?.(['created_at_from', 'created_at_to']);
    }
  };

  const searchSupplierHandler = (value: string) => {
    const reqData = value ? { ...initState, name: value } : initState;
    submitChangeSuppData(reqData);
  };

  const resetSupplierHandler = () => {
    resetSearch?.(['supplier_id']);
    submitChangeSuppData(initState);
  };

  const handleSelectSupplier = (value: number) =>
    value ? handleSearch?.({ supplier_id: value.toString() }) : resetSearch?.(['supplier_id']);

  const searchCategoryHandler = (value: string) =>
    value ? handleSearch?.({ category_id: value }) : resetSearch?.(['category_id']);

  const handleMainSearch = (value: string) =>
    value ? handleSearch?.({ name: value }, 'name') : resetSearch?.(['name']);

  const getNameSearch = Hooks.useColumnSearch<Product>(handleMainSearch, 'code', isActiveFilter, setIsActiveFilter);

  const handleSelectStatus = (value: string[]) =>
    value ? handleSearch?.({ statuses: value }) : resetSearch?.(['statuses']);

  const getPriceSearch = Hooks.useColumnSearch<Product>(
    searchPriceHandler,
    NumberTypeEnum.PRICE,
    isActiveFilter,
    setIsActiveFilter,
    filters?.price,
  );

  const getDateSearch = Hooks.useColumnSearchDate<Product>(searchCreatedDateHandler, isActiveFilter, setIsActiveFilter);

  const getCategorySearch = Hooks.useColumnTreeSelect<Product>(
    searchCategoryHandler,
    categories?.items || [],
    'category',
    isActiveFilter,
    setIsActiveFilter,
    filters?.created_at,
  );

  const getSupplierSearch = Hooks.useColumnAutocomplete<Product>(
    searchSupplierHandler,
    handleSelectSupplier,
    resetSupplierHandler,
    suppliers?.items || [],
    isActiveFilter,
    setIsActiveFilter,
  );

  const getStatusSearch = Hooks.useColumnMultiSelect<Product>(
    handleSelectStatus,
    selectStatusOptions,
    'Выбрать статус',
    isActiveFilter,
    setIsActiveFilter,
  );

  useEffect(() => {
    const resultVisibleColumns: ColumnsType<Product> = [];

    columns.forEach(column => {
      if (
        column.key &&
        (!Object.prototype.hasOwnProperty.call(visibleColumnsKeys, column.key) || visibleColumnsKeys[column.key])
      ) {
        resultVisibleColumns.push(column);
      }
    });

    setVisibleColumns(resultVisibleColumns);
  }, [visibleColumnsKeys]);

  const handleSelectChange = (selectedRowKeys: React.Key[], selectedRows: Product[]) => {
    const ids = selectedRows.map(({ id }) => id);
    setSelectedProducts(ids);
  };

  const rowSelection: TableRowSelection<Product> = {
    checkStrictly: true,
    onChange: handleSelectChange,
  };

  const columnsWithFilters: ColumnsType<Product> = visibleColumns.map(column => {
    switch (column.key) {
      case 'name':
        return { ...column, ...getNameSearch(column.key, column.title) };
      case 'categories':
        return { ...column, ...getCategorySearch(column.key, column.title) };
      case 'price':
        return { ...column, ...getPriceSearch(column.key, column.title) };
      case 'supplier':
        return { ...column, ...getSupplierSearch(column.key, column.title) };
      case 'created_at':
        return { ...column, ...getDateSearch(column.key, column.title) };
      case 'status':
        return isSuppProducts ? { ...column, ...getStatusSearch(column.key, column.title) } : column;
      default:
        return column;
    }
  });

  const handleProductsActivity = (id: string) => handleActivity && handleActivity([id]);

  const handleProductsStatuses = () => handleActivity && handleActivity(selectedProducts);

  const resultColumns = type
    ? [
        ...columnsWithFilters,
        {
          title: '',
          key: 'settings',
          render: (_: Product, data: { id: string }) => (
            <Dropdown
              overlayClassName={styles.menu}
              placement="bottom"
              menu={{
                items: [
                  {
                    key: 'edit',
                    label: <Link to={`/products/edit/${data.id}`}>Редактировать</Link>,
                  },
                  {
                    key: 'activity',
                    label: type === 'onMarket' ? 'Деактивировать' : 'Активировать',
                    onClick: () => handleProductsActivity(data.id),
                  },
                ],
              }}>
              <MoreOutlined />
            </Dropdown>
          ),
        },
      ]
    : columnsWithFilters;

  const filteredOptColumns =
    type !== 'forModeration'
      ? resultColumns.filter(({ key }) => key !== 'complaint' && key !== 'clientName')
      : resultColumns;

  return (
    <div className={isSuppProducts ? styles.suppProductsTable : styles.productsTable}>
      <div className={styles.settings}>
        {withSearch ? (
          <>
            <Search
              className={styles.search}
              value={searchValue}
              placeholder="Поиск по названию"
              onSearch={handleMainSearch}
              onChange={handleSearchName}
              allowClear
            />
            <div className={styles.space} />
          </>
        ) : null}

        <div className={styles.searchBtns}>{Boolean(Settings) && Settings}</div>

        <div className={styles.wrapper}>
          {selectedProducts?.length ? (
            <Button onClick={handleProductsStatuses}>{type === 'onMarket' ? 'Деактивировать' : 'Активировать'}</Button>
          ) : null}
          <Tooltip overlayStyle={{ position: 'fixed' }} title="Очистить все фильтры">
            <ClearOutlined className={styles.clear} onClick={handleResetFilters} />
          </Tooltip>

          <TableSettings
            disabledColumns={[mainColumnName]}
            columns={columns}
            visibleColumnsKeys={visibleColumnsKeys}
            setVisibleColumnsKeys={changeVisibleColumns}
          />
        </div>
      </div>
      {isLoading ? (
        <div className={styles.center}>
          <Spin size="large" />
        </div>
      ) : (
        <Table
          rowSelection={rowSelection}
          rowKey={record => record.id}
          bordered
          columns={filteredOptColumns}
          dataSource={data}
          scroll={{ x: 1300 }}
          pagination={{
            onChange: handleChangePage,
            onShowSizeChange: handleChangePageSize,
            current: pagination?.current_page || 1,
            pageSize: pagination?.per_page || PER_PAGE,
            total: pagination?.total || 0,
            showSizeChanger: true,
          }}
          locale={{ emptyText: <Empty description="Нет данных" /> }}
        />
      )}
    </div>
  );
};
