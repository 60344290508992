import { Utils , getFinalPrice } from 'src/shared/lib';
import { v4 as uuid } from 'uuid';

import { OrderTransactionItem } from '../../types';
import styles from './OrdersTransactionsHistory.module.less';

interface OrdersStatusHistoryProps {
  transactions?: OrderTransactionItem[] | null;
}

export const OrdersTransactionsHistory = ({ transactions }: OrdersStatusHistoryProps) => (
  <section className={styles.wrapper}>
    <div className={styles.list}>
      {transactions
        ? transactions.map(({ status_name: status, type_name: transType, amount, created_at: createdAt, error }) => (
            <div className={styles.card} key={uuid()}>
              <div className={styles.wrapper}>
                <div className={styles.info}>
                  <p className={styles.status}>
                    <strong>{status || '-'}</strong>
                  </p>
                  <p className={styles.status}>{transType || '-'}</p>
                  {error && <p className={styles.status}>{error}</p>}
                  <p className={styles.date}>
                    <span>{amount ? getFinalPrice(amount) : '-'}</span>
                    <span>{createdAt ? Utils.getFormatDate(createdAt, 'DD.MM.YYYY HH:mm') : '-'}</span>
                  </p>
                </div>
              </div>
            </div>
          ))
        : null}
    </div>
  </section>
);
