import { Link } from 'react-router-dom';
import { Utils , getFinalPrice , getFormattedPhone } from 'src/shared/lib';
import { Product } from 'src/pages/products/types';
import { productStatusOptions } from 'src/shared/config';

import styles from './ProductInfo.module.less';

interface ProductInfoProps {
  product: Product;
}

export const ProductInfo = ({ product }: ProductInfoProps) => (
  <div className={styles.productInfo}>
    <div>
      {product?.complaint ? (
        <p>
          <strong>Жалоба: </strong>
          {product?.complaint?.comment || '-'}
        </p>
      ) : null}
      <p>
        <strong>Описание: </strong>
        {product?.description ? product.description.replace('<!--split-->', ' / ') : '-'}
      </p>
      <div className={styles.category}>
        <strong>Категории:</strong>
        <div className={styles.wrapper}>
          {(product.categories || []).map(category => (
            <span key={category?.id} className={styles.item}>
              {category?.name || '-'}
            </span>
          ))}
          {(product?.categories || []).map(
            category =>
              category?.breadcrumbs &&
              category.breadcrumbs.map(item => (
                <span key={item?.id} className={styles.item}>
                  {item?.name || '-'}
                </span>
              )),
          )}
        </div>
      </div>
      <p>
        <strong>Цена: </strong> {product?.price ? getFinalPrice(product.price) : '-'}
      </p>
      <p>
        <strong>Статус: </strong>
        {product?.status
          ? productStatusOptions[product.status.toUpperCase() as keyof typeof productStatusOptions]
          : '-'}
      </p>
      <p>
        <strong>Дата создания: </strong>
        {product?.created_at ? Utils.getFormatDate(product.created_at, 'DD.MM.YYYY HH:mm') : '-'}
      </p>
      <p>
        <strong>Поставщик: </strong>
        {product?.supplier?.supplier?.name ? (
          <Link to={`/profile/supplier/${product.supplier.supplier.id}`} state={{ prevPath: true }}>
            {product.supplier.supplier.name}
          </Link>
        ) : (
          '-'
        )}
      </p>
      <p>
        <strong>Телефон: </strong>
        {product?.supplier?.supplier?.phone ? getFormattedPhone(product.supplier.supplier.phone) : '-'}
      </p>
      <p>
        <strong>Павильон: </strong>
        {product?.supplier?.pavilion?.name ? product.supplier.pavilion.name : '-'}
      </p>
    </div>
  </div>
);
