import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { Card, Tooltip } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import { Hooks } from 'src/shared/lib';

import { LogDetailsModal, ParserLogsTable } from './ui';
import { $logs, $pageSize, changeRequestData, getLogsFx, clearModalDetails, changePageSize } from './model';
import styles from './Logs.module.less';

export const Logs = () => {
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const data = useStore($logs);
  const isLoading = useStore(getLogsFx.pending);
  const pageSize = useStore($pageSize);
  const [searchParams, setSearchParams] = Hooks.useSearchParamsObject();
  const [logId, setLogId] = useState('');

  useEffect(() => {
    const { page, statuses, id, ...filters } = searchParams;
    const resultStatuses = statuses ? statuses.split(',') : [];
    const currPage = page ? +page : 1;
    const logId = id || '';
    setLogId(logId);

    changeRequestData({ ...filters, statuses: resultStatuses, pagination: { page: currPage, per_page: pageSize } });
  }, [searchParams, pageSize]);

  const handleChangePage = (value: number) => {
    setSearchParams({ ...searchParams, page: value.toString() });
  };

  const handleChangePageSize = (currentSize: number, size: number) => {
    changePageSize(size);
  };

  const handleSelectStatus = (value: string[]) =>
    setSearchParams({ ...searchParams, statuses: value || null, page: 1 });

  const handleSearchId = (value: string) => setSearchParams({ ...searchParams, product_id: value, page: 1 });

  const clearLogId = () => {
    setLogId('');
    clearModalDetails();
    setSearchParams({ ...searchParams, id: '' });
  };

  const handleResetFilters = () => {
    setSearchParams({ page: 1 });
    setIsActiveFilter(true);
  };

  const handleSelectLog = (id: string) => {
    setLogId(id);
    setSearchParams({ ...searchParams, id });
  };

  return (
    <>
      <Card className={styles.logs}>
        <Tooltip overlayStyle={{ position: 'fixed' }} title="Очистить все фильтры">
          <ClearOutlined className={styles.clear} onClick={handleResetFilters} />
        </Tooltip>
        <ParserLogsTable
          setLogId={handleSelectLog}
          handleSearchId={handleSearchId}
          data={data?.items || []}
          handleChangePage={handleChangePage}
          pagination={data?.pagination}
          isLoading={isLoading}
          isActiveFilter={isActiveFilter}
          setIsActiveFilter={setIsActiveFilter}
          handleChangePageSize={handleChangePageSize}
          handleSelectStatus={handleSelectStatus}
        />
      </Card>
      <LogDetailsModal id={logId} onClose={clearLogId} />
    </>
  );
};
