import React from 'react';
import { MainModal } from 'src/shared/ui';

import { ScanCode } from './components';
import { useReceptionPoint } from '../../hooks/useRecipientPoint';

type TProps = {
  readonly onClose: () => void;
};

export const AddManualModal: React.FC<TProps> = ({ onClose }) => {
  const { setTaskCheckCount } = useReceptionPoint();

  const handleClose = () => {
    setTaskCheckCount?.(undefined);
    onClose();
  };

  return (
    <MainModal isOpen closeHandler={handleClose}>
      <ScanCode />
    </MainModal>
  );
};
