import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Space, Form } from 'antd';
import type { ColumnType } from 'antd/es/table';
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { ColumnTitle } from 'antd/lib/table/interface';
import { NumberTypeEnum } from 'src/shared/types';

import { getNumbers } from '../getNumbers';
import { getSearchTypeName } from './helpers';
import styles from './useColumn.module.less';

function useColumnSearch<DataType>(
  searchHandler: (value: string, type: string) => void,
  type: string,
  isActiveFilter?: boolean,
  setIsActiveFilter?: (value: boolean) => void,
  defaultValue?: string,
) {
  const [form] = Form.useForm();
  const searchInput = useRef<InputRef>(null);
  const [isActive, setIsActive] = useState(!!defaultValue);

  useEffect(() => {
    setIsActive(!!defaultValue);
    if (!defaultValue) {
      form.resetFields();
    }
  }, [defaultValue, form]);

  useEffect(() => {
    if (isActiveFilter && setIsActiveFilter) {
      form.resetFields();
      setIsActive(false);
      setIsActiveFilter(false);
    }
  }, [isActiveFilter, setIsActiveFilter, form]);

  const resultType = type && (Object.values(NumberTypeEnum) as string[]).includes(type) ? 'number' : type;

  const handleSearchEmail = (values: { search: string }) => {
    searchHandler(values.search, type);
    setIsActive(true);
  };

  const handleReset = (clearFilters: () => void) => {
    searchHandler('', type);
    form.resetFields();
    clearFilters();
    setIsActive(false);
  };

  const handleNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const resultNumber = getNumbers(e.target.value, 'withComma');
    form.setFieldsValue({
      search: resultNumber,
    });
  };

  const getColumnSearchProps = (dataIndex: keyof DataType, titleText: ColumnTitle<DataType>): ColumnType<DataType> => ({
    filterDropdown: ({ clearFilters }) => (
      <div className={styles.useColumn}>
        <Form form={form} name="basic" id="search" onFinish={handleSearchEmail} autoComplete="off">
          {type === 'email' ? (
            <Form.Item
              rules={[
                { required: true, message: 'Пожалуйста, введите email сотрудника!' },
                { type: 'email', message: 'Не валидный email' },
              ]}
              name="search"
              initialValue={defaultValue}>
              <Input type="email" placeholder={`Поиск по ${getSearchTypeName(String(dataIndex))}`} />
            </Form.Item>
          ) : (
            <Form.Item name="search" initialValue={defaultValue}>
              {resultType === 'number' ? (
                <Input
                  onChange={handleNumber}
                  type="text"
                  placeholder={`Поиск по ${getSearchTypeName(String(dataIndex))}`}
                />
              ) : (
                <Input type={resultType || 'text'} placeholder={`Поиск по ${getSearchTypeName(String(dataIndex))}`} />
              )}
            </Form.Item>
          )}

          <Space>
            <Button type="primary" icon={<SearchOutlined />} size="small" htmlType="submit">
              Поиск
            </Button>

            <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
              Сбросить
            </Button>
          </Space>
        </Form>
      </div>
    ),
    filterIcon: () => <SearchOutlined style={{ color: isActive ? '#597393' : '' }} />,
    onFilter: (value, record) =>
      String(record[dataIndex])
        ?.toLowerCase()
        ?.includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    title: () => <span style={{ textDecoration: isActive ? 'underline' : '' }}>{titleText?.toString()}</span>,
  });

  return getColumnSearchProps;
}

export { useColumnSearch };
