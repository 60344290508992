import { Button, Empty, Input, InputRef, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useRef, useState } from 'react';
import { Task } from 'src/pages/orders';
// import { useSearchSupplier } from 'src/shared/lib/hooks/useSearchSupplier';
// import { useSearchPavilion } from 'src/shared/lib/hooks/useSearchPavilion';

import { useDebounce, useHasFocus } from 'src/utils';

import { useReceptionPoint } from '../../hooks/useRecipientPoint';
import { PER_PAGE } from '../../ReceptionPoint.const';
import styles from './ReceptionPointTable.module.less';
import { ReturnGoodsModal } from '../ReturnGoodsModal';
import { AddManualModal } from '../AddManualModal';
import { CheckCountModal } from '../CheckCountModal';
import { PrintQr } from '../PrintQr';
import { Preview } from '../Preview';

export const ReceptionPointTable: React.FC = () => {
  const {
    tasks,
    loading,
    searchParams,
    setSearchParams,
    totalCount,
    returnModalData,
    columns,
    initColumnSettings,
    showAddManualModal,
    setShowAddManualModal,
    getTaskByCode,
    taskCheckCount,
    printQrData,
    preview
  } = useReceptionPoint();
  // const { suppliers, setParams: setSupplierSearchParams } = useSearchSupplier();
  // const { pavilions, setParams: setPavilionsearchParams } = useSearchPavilion();
  const { pagination } = searchParams || {};
  // const [nameValue, setNameValue] = useState('');
  const inputRef = useRef<InputRef>(null);
  const [visibleColumns, setVisibleColumns] = useState(columns);
  // const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [scanValue, setScanValue] = useState('');
  const debouncedValue = useDebounce(scanValue || '');

  const focus = useHasFocus();

  const handleChangePage = (value: number) => {
    setSearchParams?.(prev => ({
      ...prev,
      pagination: {
        ...prev?.pagination,
        page: value,
      },
    }));
  };

  const handleChangePageSize = (currentSize: number, size: number) => {
    setSearchParams?.(prev => ({
      ...prev,
      pagination: {
        ...prev?.pagination,
        per_page: size,
      },
    }));
  };

  // TODO вернуть поиск по колонкам
  // const handleSelectSupplier = (value: number) => {
  //   setSearchParams?.(prev => ({
  //     ...prev,
  //     supplier_id: value.toString() || null,
  //     pagination: {
  //       ...prev?.pagination,
  //       page: 1,
  //     },
  //   }));
  // };
  // const handleSelectPavilion = (value: number) => {
  //   setSearchParams?.(prev => ({
  //     ...prev,
  //     pavilion_id: value.toString() || null,
  //     pagination: {
  //       ...prev?.pagination,
  //       page: 1,
  //     },
  //   }));
  // };
  // const searchCreatedDateHandler = (value: string) => {
  //   setSearchParams?.(prev => ({
  //     ...prev,
  //     created_at: value ? new Date(value).toISOString() : undefined,
  //     pagination: {
  //       ...prev?.pagination,
  //       page: 1,
  //     },
  //   }));
  // };
  // const searchSupplierHandler = (value: string) => {
  //   if (value) {
  //     setSupplierSearchParams({ ...initState, name: value });
  //   }
  // };
  // const resetSupplierHandler = () => {
  //   setSearchParams?.(prev => ({
  //     ...prev,
  //     supplier_id: undefined,
  //     pagination: {
  //       ...prev?.pagination,
  //       page: 1,
  //     },
  //   }));
  // };
  // const searchPavilionHandler = (value: string) => {
  //   if (value) {
  //     setPavilionsearchParams({ ...initState, query: value });
  //   }
  // };
  // const resetPavilionHandler = () => {
  //   setSearchParams?.(prev => ({
  //     ...prev,
  //     pavilion_id: undefined,
  //     pagination: {
  //       ...prev?.pagination,
  //       page: 1,
  //     },
  //   }));
  // };

  // TODO вернуть поиск по колонкам
  // const getSupplierSearch = Hooks.useColumnAutocomplete<Task>(
  //   searchSupplierHandler,
  //   handleSelectSupplier,
  //   resetSupplierHandler,
  //   suppliers?.items || [],
  //   isActiveFilter,
  //   setIsActiveFilter,
  // );
  // const getPavilionSearch = Hooks.useColumnAutocomplete<Task>(
  //   searchPavilionHandler,
  //   handleSelectPavilion,
  //   resetPavilionHandler,
  //   pavilions?.items || [],
  //   isActiveFilter,
  //   setIsActiveFilter,
  // );
  // const getDateSearch = Hooks.useColumnSearch<Task>(
  //   searchCreatedDateHandler,
  //   'date',
  //   isActiveFilter,
  //   setIsActiveFilter,
  //   searchParams?.created_at,
  // );
  // const searchNameHandler = (value: string) => {
  //   setSearchParams?.(prev => ({
  //     ...prev,
  //     name: value,
  //     pagination: {
  //       ...prev?.pagination,
  //       page: 1,
  //     },
  //   }));
  // };

  const filteredColumns: ColumnsType<Task> = visibleColumns!.map(column => {
    switch (column.key) {
      // TODO вернуть поиск по колонкам
      // case 'created_at':
      //   return { ...column, ...getDateSearch(column.key, column.title) };
      // case 'supplier':
      //   return { ...column, ...getSupplierSearch(column.key, column.title) };
      // case 'pavilion':
      //   return { ...column, ...getPavilionSearch(column.key, column.title) };
      default:
        return column;
    }
  });

  // TODO поиск товара по названию
  // const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => setNameValue(e.target.value);

  const handleClickScanner = () => {
    if (inputRef?.current && !showAddManualModal && !returnModalData && !taskCheckCount) {
      inputRef.current.focus();
    }
  };

  const handleScanCode = async () => {
    await getTaskByCode?.({
      code: debouncedValue,
    });
    setScanValue('');
  };

  useEffect(() => {
    if (!focus) {
      window.focus();
    }
  }, [focus]);

  useEffect(() => {
    handleScanCode();
  }, [debouncedValue]);

  useEffect(() => {
    handleClickScanner();
  }, [showAddManualModal, returnModalData, taskCheckCount]);

  useEffect(() => {
    if (columns && initColumnSettings) {
      const resultVisibleColumns: ColumnsType<Task> = columns?.filter(
        column => column.key && (!(column.key in initColumnSettings) || initColumnSettings[column.key]),
      );
      setVisibleColumns(resultVisibleColumns);
    }
  }, [initColumnSettings]);

  return (
    <>
      <div>
        <div className={styles.searchSettings}>
          <Input
            ref={inputRef}
            className={styles.search}
            placeholder='Отсканируйте QR-код на товаре'
            onChange={e => {
              setScanValue(e.target.value);
            }}
            autoFocus
            onBlur={handleClickScanner}
            value={scanValue}
          />
          <Button onClick={() => setShowAddManualModal?.(true)}>Добавить вручную</Button>
        </div>
        <Table
          className={styles.table}
          loading={loading}
          rowKey={record => record.id}
          bordered
          columns={filteredColumns}
          dataSource={tasks?.items || []}
          scroll={{ x: 1200 }}
          pagination={{
            onChange: handleChangePage,
            onShowSizeChange: handleChangePageSize,
            current: pagination?.page || 1,
            pageSize: pagination?.per_page || PER_PAGE,
            total: totalCount || 0,
            showSizeChanger: true,
          }}
          locale={{ emptyText: <Empty description='Нет данных' /> }}
        />
      </div>
      {returnModalData && <ReturnGoodsModal />}
      {showAddManualModal && <AddManualModal onClose={() => setShowAddManualModal?.(false)} />}
      {taskCheckCount && <CheckCountModal />}
      {printQrData && <PrintQr />}
      {preview && <Preview />}
    </>
  );
};
