import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { TSellerHistoryEntity } from 'src/shared/api/queries/sellerBillingApi';
import { statusColors } from 'src/shared/config';

import styles from './PaymentsSuppliersPage.module.less';

export const getColumns = () => {
  const columns: ColumnsType<TSellerHistoryEntity> = [
    {
      title: 'Тип транзакции',
      key: 'operation_type_name',
      render: record => (
        <Tag
          style={
            (record.operation_type_id === 5 && statusColors.gray) ||
            (record.operation_type_id === 6 && statusColors.green) ||
            statusColors.blue
          }
        >
          {record.operation_type_name}
        </Tag>
      ),
    },
    {
      title: 'Описание',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'Сумма',
      dataIndex: 'amount',
      key: 'amount',
      render: record => (
        <span style={{ color: record > 0 ? '#008000' : '#000000' }} className={styles.nowrap}>
          {record > 0 ? '+' : ''}
          {new Intl.NumberFormat().format(record / 100)} ₽
        </span>
      ),
    },
    {
      title: 'Дата',
      dataIndex: 'created_at',
      key: 'created_at',
      render: record => dayjs(record).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Поставщик',
      key: 'supplier_name',
      render: record => (
        <Link to={`/profile/supplier/${record.supplier_id}`} className={styles.link}>
          {record.supplier_name}
        </Link>
      ),
    },
    {
      title: 'Павильон',
      dataIndex: 'pavilion_name',
      key: 'pavilion_name',
    },
    {
      title: 'Ответственный',
      dataIndex: 'created_by',
      key: 'created_by',
    },
  ];

  const initColumnSettings = columns.reduce((prev, curr) => {
    if (curr.key) {
      prev[curr.key] = true;
    }
    return prev;
  }, {} as Record<string, boolean>);

  return {
    columns,
    initColumnSettings,
  };
};
