import { createEvent, createEffect, createStore, sample } from 'effector';
import { setError, setSuccess } from 'src/features/notifications';
import { synonymsApi } from 'src/shared/api';
import {
  SynonymsRequestData,
  SynonymPostData,
  SynonymUpdateData,
  SynonymRemoveData,
} from 'src/shared/api/queries/synonymsApi';
import { Paginated } from 'src/shared/types';

import { initState } from './config';
import { Synonym } from './types';

export const $synonymsList = createStore<Paginated<Synonym> | null>(null);
export const $requestData = createStore<SynonymsRequestData>(initState);

export const getSynonymsList = createEvent<SynonymsRequestData>();
export const postSynonym = createEvent<SynonymPostData>();
export const updateSynonym = createEvent<SynonymUpdateData>();
export const removeSynonym = createEvent<SynonymRemoveData>();
export const changeRequestData = createEvent<SynonymsRequestData>();

export const getSynonymsListFx = createEffect(synonymsApi.getSynonyms);
export const postSynonymFx = createEffect(synonymsApi.postSynonym);
export const updateSynonymFx = createEffect(synonymsApi.updateSynonym);
export const removeSynonymFx = createEffect(synonymsApi.removeSynonym);

$requestData.on(changeRequestData, (_, data) => data);

$synonymsList.on(getSynonymsListFx.doneData, (_, data) => data);

sample({
  clock: getSynonymsList,
  target: getSynonymsListFx,
});

sample({
  clock: postSynonym,
  target: postSynonymFx,
});

sample({
  clock: updateSynonym,
  target: updateSynonymFx,
});

sample({
  clock: removeSynonym,
  target: removeSynonymFx,
});

sample({
  clock: changeRequestData,
  source: $requestData,
  target: getSynonymsListFx,
});

sample({
  clock: [postSynonymFx.doneData, updateSynonymFx.doneData, removeSynonymFx.doneData],
  source: $requestData,
  target: getSynonymsListFx,
});

getSynonymsListFx.failData.watch(data => {
  setError(data.message);
});

postSynonymFx.failData.watch(data => {
  setError(data.message);
});

updateSynonymFx.failData.watch(data => {
  setError(data.message);
});

removeSynonymFx.failData.watch(data => {
  setError(data.message);
});

postSynonymFx.doneData.watch(() => {
  setSuccess('Синоним успешно добавлен');
});

updateSynonymFx.doneData.watch(() => {
  setSuccess('Синоним успешно изменен');
});

removeSynonymFx.doneData.watch(() => {
  setSuccess('Синоним успешно удален');
});
