import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { Card, Dropdown, Table, Empty } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { MainButton, ConfirmForm, Title, MainModal } from 'src/shared/ui';
import { Namings } from 'src/features/namings';

import {
  $categories,
  $isLoading,
  $treeData,
  deleteCategory,
  initCategoriesPage,
  submitCreationCategory,
  submitEditCategory,
} from './model';
import { columns, MenuTableEnum } from './config';
import { Category, CategoryFormData } from './types';
import { EditModal } from './ui';
import styles from './Categories.module.less';

interface CategoriesProps {
  isModal?: boolean;
  handleCategory?: (value: Category | null) => void;
}

export const Categories = ({ isModal, handleCategory }: CategoriesProps) => {
  const [deletingCategory, setDeletingCategory] = useState<Category | null>(null);
  const [editingCategory, setEditingCategory] = useState<Category | null>(null);
  const [isOpenCreation, setIsOpenCreation] = useState(false);
  const [isOpenNaming, setIsOpenNaming] = useState(false);
  const categories = useStore($categories);
  const treeData = useStore($treeData);
  const isLoading = useStore($isLoading);

  useEffect(() => {
    initCategoriesPage();
  }, []);

  const addCategory = () => {
    setEditingCategory(null);
    setIsOpenCreation(true);
  };

  const setCategoryForEdit = (data: Category) => {
    setEditingCategory(data);
    setIsOpenCreation(true);
  };

  const confirmDelete = () => {
    if (deletingCategory) {
      deleteCategory(deletingCategory.id);
    }
    setDeletingCategory(null);
  };

  const categoryHandler = (data: CategoryFormData) => {
    if (editingCategory) {
      submitEditCategory({ ...data, id: editingCategory.id });
    } else {
      submitCreationCategory(data);
    }
    setEditingCategory(null);
    setIsOpenCreation(false);
  };

  const handleCloseAdd = () => setIsOpenCreation(false);

  const handleOpenNamingModal = (data: Category) => {
    setEditingCategory(data);
    setIsOpenNaming(true);
  };

  const handleCloseNamingModal = () => setIsOpenNaming(false);

  const settingsColumn = [
    {
      title: '',
      key: 'settings',
      render: (data: Category) => (
        <Dropdown
          overlayClassName={styles.menu}
          placement='bottom'
          menu={{
            items: !isModal
              ? [
                  {
                    key: MenuTableEnum.EDIT,
                    label: 'Изменить',
                    onClick: () => setCategoryForEdit(data),
                  },
                  { key: MenuTableEnum.REMOVE, label: 'Удалить', onClick: () => setDeletingCategory(data) },
                  { key: MenuTableEnum.NAMING, label: 'Наименования', onClick: () => handleOpenNamingModal(data) },
                ]
              : [{ key: MenuTableEnum.NAMING, label: 'Наименования', onClick: () => handleOpenNamingModal(data) }],
          }}
        >
          <MoreOutlined />
        </Dropdown>
      ),
      width: '3%',
    },
  ];

  return (
    <div className={styles.wrapper}>
      <Card>
        <div className={styles.button}>
          <Title className={styles.title}>Категории</Title>
          {!isModal ? (
            <MainButton type='primary' onClick={addCategory}>
              Добавить
            </MainButton>
          ) : null}
        </div>

        <Table
          loading={isLoading}
          rowKey={record => record.id}
          columns={[...columns, ...settingsColumn]}
          dataSource={categories}
          bordered
          pagination={false}
          locale={{ emptyText: <Empty description='Нет данных' /> }}
        />
      </Card>

      <ConfirmForm
        isOpen={!!deletingCategory}
        title={`Вы уверены, что хотите удалить категорию "${deletingCategory?.name}"?`}
        closeHandler={() => setDeletingCategory(null)}
        confirmHandler={confirmDelete}
      />

      <MainModal isOpen={isOpenCreation} closeHandler={handleCloseAdd}>
        <EditModal
          closeHandler={() => {
            setEditingCategory(null);
            handleCloseAdd();
          }}
          category={editingCategory}
          submitHandler={categoryHandler}
          treeData={treeData}
        />
      </MainModal>

      <MainModal isOpen={isOpenNaming} closeHandler={handleCloseNamingModal} width='90%'>
        <Namings category={editingCategory} handleCategory={handleCategory} />
      </MainModal>
    </div>
  );
};
