import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { Hooks , getIsoTimeZoneTime } from 'src/shared/lib';
import { Table, Input, Empty, Card, Tooltip, Spin } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/interface';
import { TableSettings } from 'src/entities/tableSettings';
import { MainModal } from 'src/shared/ui';

import {
  $posts,
  $suppliers,
  $sources,
  $pageSize,
  $productParsePost,
  $postDescription,
  $categories,
  $visibleColumns,
  $isParsePostError,
  resetProductParsePost,
  resetIsParsePostError,
  changePageSize,
  changeRequestData,
  changeVisibleColumns,
  submitChangeSuppData,
  submitChangeSourceData,
  submitParsePostData,
  submitActualPostReqData,
  submitParsePostReqData,
  getPostsFx,
  getActualPostInfoFx,
  submitProductParseFx,
} from './model';
import { columns, mainColumnName, PER_PAGE, statusOptions, initState, defaultStatuses } from './config';
import { ProductParser } from '../product/ui/productParser';
import { PostData } from './types';
import styles from './Posts.module.less';

interface PostsProps {
  id?: string;
}

export const Posts = ({ id }: PostsProps) => {
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const visibleColumnsKeys = useStore($visibleColumns);
  const [descriptionValue, setDescriptionValue] = useState('');
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [isInitialPosts, setIsInitialPosts] = useState(true);
  const [activeProductId, setActiveProductId] = useState<number | string | null>(null);
  const productParsePost = useStore($productParsePost);
  const postDescription = useStore($postDescription);
  const categories = useStore($categories);
  const posts = useStore($posts);
  const suppliers = useStore($suppliers);
  const sources = useStore($sources);
  const isLoading = useStore(getPostsFx.pending);
  const isParserLoading = useStore(getActualPostInfoFx.pending);
  const isParseLoading = useStore(submitProductParseFx.pending);
  const isParsePostError = useStore($isParsePostError);
  const pageSize = useStore($pageSize);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams, setSearchParams] = Hooks.useSearchParamsObject();

  useEffect(() => {
    if (isParsePostError) {
      const filters = { ...searchParams };
      delete filters.prodId;
      setSearchParams(filters);
      resetIsParsePostError();
    }
  }, [isParsePostError]);

  useEffect(() => {
    submitChangeSuppData(initState);
    submitChangeSourceData(initState);
    setSearchParams({ ...searchParams, statuses: defaultStatuses.statuses, page: 1 });
  }, []);

  useEffect(() => {
    const { page, from, statuses, prodId, to, ...filters } = searchParams;
    const currPage = page ? +page : 1;
    const idData = id ? { supplier_id: id } : {};
    const resultDate = from && to ? { post_date: { from, to } } : {};
    const selectedStatuses = statuses ? { statuses: statuses.split(',') } : {};
    const resultStatuses = isInitialPosts ? defaultStatuses : selectedStatuses;
    setIsInitialPosts(false);

    if (prodId) {
      setActiveProductId(prodId);
      handleParser(prodId);
      setIsModalOpen(true);
      return;
    }

    if (!prodId && activeProductId) {
      setActiveProductId(null);
      return;
    }

    changeRequestData({
      ...filters,
      ...idData,
      ...resultDate,
      ...resultStatuses,
      pagination: {
        page: currPage,
        per_page: pageSize,
      },
    });
  }, [searchParams, pageSize, id]);

  useEffect(() => {
    const resultVisibleColumns: ColumnsType<PostData> = [];
    columns.forEach(column => {
      if (
        column.key &&
        (!Object.prototype.hasOwnProperty.call(visibleColumnsKeys, column.key) || visibleColumnsKeys[column.key])
      ) {
        resultVisibleColumns.push(column);
      }
    });
    setVisibleColumns(resultVisibleColumns);
  }, [visibleColumnsKeys]);

  const searchNameHandler = () => {
    setSearchParams({ ...searchParams, description: descriptionValue, page: 1 });
  };

  const searchFieldHandler = (value: string, type: string) => {
    setSearchParams({ ...searchParams, [type]: value || null, page: 1 });
  };

  const searchCreatedDateHandler = (data: { date_after: string; date_to: string }) => {
    setSearchParams({
      ...searchParams,
      from: data?.date_after ? getIsoTimeZoneTime({ day: data.date_after }, 'from') : null,
      to: data?.date_to ? getIsoTimeZoneTime({ day: data.date_after }, 'to') : null,
      page: 1,
    });
  };

  const searchSupplierHandler = (value: string) => {
    const reqData = value ? { ...initState, name: value } : initState;
    submitChangeSuppData(reqData);
  };

  const resetSupplierHandler = () => {
    const filters = { ...searchParams };
    delete filters.supplier_id;
    setSearchParams({ ...filters, page: 1 });
  };

  const handleSelectSupplier = (value: number) => {
    if (value) {
      setSearchParams({ ...searchParams, supplier_id: value, page: 1 });
    }
  };

  const handleSelectStatus = (value: string[]) =>
    setSearchParams({ ...searchParams, statuses: value || null, page: 1 });

  const handleChangePage = (value: number, size: number) => {
    const { per_page: perPage } = searchParams;
    const resultPage = !perPage || Number(perPage) === size ? { page: value.toString() } : { page: 1 };
    setSearchParams({ ...searchParams, per_page: size, ...resultPage });
    changePageSize(size);
  };

  const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value && descriptionValue) {
      const { description, ...filteredReqData } = searchParams;
      setSearchParams({ ...filteredReqData, page: 1 });
    }

    setDescriptionValue(e.target.value);
  };

  const handleResetFilters = () => {
    setSearchParams({ page: 1 });
    setIsActiveFilter(true);
    setDescriptionValue('');
  };

  const handleSearchSource = (value: string) => {
    const reqData = value ? { ...initState, search: value } : { ...initState };
    submitChangeSourceData(reqData);
  };

  const handleSelectSource = (value: number) => {
    if (value) {
      setSearchParams({ ...searchParams, source_id: value, page: 1 });
    }
  };

  const resetSourceHandler = () => {
    const filters = { ...searchParams };
    delete filters.source_id;
    setSearchParams({ ...filters, page: 1 });
  };

  const handleModal = (value: boolean) => setIsModalOpen(value);

  const handleParsePost = () => {
    if (activeProductId) {
      submitParsePostData(activeProductId);
    }
  };

  const handleActualPost = () => {
    if (activeProductId) {
      submitActualPostReqData(activeProductId);
    }
  };

  const handleParser = (id: number | string) => {
    if (id) {
      submitParsePostReqData(id);
      handleModal(true);
    }
  };

  const getErrorSearch = Hooks.useColumnSearch<PostData>(
    searchFieldHandler,
    'error',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.email,
  );

  const getUrlSearch = Hooks.useColumnSearch<PostData>(
    searchFieldHandler,
    'url',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.email,
  );

  const getSourcesSearch = Hooks.useColumnAutocomplete<PostData>(
    handleSearchSource,
    handleSelectSource,
    resetSourceHandler,
    sources,
    isActiveFilter,
    setIsActiveFilter,
  );

  const getSupplierSearch = Hooks.useColumnAutocomplete<PostData>(
    searchSupplierHandler,
    handleSelectSupplier,
    resetSupplierHandler,
    suppliers?.items || [],
    isActiveFilter,
    setIsActiveFilter,
  );

  const getDateSearch = Hooks.useColumnSearchDate<PostData>(
    searchCreatedDateHandler,
    isActiveFilter,
    setIsActiveFilter,
  );

  const getStatusSearch = Hooks.useColumnMultiSelect<PostData>(
    handleSelectStatus,
    statusOptions,
    'Выбрать статус',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.status,
    defaultStatuses.statuses,
  );

  const filteredColumns: ColumnsType<PostData> = visibleColumns.map(column => {
    switch (column.key) {
      case 'error':
        return { ...column, ...getErrorSearch(column.key, column.title) };
      case 'source':
        return { ...column, ...getSourcesSearch(column.key, column.title) };
      case 'supplier':
        return { ...column, ...getSupplierSearch(column.key, column.title) };
      case 'url':
        return { ...column, ...getUrlSearch(column.key, column.title) };
      case 'post_date':
        return { ...column, ...getDateSearch(column.key, column.title) };
      case 'status':
        return { ...column, ...getStatusSearch(column.key, column.title) };
      default:
        return column;
    }
  });

  const resultColumns = id ? filteredColumns.filter(({ key }) => key !== 'supplier') : filteredColumns;

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Card className={styles.body}>
        <div className={styles.settings}>
          <Input.Search
            value={descriptionValue}
            className={styles.search}
            placeholder="Поиск"
            onChange={handleSearchName}
            onSearch={searchNameHandler}
            allowClear
          />
          <div className={styles.wrapper}>
            <Tooltip title="Очистить все фильтры">
              <ClearOutlined className={styles.clear} onClick={handleResetFilters} />
            </Tooltip>
            <TableSettings
              columns={columns}
              disabledColumns={[mainColumnName]}
              visibleColumnsKeys={visibleColumnsKeys}
              setVisibleColumnsKeys={changeVisibleColumns}
            />
          </div>
        </div>

        <Table
          rowClassName={styles.tableRow}
          onRow={data => ({
            onClick: () => {
              if (data?.id) {
                resetProductParsePost();
                setActiveProductId(data.id);
                setSearchParams({ ...searchParams, prodId: data.id });
              }
            },
          })}
          rowKey={record => record.id}
          bordered
          scroll={{ x: 1200 }}
          columns={resultColumns}
          dataSource={posts?.items || []}
          pagination={{
            onChange: handleChangePage,
            current: posts?.pagination?.current_page || 1,
            pageSize: posts?.pagination?.per_page || PER_PAGE,
            total: posts?.pagination?.total || 0,
            showSizeChanger: true,
          }}
          locale={{
            emptyText: <Empty description="Нет данных" />,
          }}
        />
      </Card>

      <MainModal
        width="95%"
        isOpen={isModalOpen}
        closeHandler={() => {
          handleModal(false);
          setSearchParams({ ...searchParams, prodId: '' });
        }}>
        <ProductParser
          productId={activeProductId}
          data={productParsePost}
          description={postDescription}
          categories={categories}
          handleParsePost={handleParsePost}
          handleActualPost={handleActualPost}
          isLoading={isParserLoading}
          closeHandler={handleModal}
          isParseLoading={isParseLoading}
          isPosts
        />
      </MainModal>
    </>
  );
};
