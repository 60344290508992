import { useEffect } from 'react';
import { useStore } from 'effector-react';
import { Form, Input, Button, Card, Spin } from 'antd';
import { Title } from 'src/shared/ui';

import { $captchaSettings, initCaptchaPage, submitUpdateSettingsData, getSettingsFx } from './model';
import { CaptchaTypes, CaptchaSettingsKeys } from './types';
import styles from './Captcha.module.less';

export const Captcha = () => {
  const [form] = Form.useForm();
  const captchaSettings = useStore($captchaSettings);
  const isLoading = useStore(getSettingsFx.pending);

  useEffect(() => {
    initCaptchaPage();
  }, []);

  useEffect(() => {
    form.setFieldsValue(captchaSettings);
  }, [form, captchaSettings]);

  const formSubmitHandler = (values: CaptchaTypes) => {
    submitUpdateSettingsData({
      settings: [
        {
          key: CaptchaSettingsKeys.ClientCaptcha,
          value: values.client_registrations_without_captcha,
        },
        {
          key: CaptchaSettingsKeys.IntermedCaptcha,
          value: values.intermediary_registrations_without_captcha,
        },
        {
          key: CaptchaSettingsKeys.SupplierCaptcha,
          value: values.supplier_registrations_without_captcha,
        },
      ],
    });
  };

  return (
    <div className={styles.captcha}>
      <Card>
        <Title>Капча</Title>

        {isLoading ? (
          <div className={styles.center}>
            <Spin size="large" />
          </div>
        ) : (
          <Form
            form={form}
            className={styles.form}
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            onFinish={formSubmitHandler}
            autoComplete="off">
            <Form.Item
              label="Клиент: количество доступных регистраций без капчи за 10 минут"
              name="client_registrations_without_captcha"
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, количество доступных регистраций без капчи за 10 минут клиента',
                },
              ]}>
              <Input type="number" min={0} />
            </Form.Item>
            <Form.Item
              label="Посредник: количество доступных регистраций без капчи за 10 минут"
              name="intermediary_registrations_without_captcha"
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, количество доступных регистраций без капчи за 10 минут посредника',
                },
              ]}>
              <Input type="number" min={0} />
            </Form.Item>
            <Form.Item
              label="Поставщик: количество доступных регистраций без капчи за 10 минут"
              name="supplier_registrations_without_captcha"
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, количество доступных регистраций без капчи за 10 минут поставщика',
                },
              ]}>
              <Input type="number" min={0} />
            </Form.Item>
            <Form.Item className={styles.button}>
              <Button disabled={!captchaSettings} htmlType="submit" type="primary">
                Сохранить изменения
              </Button>
            </Form.Item>
          </Form>
        )}
      </Card>
    </div>
  );
};
