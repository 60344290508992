import type { ColumnsType } from 'antd/es/table';
import { Utils } from 'src/shared/lib';
import { DateType } from 'src/shared/ui/datePicker';

import { AdminLogs } from '../../types';

export const PER_PAGE = 10;

export const columns: ColumnsType<AdminLogs> = [
  {
    title: 'Действие',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Пользователь',
    dataIndex: 'user',
    key: 'user',
    render: user => user.name,
  },
  {
    title: 'Дата и время',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (data: DateType) => Utils.getFormatDate(data, 'DD.MM.YYYY HH:mm'),
  },
];
