import { AxiosError } from 'axios';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { namingsApi } from 'src/shared/api';
import { Paginated } from 'src/shared/types';
import { Storage } from 'src/shared/lib';
import { setSuccess, setError } from 'src/features/notifications';

import { Naming } from './types';
import { PER_PAGE, initState } from './config';

export const $namings = createStore<Paginated<Naming> | null>(null);
export const $initNamingState = createStore<namingsApi.GetNamingsRequestData | null>(null);
export const $isNamingUpdated = createStore(false);

export const $pageSize = createStore<number>(PER_PAGE);
Storage.persist.entity($pageSize, { slice: 'namings', key: 'pageSize' });

export const initNamings = createEvent<namingsApi.GetNamingsRequestData>();
export const submitAddNamingData = createEvent<namingsApi.AddNamingsRequestData>();
export const submitEditNamingData = createEvent<namingsApi.EditNamingsRequestData>();
export const submitDeleteNamingData = createEvent<number>();
export const changePageSize = createEvent<number>();
export const resetIsNamingUpdated = createEvent();

export const getNamingsFx = createEffect(namingsApi.getNamings);
export const addNamingFx = createEffect<namingsApi.AddNamingsRequestData, Naming, AxiosError<{ message: string }>>(
  namingsApi.addNamings,
);
export const editNamingFx = createEffect<namingsApi.EditNamingsRequestData, Naming, AxiosError<{ message: string }>>(
  namingsApi.editNamings,
);
export const deleteNamingFx = createEffect<number, Naming, AxiosError<{ message: string }>>(namingsApi.deleteNamings);

$namings.on(getNamingsFx.doneData, (_, data: Paginated<Naming>) => {
  const sortedData = data?.items?.sort((a, b) => b.id - a.id) || [];
  return { ...data, items: sortedData };
});
$initNamingState.on(initNamings, (_, data) => data);
$isNamingUpdated
  .on([addNamingFx.doneData, editNamingFx.doneData, deleteNamingFx.doneData], () => true)
  .reset(resetIsNamingUpdated);

sample({
  clock: initNamings,
  target: getNamingsFx,
});

sample({
  clock: submitAddNamingData,
  target: addNamingFx,
});

sample({
  clock: submitEditNamingData,
  target: editNamingFx,
});

sample({
  clock: submitDeleteNamingData,
  target: deleteNamingFx,
});

sample({
  clock: changePageSize,
  target: $pageSize,
});

sample({
  clock: [addNamingFx.doneData, editNamingFx.doneData, deleteNamingFx.doneData],
  filter: data => !!data,
  fn: data => ({ id: data?.category_id || 0, data: initState }),
  target: getNamingsFx,
});

sample({
  clock: addNamingFx.doneData,
  fn: () => 'Наименование успешно добавлено',
  target: setSuccess,
});

sample({
  clock: editNamingFx.doneData,
  fn: () => 'Наименование успешно измененено',
  target: setSuccess,
});

sample({
  clock: deleteNamingFx.doneData,
  fn: () => 'Наименование успешно удалено',
  target: setSuccess,
});

sample({
  clock: addNamingFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при добавлении наименования',
  target: setError,
});

sample({
  clock: editNamingFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при изменении наименования',
  target: setError,
});

sample({
  clock: deleteNamingFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при удалении наименования',
  target: setError,
});
