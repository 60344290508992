import { Menu } from 'antd';
import { Route, Routes, Link, useLocation } from 'react-router-dom';

import { PaymentsList } from './paymentList';
import styles from './Payments.module.less';
import { PaymentsSuppliersPage } from './suppliers';
import { PaymentsWithdrawalPage } from './withdrawals';

export const Payments = () => {
  const location = useLocation();

  return (
    <section className={styles.orders}>
      <h2 className={styles.title}>Платежи</h2>

      <Menu mode="horizontal" className={styles.menu} selectedKeys={[location.pathname]}>
        <Menu.Item key="/payments">
          <Link to="/payments">Сплит</Link>
        </Menu.Item>
        <Menu.Item key="/payments/clientsPayments">
          <Link to="/payments/clientsPayments">Без сплита</Link>
        </Menu.Item>
        <Menu.Item key="/payments/suppliers">
          <Link to="/payments/suppliers">Поставщики</Link>
        </Menu.Item>
        <Menu.Item key="/payments/withdrawals">
          <Link to="/payments/withdrawals">Запросы вывода</Link>
        </Menu.Item>
      </Menu>

      <div className={styles.body}>
        <Routes>
          <Route path="/" element={<PaymentsList paymentsType="legal-entities" />} />
          <Route path="/clientsPayments" element={<PaymentsList paymentsType="clients" />} />
          <Route path="/suppliers" element={<PaymentsSuppliersPage />} />
          <Route path="/withdrawals" element={<PaymentsWithdrawalPage />} />
        </Routes>
      </div>
    </section>
  );
};
