import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { sellerBillingApi } from 'src/shared/api';
import { TSellerBalanceEntity } from 'src/shared/api/queries/sellerBillingApi';
import { AxiosError } from 'axios';
import { notification } from 'antd';

import { TWithdrawalFx } from '../PaymentsWithdrawalPage.types';

type TReturnValues = {
  readonly loading: boolean;
  readonly balances: TSellerBalanceEntity[];
  readonly onWithdrawal: (values: TWithdrawalFx) => Promise<void>;
};

const PaymentsWithdrawalContext = createContext<Partial<TReturnValues>>({});

export const PaymentsWithdrawalProvider = ({ children }: { children: ReactNode }) => {
  const [balances, setBalances] = useState<TSellerBalanceEntity[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchBalances = async () => {
    try {
      setLoading(true);
      const response = await sellerBillingApi.getSellerBalances();
      setBalances(response?.balances);
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({ message: error.response?.data.message || 'Ошибка загрузки балансов' });
    } finally {
      setLoading(false);
    }
  };

  const onWithdrawal = async ({ data, callback }: TWithdrawalFx) => {
    try {
      await sellerBillingApi.updateSellerBalance(data);
      fetchBalances();
      callback();
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({ message: error.response?.data.message || 'Ошибка списания средств' });
    }
  };

  useEffect(() => {
    fetchBalances();
  }, []);

  const values = useMemo(
    () => ({
      loading,
      balances,
      onWithdrawal,
    }),
    [loading, balances],
  );

  return <PaymentsWithdrawalContext.Provider value={values}>{children}</PaymentsWithdrawalContext.Provider>;
};

export const usePaymentsWithdrawal = (): Partial<TReturnValues> => ({ ...useContext(PaymentsWithdrawalContext) });
