import { Table, Empty } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';

import { PER_PAGE } from './config';
import { AdminLogs } from '../../types';

interface AdminsLogsTableProps {
  data: AdminLogs[];
  columns: ColumnsType<AdminLogs>;
  pagination?: {
    current_page: number;
    total: number;
    per_page: number;
  };
  handleChangePage?: (value: number, size: number) => void;
}

export const AdminsLogsTable = ({ data, columns, pagination, handleChangePage }: AdminsLogsTableProps) => (
  <Table
    columns={columns}
    dataSource={data}
    rowKey={record => record.id}
    pagination={{
      onChange: handleChangePage,
      current: pagination?.current_page || 1,
      pageSize: pagination?.per_page || PER_PAGE,
      total: pagination?.total || 0,
      showSizeChanger: true,
    }}
    locale={{ emptyText: <Empty description="Нет данных" /> }}
  />
);
