import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { Table, Input, Tooltip, Empty, Spin } from 'antd';
import { TableSettings } from 'src/entities/tableSettings';
import { ClearOutlined } from '@ant-design/icons';
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface';
import { NumberTypeEnum } from 'src/shared/types';
import { Hooks , getFinalFilterPrice } from 'src/shared/lib';

import { IOrder } from '../ordersList/types';
import { columns, PER_PAGE, initState, mainColumnName } from './config';
import {
  $orders,
  $requestData,
  $clients,
  $clientsData,
  $visibleColumns,
  initRefundsListPage,
  submitChangeReqData,
  getOrdersFx,
  changeVisibleColumns,
  changeClientsData,
} from './model';
import styles from './RefundsList.module.less';

export const RefundsList = () => {
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const visibleColumnsKeys = useStore($visibleColumns);
  const [nameValue, setNameValue] = useState('');
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const orders = useStore($orders);
  const requestData = useStore($requestData);
  const isLoading = useStore(getOrdersFx.pending);
  const clients = useStore($clients);
  const clientsData = useStore($clientsData);

  useEffect(() => {
    initRefundsListPage({ ...initState });
  }, []);

  useEffect(() => {
    const resultVisibleColumns: ColumnsType<IOrder> = columns.filter(
      column => column.key && (!(column.key in visibleColumnsKeys) || visibleColumnsKeys[column.key]),
    );
    setVisibleColumns(resultVisibleColumns);
  }, [visibleColumnsKeys]);

  const handleSelectChange = (selectedRowKeys: React.Key[], selectedRows: IOrder[]) => {};

  const handleSelect = (record: IOrder, selected: boolean, selectedRows: IOrder[]) => {};

  const handleSelectAll = (selected: boolean, selectedRows: IOrder[], changeRows: IOrder[]) => {};

  const rowSelection: TableRowSelection<IOrder> = {
    onChange: handleSelectChange,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
  };

  const searchNameHandler = (value: string) => {
    if (!value) {
      const { code, ...filteredReqData } = requestData;
      submitChangeReqData({ ...filteredReqData, ...initState });
    } else {
      submitChangeReqData({ ...requestData, code: value, ...initState });
    }
  };

  const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => setNameValue(e.target.value);

  const searchPriceHandler = (value: string) => {
    if (!value) {
      const { price, ...filteredReqData } = requestData;
      submitChangeReqData({ ...filteredReqData, ...initState });
    } else {
      const resultValue = getFinalFilterPrice(value);
      submitChangeReqData({ ...requestData, price: resultValue, ...initState });
    }
  };

  const searchClientHandler = (value: string) => {
    if (value) {
      changeClientsData({ ...clientsData, name: value });
    }
  };

  const resetClientHandler = () => {
    const { client_id: clientId, ...filteredReqData } = requestData;
    submitChangeReqData({ ...filteredReqData, ...initState });
  };

  const handleSelectClient = (value: number) => {
    submitChangeReqData({ ...requestData, client_id: value, ...initState });
  };

  const searchCreatedDateHandler = (value: string) => {
    if (value) {
      submitChangeReqData({ ...requestData, ...clientsData, created_at: new Date(value).toISOString(), ...initState });
    } else {
      const { created_at: createdAt, ...filteredReqData } = requestData;
      submitChangeReqData({ ...filteredReqData, ...initState });
    }
  };

  const handleChangePage = (value: number, size: number) => {
    submitChangeReqData({ ...requestData, pagination: { page: value, per_page: size } });
  };

  const handleResetFilters = () => {
    submitChangeReqData({ ...initState });
    setIsActiveFilter(true);
    setNameValue('');
  };

  const getPriceSearch = Hooks.useColumnSearch<IOrder>(
    searchPriceHandler,
    NumberTypeEnum.PRICE,
    isActiveFilter,
    setIsActiveFilter,
  );

  const getClientSearch = Hooks.useColumnAutocomplete<IOrder>(
    searchClientHandler,
    handleSelectClient,
    resetClientHandler,
    clients?.items,
    isActiveFilter,
    setIsActiveFilter,
  );

  const getDateSearch = Hooks.useColumnSearch<IOrder>(
    searchCreatedDateHandler,
    'date',
    isActiveFilter,
    setIsActiveFilter,
  );

  const filteredColumns: ColumnsType<IOrder> = visibleColumns.map(column => {
    switch (column.key) {
      case 'price':
        return { ...column, ...getPriceSearch(column.key, column.title) };
      case 'client':
        return { ...column, ...getClientSearch(column.key, column.title) };
      case 'created_at':
        return { ...column, ...getDateSearch(column.key, column.title) };
      default:
        return column;
    }
  });

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return orders ? (
    <div className={styles.refundsList}>
      <div className={styles.searchSettings}>
        <Input.Search
          value={nameValue}
          className={styles.search}
          placeholder="Поиск"
          onChange={handleSearchName}
          onSearch={searchNameHandler}
          allowClear
        />

        <div className={styles.options}>
          <Tooltip overlayStyle={{ position: 'fixed' }} title="Очистить все фильтры">
            <ClearOutlined className={styles.clear} onClick={handleResetFilters} />
          </Tooltip>

          <TableSettings
            disabledColumns={[mainColumnName]}
            columns={columns}
            visibleColumnsKeys={visibleColumnsKeys}
            setVisibleColumnsKeys={changeVisibleColumns}
          />
        </div>
      </div>

      <Table
        className={styles.table}
        rowSelection={{ ...rowSelection }}
        rowKey={record => record.id}
        bordered
        columns={filteredColumns}
        dataSource={orders?.items || []}
        pagination={{
          onChange: handleChangePage,
          current: orders?.pagination?.current_page || 1,
          pageSize: orders?.pagination?.per_page || PER_PAGE,
          total: orders?.pagination?.total || 0,
          showSizeChanger: true,
        }}
        scroll={{ x: 1200 }}
        locale={{ emptyText: <Empty description="Нет данных" /> }}
      />
    </div>
  ) : (
    <Empty description="Нет данных" />
  );
};
