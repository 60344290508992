import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Utils , getFinalPrice , getFormattedPhone } from 'src/shared/lib';
import { DateType } from 'src/shared/ui/datePicker';
import { statusColors , orderStatus } from 'src/shared/config';

import { IOrder } from '../ordersList/types';


export const mainColumnName = 'code';

export const PER_PAGE = 10;

export const initState = {
  status: orderStatus.refund,
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export const columns: ColumnsType<IOrder> = [
  {
    title: 'Заказ',
    dataIndex: 'code',
    key: 'code',
    render: (code, { id }) =>
      code ? (
        <Link to={`/order/${id}`} state={{ prevPath: true }}>
          {code}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Стоимость',
    dataIndex: 'price',
    key: 'price',
    render: data => {
      const resultPrice = getFinalPrice(data);
      return data ? resultPrice : '-';
    },
  },
  {
    title: 'Клиент',
    dataIndex: 'client',
    key: 'client',
    render: data =>
      data ? (
        <Link to={`/profile/client/${data.id}`} state={{ prevPath: true }}>
          {data.name}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
    render: (_, { client }) => getFormattedPhone(client.phone) || '-',
  },
  {
    title: 'Дата заказа',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (data: DateType) => Utils.dateFormat(data),
  },
  {
    title: 'Комментарий',
    dataIndex: 'historyLast',
    key: 'historyLast',
    render: data => data?.description || '-',
  },
  {
    title: 'Статус заказа',
    dataIndex: 'status',
    key: 'status',
    render: () => <Tag style={statusColors.red}>Возврат</Tag>,
  },
];

export const initColumnSettings = columns.reduce((prev, curr) => {
  if (curr.key) {
    prev[curr.key] = true;
  }
  return prev;
}, {} as Record<string, boolean>);
