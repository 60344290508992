import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { MainModal } from 'src/shared/ui';

import { LegalEntity } from '../../types';
import styles from './ArchiveErrorModal.module.less';

interface ArchiveErrorModalProps {
  isArchive: boolean;
  isOpen: boolean;
  closeHandler: () => void;
  legalEntity: LegalEntity | null;
  isLegalProfile?: boolean;
}

export const ArchiveErrorModal = ({
  isArchive,
  legalEntity,
  isOpen,
  closeHandler,
  isLegalProfile,
}: ArchiveErrorModalProps) => (
  <MainModal isOpen={isOpen} closeHandler={closeHandler}>
    <div className={styles.remove}>
      <h2 className={styles.removeTitle}>Ошибка {isArchive ? 'архивирования' : ''}</h2>

      {isArchive && legalEntity ? (
        <p>
          Юридическое лицо &quot;
          {isLegalProfile ? (
            legalEntity.name
          ) : (
            <Link
              className={styles.link}
              to={`/profile/legalEntity/${legalEntity.id}`}
              state={{ prevPath: true }}
              target="_blank"
              rel="noopener noreferrer">
              {legalEntity.name}
            </Link>
          )}
          &quot; с активными поставщиками нельзя архивировать. Отключите активных поставщиков и повторите попытку
        </p>
      ) : (
        <p>
          Нельзя активировать поставщика - юридическое лицо &quot;
          {legalEntity ? (
            <Link
              className={styles.link}
              to={`/profile/legalEntity/${legalEntity.id}`}
              state={{ prevPath: true }}
              target="_blank"
              rel="noopener noreferrer">
              {legalEntity.name}
            </Link>
          ) : null}
          &quot; находится в архиве
        </p>
      )}

      <div className={styles.buttons}>
        <Button htmlType="submit" className={styles.button} type="primary" autoFocus onClick={closeHandler}>
          Понятно
        </Button>
      </div>
    </div>
  </MainModal>
);
