import type { ColumnsType } from 'antd/es/table';
import { getFormattedPhone , getNumbers } from 'src/shared/lib';
import { Rule } from 'antd/lib/form';

import { User } from './types';

export const mainColumnName = 'name';

export const employeesFormRules = {
  name: [
    { required: true, message: 'Пожалуйста, введите имя сотрудника!' },
    { max: 200, message: 'Длина имени должна составлять не более 200 символов!' },
    {
      pattern: /^([а-яёA-Za-z]+(?:[ -][a-яё ]+)*[ -]?|)$/i,
      message: 'Имя может содержать только кириллицу, латиницу, дефис и пробелы',
      validateTrigger: 'onChange',
    },
  ],
  password: [
    { required: true, message: 'Пожалуйста, введите пароль сотрудника!' },
    { min: 6, message: 'Пароль должен содержать не менее 6 символов!', validateTrigger: 'onSubmit' },
    { max: 8, message: 'Пароль не должен содержать более 8 символов!', validateTrigger: 'onSubmit' },
    {
      pattern: /(?=.*[!@#$%^&*()_+~])/,
      message: 'Пароль должен содержать спец. символы (!@#$%^&*()_+~)!',
      validateTrigger: 'onSubmit',
    },
    {
      pattern: /(?=.*[0-9])/,
      message: 'Пароль должен содержать цифры!',
      validateTrigger: 'onSubmit',
    },
    {
      pattern: /(?=.*[a-z])/,
      message: 'Пароль должен содержать латинские буквы нижнего регистра!',
      validateTrigger: 'onSubmit',
    },
    {
      pattern: /(?=.*[A-Z])/,
      message: 'Пароль должен содержать латинские буквы верхнего регистра!',
      validateTrigger: 'onSubmit',
    },
    {
      pattern: /(?=.*^\S*$)/,
      message: 'Пароль не должен содержать пробелы!',
      validateTrigger: 'onSubmit',
    },
  ],
  phone: [
    { required: true, message: 'Пожалуйста, введите телефон сотрудника!' },
    {
      pattern: /(?=.*^7|\+7)/,
      message: 'Телефон должен начинаться с +7!',
    },
    {
      message: 'Длина телефона должна составлять не менее 11 символов',
      validator(rule: Rule, value: string) {
        const phoneNumbers = value ? getNumbers(value) : '';
        if (phoneNumbers.length < 11) {
          return Promise.reject();
        }
        return Promise.resolve();
      },
      validateTrigger: 'onChange',
    },
  ],
};

export const roleOptions = {
  ADMIN: 'Админ',
  RECEIVER: 'Приемщик',
  STOREKEEPER: 'Кладовщик',
  GTS: 'ГТС'
};

export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export const columns: ColumnsType<User> = [
  {
    title: 'ФИО',
    dataIndex: 'name',
    key: 'name',
    render: data => data || '-',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Номер телефона',
    dataIndex: 'phone',
    key: 'phone',
    render: data => getFormattedPhone(data) || '-',
  },
  {
    title: 'Должность',
    dataIndex: 'position',
    key: 'position',
    render: data => data || '-',
  },
  {
    title: 'Роль',
    dataIndex: 'role',
    key: 'role',
    render: data => (data ? roleOptions[data as keyof typeof roleOptions] : '-'),
  },
];

export const initColumnSettings = columns.reduce((prev, curr) => {
  if (curr.key) {
    prev[curr.key] = true;
  }
  return prev;
}, {} as Record<string, boolean>);
