import { LegalAdressType } from 'src/pages/profiles/legalEntitiesProfile/types';
import { LegalStatusesType } from 'src/pages/profiles/legalEntities/types';
import { LegalCreatorType } from 'src/shared/types';

import api from '../api';

export interface LegalEntitiesReqData {
  full_name?: string;
  name?: string;
  ceo_first_name?: string;
  ceo_last_name?: string;
  ceo_phone?: string;
  site_url?: string;
  status?: LegalStatusesType;
  is_archived?: boolean;
  inn?: string;
  creator_id?: number;
  creator_type?: LegalCreatorType;
  pagination: {
    per_page: number;
    page: number;
  };
}

export interface LegalEntityFields {
  billing_descriptor: string;
  full_name: string;
  name: string;
  inn: string;
  kpp: string;
  email: string;
  site_url: string;
  ceo: {
    first_name: string;
    last_name: string;
    middle_name: string;
    birth_date: string;
    phone: string;
    country: string;
  };
  bank_account: {
    account: string;
    bank_name: string;
    bik: string;
    details: string;
    tax: number;
  };
}

export interface LegalEntityCreateReqData {
  legal_entity: LegalEntityFields;
  addresses: {
    type: LegalAdressType | null;
    zip: string;
    country: string;
    city: string;
    street: string;
  }[];
}

export interface LegalEntityEditReqData {
  id: string;
  data: LegalEntityCreateReqData;
}

export interface IIsInternalRequest {
  id: number | string;
  data: {
    is_internal: boolean;
  };
}

export const getLegalEntities = (data: LegalEntitiesReqData) =>
  api.post('legal-entities', data).then(response => response.data);

export const getLegalEntity = (id: string) => api.get(`legal-entity/${id}`).then(response => response.data);

export const createLegalEntity = (data: LegalEntityCreateReqData) =>
  api.post(`legal-entity`, data).then(response => response.data);

export const editLegalEntity = ({ id, data }: LegalEntityEditReqData) =>
  api.put(`/legal-entity/${id}`, data).then(response => response.data);

export const deleteLegalEntity = (id: string) => api.delete(`legal-entity/${id}`).then(response => response.data);

export const regLegalEntityTinkoff = (id: string) =>
  api.post(`/legal-entity/${id}/register-in-tinkoff`).then(response => response.data);

export const archiveLegalEntity = (id: string | number) =>
  api.post(`/legal-entity/${id}/archive`).then(response => response.data);

export const extractLegalEntity = (id: string | number) =>
  api.post(`/legal-entity/${id}/extract`).then(response => response.data);

export const isInternalLegalEntity = ({ id, data }: IIsInternalRequest) =>
  api.put(`/legal-entity/${id}/is-internal`, data).then(response => response.data);
