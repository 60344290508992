import { AxiosError } from 'axios';
import { createStore, createEvent, createEffect, sample } from 'effector';
import { usersApi } from 'src/shared/api';
import { Storage } from 'src/shared/lib';
import { setError, setSuccess } from 'src/features/notifications';
import { Paginated } from 'src/shared/types';

import { User } from './types';
import { initState, initColumnSettings, PER_PAGE } from './config';

export const $users = createStore<Paginated<User> | null>(null);
export const $requestData = createStore<usersApi.UsersRequestData>(initState);
export const $isEmployeeError = createStore(false);
export const $visibleColumns = createStore(initColumnSettings);
Storage.persist.entity($visibleColumns, { slice: 'employees', key: 'tableSettings' });
export const $pageSize = createStore<number>(PER_PAGE);
Storage.persist.entity($pageSize, { slice: 'employees', key: 'pageSize' });

export const updateUser = createEvent<usersApi.UpdatedUserRequestData>();
export const addUser = createEvent<usersApi.AddUserRequestData>();
export const changeRequestData = createEvent<usersApi.UsersRequestData>();
export const changeVisibleColumns = createEvent<Record<string, boolean>>();
export const changePageSize = createEvent<number>();
export const resetEmployeeError = createEvent();

export const getUsersFx = createEffect(usersApi.getUsersList);
export const updateUserFx = createEffect<usersApi.UpdatedUserRequestData, User, AxiosError<{ message: string }>>(
  usersApi.updateUser,
);
export const addUserFx = createEffect<usersApi.AddUserRequestData, User, AxiosError<{ message: string }>>(
  usersApi.addUser,
);

$users.on(getUsersFx.doneData, (_, data) => data);
$requestData.on(changeRequestData, (_, data) => data);
$isEmployeeError
  .on([updateUser, addUser], () => false)
  .on([updateUserFx.doneData, addUserFx.doneData], () => true)
  .reset(resetEmployeeError);

sample({
  clock: changeVisibleColumns,
  target: $visibleColumns,
});

sample({
  clock: updateUser,
  target: updateUserFx,
});

sample({
  clock: addUser,
  target: addUserFx,
});

sample({
  clock: changeRequestData,
  source: $requestData,
  target: getUsersFx,
});

sample({
  clock: [updateUserFx.doneData, addUserFx.doneData],
  source: $requestData,
  target: getUsersFx,
});

sample({
  clock: addUserFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при добавлении сотрудника',
  target: setError,
});

sample({
  clock: updateUserFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при изменении сотрудника',
  target: setError,
});

sample({
  clock: updateUserFx.doneData,
  fn: () => 'Сотрудник успешно отредактирован',
  target: setSuccess,
});

sample({
  clock: addUserFx.doneData,
  fn: () => 'Сотрудник успешно добавлен',
  target: setSuccess,
});

sample({
  clock: changePageSize,
  target: $pageSize,
});
