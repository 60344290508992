import { useEffect } from 'react';
import { useStore } from 'effector-react';
import { Form, Input, Button, TreeSelect } from 'antd';
import { Title } from 'src/shared/ui';
import { treeSelectFieldNames } from 'src/shared/config';

import { $categories, getCategoriesFx } from '../../model';
import { Size, SizeData } from '../../types';
import styles from './AddSize.module.less';

interface AddSizeProps {
  initSize?: Size | null;

  successHandler: (value: SizeData) => void;
  editHandler: (value: SizeData) => void;
  closeHandler: () => void;
}

export const AddSize = ({ initSize, closeHandler, successHandler, editHandler }: AddSizeProps) => {
  const categories = useStore($categories);
  const isLoading = useStore(getCategoriesFx.pending);
  const [form] = Form.useForm();

  useEffect(() => {
    if (initSize) {
      form.setFieldsValue({ ...initSize, category_ids: initSize?.categories?.map(item => item.id) || [] });
    } else {
      form.resetFields();
    }
  }, [form, initSize?.id]);

  const formSubmitHandler = (values: SizeData): void => {
    if (initSize) {
      editHandler({ ...values, category_ids: values.category_ids || [] });
    } else {
      successHandler({ ...values, category_ids: values.category_ids || [] });
    }
    form.resetFields();
    closeHandler();
  };

  const handleCancelClose = () => {
    form.resetFields();
    closeHandler();
  };

  return (
    <div className={styles.addSize}>
      <Title>{initSize ? 'Редактирование размера' : 'Добавление размера'}</Title>
      <Form form={form} name="basic" layout="vertical" onFinish={formSubmitHandler} autoComplete="off">
        <Form.Item
          label="Размер"
          name="value"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите размер!',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item label="Категории" name="category_ids">
          <TreeSelect
            disabled={isLoading}
            multiple
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            allowClear
            fieldNames={treeSelectFieldNames}
            treeData={categories?.items}
          />
        </Form.Item>

        <div className={styles.buttons}>
          <Form.Item>
            <Button htmlType="submit" className={styles.button} type="primary">
              Готово
            </Button>
          </Form.Item>

          <Button onClick={handleCancelClose} className={styles.button}>
            Отмена
          </Button>
        </div>
      </Form>
    </div>
  );
};
