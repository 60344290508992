import { AxiosError } from 'axios';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { Storage } from 'src/shared/lib';
import { clientsApi, postApi, suppliersApi, sourceApi, categoriesApi } from 'src/shared/api';
import { Supplier } from 'src/pages/profiles/suppliers/types';
import { Paginated } from 'src/shared/types';
import { DetailedSource } from 'src/pages/profiles/suppliersProfile/ui/suppParser/ui/suppParserList/SuppParserList';
import { Categories } from 'src/pages/profiles/suppliersProfile/ui/suppParser/ui/addSource/types';
import { DetailedCategory } from 'src/entities/updateSource/types';
import { setError } from 'src/features/notifications';

import { PostData, Posts, PostSupplier, UpdatedPost } from './types';
import { initColumnSettings, initState, PER_PAGE } from './config';
import { ProductParsePost } from '../product/types';

export const $posts = createStore<Posts | null>(null);
export const $suppliers = createStore<Paginated<Supplier> | null>(null);
export const $sources = createStore<DetailedSource[]>([]);
export const $requestData = createStore<clientsApi.ClientsRequestData>(initState);
export const $productParsePost = createStore<ProductParsePost | null>(null);
export const $postDescription = createStore<string[]>([]);
export const $categories = createStore<DetailedCategory[] | null>(null);
export const $visibleColumns = createStore(initColumnSettings);
export const $isParsePostError = createStore(false);
Storage.persist.entity($visibleColumns, { slice: 'posts', key: 'tableSettings' });
export const $pageSize = createStore<number>(PER_PAGE);
Storage.persist.entity($pageSize, { slice: 'posts', key: 'pageSize' });

export const changeVisibleColumns = createEvent<Record<string, boolean>>();
export const changePageSize = createEvent<number>();
export const changeRequestData = createEvent<postApi.PostsReqData>();
export const submitChangeSuppData = createEvent<suppliersApi.SuppRequestData>();
export const submitChangeSourceData = createEvent<sourceApi.GetSourcesReqData>();
export const submitParsePostData = createEvent<number | string>();
export const submitActualPostReqData = createEvent<number | string>();
export const resetProductParsePost = createEvent();
export const resetIsParsePostError = createEvent();

export const getClientsFx = createEffect(clientsApi.getClientsList);
export const getPostsFx = createEffect(postApi.getPosts);
export const getSuppliersFx = createEffect(suppliersApi.getSuppList);
export const getSourcesFx = createEffect(sourceApi.getSources);
export const getCategoriesFx = createEffect(categoriesApi.getCategories);
export const getProductParsePostFx = createEffect(postApi.getProductParsingPost);
export const submitParsePostReqData = createEvent<string | number>();
export const submitProductParseFx = createEffect(postApi.parsePost);
export const getActualPostInfoFx = createEffect<string | number, UpdatedPost, AxiosError<{ message: string }>>(
  postApi.getActualPostInfo,
);

$posts
  .on(getPostsFx.doneData, (_, data: Posts) => {
    const resultData: PostData[] = data?.items
      ? data.items.map(item => {
          const supplier: PostSupplier = data?.suppliers?.[item?.source?.supplier_id] || {};
          return { ...item, supplier };
        })
      : [];
    return { ...data, items: resultData };
  })
  .on(submitProductParseFx.doneData, (postsList, updatedPost: UpdatedPost) => {
    if (postsList && updatedPost) {
      const updatedPostListItems = postsList.items.map(item =>
        item.id === updatedPost.post.id ? { ...item, ...updatedPost.post } : item,
      );
      return { ...postsList, items: updatedPostListItems };
    }
    return postsList;
  });
$requestData.on(changeRequestData, (_, data) => data);
$suppliers.on(getSuppliersFx.doneData, (_, data) => data);
$sources.on(getSourcesFx.doneData, (_, data: Paginated<DetailedSource>) => {
  const resultData = data?.items ? data.items.map(item => ({ ...item, ...{ name: item?.name || item?.url } })) : [];
  return resultData;
});
$categories.on(getCategoriesFx.doneData, (_, data: Categories) => {
  const resultData: DetailedCategory[] = data?.items ? data.items.map(item => ({ ...item, key: item?.id || '' })) : [];
  return resultData;
});
$productParsePost
  .on([getProductParsePostFx.doneData, submitProductParseFx.doneData, getActualPostInfoFx.doneData], (_, data) => data)
  .reset(resetProductParsePost);
$postDescription.on(
  [getProductParsePostFx.doneData, submitProductParseFx.doneData, getActualPostInfoFx.doneData],
  (_, data: ProductParsePost) => {
    const resultData = data?.post?.description?.split('\n') || [];
    return resultData;
  },
);
$isParsePostError.on(getActualPostInfoFx.failData, () => true).reset(resetIsParsePostError);

sample({
  clock: changeRequestData,
  source: $requestData,
  target: getPostsFx,
});

sample({
  clock: changeVisibleColumns,
  target: $visibleColumns,
});

sample({
  clock: changePageSize,
  target: $pageSize,
});

sample({
  clock: submitChangeSuppData,
  target: getSuppliersFx,
});

sample({
  clock: submitChangeSourceData,
  target: getSourcesFx,
});

sample({
  clock: submitParsePostReqData,
  target: [getActualPostInfoFx, getCategoriesFx],
});

sample({
  clock: submitActualPostReqData,
  target: getActualPostInfoFx,
});

sample({
  clock: submitParsePostData,
  target: submitProductParseFx,
});

sample({
  clock: getActualPostInfoFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка загрузки информации о парсинге поста',
  target: setError,
});
