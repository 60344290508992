import { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Select, Spin, TreeSelect, TreeSelectProps } from 'antd';
import { useStore } from 'effector-react';
import { setError } from 'src/features/notifications';
import { DragAndDrop, Title } from 'src/shared/ui';
import MDEditor from '@uiw/react-md-editor';
import { Image } from 'src/shared/lib';

import { Document, DocumentForm, TreeData } from '../../types';
import { documentsCodes } from '../../config';
import { $editingDocument, getDocumentFx, getFileFx, getSubRowsFx } from '../../model';
import styles from './DocumentModal.module.less';

interface ModalProps {
  treeData: TreeData[];
  closeHandler: () => void;
  submitHandler: (data: DocumentForm) => void;
}

export const DocumentModal = ({ treeData, closeHandler, submitHandler }: ModalProps) => {
  const [isEditCanceled, setIsEditCanceled] = useState(true);
  const [fileList, setFileList] = useState<File[]>([]);
  const document = useStore($editingDocument);
  const isLoading = useStore(getDocumentFx.pending);
  const [form] = Form.useForm();

  const selectOptions = Object.keys(documentsCodes).map((key: string) => ({
    value: key,
    label: `${documentsCodes[key]} (${key})`,
  }));

  useEffect(() => {
    form.setFieldsValue(document);
    setIsEditCanceled(false);
    if (!document) {
      form.resetFields();
    }
  }, [form, document, document?.id, isEditCanceled]);

  useEffect(() => {
    if (document?.document_url) {
      getInitialFile(document.document_url);
    } else {
      setFileList([]);
    }
  }, [document?.document_url]);

  const confirm = async (values: Document) => {
    try {
      const doc = fileList.length > 0 ? await Image.toBase64(fileList[0]) : '';
      submitHandler({ ...values, document: doc ? `${doc}` : '', code: values.code || null });
      form.resetFields();
      setFileList([]);
    } catch (err) {
      setError('Ошибка загрузки изображения');
    }
  };

  const onLoadData: TreeSelectProps['loadData'] = async ({ id }) => {
    await getSubRowsFx({ parent_id: id });
  };

  const getInitialFile = async (url: string) => {
    const file = await getFileFx(url);
    setFileList([file]);
  };

  const handleCancelClose = () => {
    form.resetFields();
    closeHandler();
    if (document) {
      setIsEditCanceled(true);
    }
  };

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.documentModal}>
      <Title>{document ? 'Изменение документа' : 'Создание документов'}</Title>
      <Form form={form} name="basic" onFinish={confirm} autoComplete="off" layout="vertical">
        <Form.Item
          label="Наименование"
          name="name"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите наименование!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item label="Код" name="code">
          <Select options={selectOptions} allowClear disabled={Boolean(document)} placeholder="Выберите код" />
        </Form.Item>
        <Form.Item name="parent_id" label="Родительский документ">
          <TreeSelect
            treeDataSimpleMode
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Выберите родительский документ"
            loadData={onLoadData}
            treeData={treeData}
            allowClear
          />
        </Form.Item>
        <Form.Item name="content">
          <MDEditor className={styles.markdown} />
        </Form.Item>
        <Form.Item label="Загрузить документ" name="document">
          <DragAndDrop name="document" multiple={false} fileList={fileList} setFileList={setFileList} maxCount={1} />
        </Form.Item>
        <Form.Item name="published" valuePropName="checked" initialValue={false}>
          <Checkbox>Опубликован</Checkbox>
        </Form.Item>
        <div className={styles.buttons}>
          <Form.Item>
            <Button htmlType="submit" className={styles.button} type="primary">
              Готово
            </Button>
          </Form.Item>

          <Button onClick={handleCancelClose} className={styles.button}>
            Отмена
          </Button>
        </div>
      </Form>
    </div>
  );
};
