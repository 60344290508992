import { Menu } from 'antd';
import { OrdersList, TaskList } from 'src/pages/orders';
import { Route, Routes, Link, useLocation, Navigate } from 'react-router-dom';
import { ScanCode } from 'src/features/scanCode';
import { orderStatus, taskStatus } from 'src/shared/config';
import { useIsAdmin, useIsReceiver, useIsStorekeeper } from 'src/widgets/header';
import { useIsGTS } from 'src/widgets/header/model';

import styles from './Stock.module.less';
import { ReceptionPoint } from '../receptionPoint';

export const StockPage = () => {
  const location = useLocation();
  const isAdmin = useIsAdmin();
  const isReceiver = useIsReceiver();
  const isStorekeeper = useIsStorekeeper();
  const canReceive = isAdmin || isReceiver;
  const canStore = isAdmin || isStorekeeper;
  const isGTS = useIsGTS();

  return (
    <section className={styles.stock}>
      <h2 className={styles.title}>Склад</h2>

      <Menu mode='horizontal' className={styles.menu} selectedKeys={[location.pathname]}>
        {isGTS ? (
          <Menu.Item key='/stock/reception-point'>
            <Link to='/stock/reception-point'>В пункте приёма</Link>
          </Menu.Item>
        ) : (
          <>
            {canReceive ? (
              <Menu.Item key='/stock'>
                <Link to='/stock'>Сканировать</Link>
              </Menu.Item>
            ) : null}
            {canReceive ? (
              <Menu.Item key='/stock/inspection'>
                <Link to='/stock/inspection'>Приемка</Link>
              </Menu.Item>
            ) : null}
            {canStore ? (
              <Menu.Item key='/stock/reception-point'>
                <Link to='/stock/reception-point'>В пункте приёма</Link>
              </Menu.Item>
            ) : null}
            {canReceive ? (
              <Menu.Item key='/stock/storage'>
                <Link to='/stock/storage'>Хранение</Link>
              </Menu.Item>
            ) : null}
            {isAdmin ? (
              <Menu.Item key='/stock/paymentError'>
                <Link to='/stock/paymentError'>Проблема оплаты</Link>
              </Menu.Item>
            ) : null}
            {canStore ? (
              <Menu.Item key='/stock/assembly'>
                <Link to='/stock/assembly'>Готов к сборке</Link>
              </Menu.Item>
            ) : null}
            {canStore ? (
              <Menu.Item key='/stock/packaging'>
                <Link to='/stock/packaging'>Упаковка</Link>
              </Menu.Item>
            ) : null}
            {canStore ? (
              <Menu.Item key='/stock/for-pickup'>
                <Link to='/stock/for-pickup'>Готовы к самовывозу</Link>
              </Menu.Item>
            ) : null}
            {canStore ? (
              <Menu.Item key='/stock/sending'>
                <Link to='/stock/sending'>Отправка</Link>
              </Menu.Item>
            ) : null}
            {canStore ? (
              <Menu.Item key='/stock/courierWaiting'>
                <Link to='/stock/courierWaiting'>Ожидает курьера</Link>
              </Menu.Item>
            ) : null}
            {canStore ? (
              <Menu.Item key='/stock/delivery'>
                <Link to='/stock/delivery'>В пути</Link>
              </Menu.Item>
            ) : null}
            {isAdmin ? (
              <Menu.Item key='/stock/delivered'>
                <Link to='/stock/delivered'>Доставлен</Link>
              </Menu.Item>
            ) : null}
            {isAdmin ? (
              <Menu.Item key='/stock/done'>
                <Link to='/stock/done'>Завершенные</Link>
              </Menu.Item>
            ) : null}
            {/* TODO: add when analytics will be done */}
            {/* {isAdmin ? (
          <Menu.Item key="/stock/write-off">
            <Link to="/stock/write-off">Списанные</Link>
          </Menu.Item>
        ) : null} */}
          </>
        )}
      </Menu>
      <div>
        <Routes>
          <Route path='/' element={(isGTS && <Navigate to='reception-point' />) || (isStorekeeper && <Navigate to='assembly' />) || <ScanCode />} />
          {canReceive ? <Route path='/inspection' element={<TaskList status={taskStatus.inspection} />} /> : null}
          {(isGTS || isAdmin) ? <Route path='/reception-point' element={<ReceptionPoint />} /> : null}
          {canReceive ? <Route path='/storage' element={<TaskList status={taskStatus.inStock} />} /> : null}
          {canStore ? (
            <Route path='/assembly' element={<OrdersList withAssemblyTask status={orderStatus.debitSuccessfull} />} />
          ) : null}
          {isAdmin ? (
            <Route path='/paymentError' element={<OrdersList withPayError status={orderStatus.debitFail} />} />
          ) : null}
          {canStore ? (
            <Route path='/packaging' element={<OrdersList askSending status={orderStatus.assembly} />} />
          ) : null}
          {canStore ? (
            <Route path='/for-pickup' element={<OrdersList isPickup status={orderStatus.forPickup} />} />
          ) : null}
          {canStore ? (
            <Route path='/sending' element={<OrdersList packing status={orderStatus.deliveryCreated} />} />
          ) : null}
          {canStore ? (
            <Route path='/courierWaiting' element={<OrdersList waitingCourier status={orderStatus.courierWaiting} />} />
          ) : null}
          {canStore ? <Route path='/delivery' element={<OrdersList status={orderStatus.delivery} />} /> : null}
          {isAdmin ? <Route path='/delivered' element={<OrdersList status={orderStatus.delivered} />} /> : null}
          {isAdmin ? <Route path='/done' element={<OrdersList status={orderStatus.done} />} /> : null}
          {/* TODO: add when analytics will be done */}
          {/* {isAdmin ? <Route path="/write-off" element={<Empty description="Нет данных" />} /> : null} */}
        </Routes>
      </div>
    </section>
  );
};
