import { useRef, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Button, Card, Form, Input } from 'antd';
import type { InputRef } from 'antd';
import { QrcodeOutlined } from '@ant-design/icons';
import { Title } from 'src/shared/ui';

import { submitCode, getTaskByCodeFx } from './model';
import { FormData } from './types';
import styles from './ScanCode.module.less';

interface ScanCodeProps {
  isCountActive?: boolean;
}

export const ScanCode = ({ isCountActive }: ScanCodeProps) => {
  const [form] = Form.useForm();
  const isLoading = useStore(getTaskByCodeFx.pending);
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputRef?.current && !isCountActive) {
      inputRef.current.focus();
    }
  }, [isCountActive]);

  const handleSubmitCode = (values: FormData) => {
    submitCode(values);
    form.resetFields();
  };

  const handleClickScanner = () => {
    if (inputRef?.current && !isCountActive) {
      inputRef.current.focus();
    }
  };

  return (
    <div className={styles.wrapper} role="presentation">
      <Card>
        <div className={styles.info}>
          <Title>Сканирование</Title>
          <div className={styles.scan}>
            <QrcodeOutlined className={styles.icon} />
            <p className={styles.title}>Отсканируйте QR-код на заказе</p>
          </div>
          <p className={styles.subtitle}>или</p>
        </div>
        <Form form={form} layout="vertical" onFinish={handleSubmitCode} className={styles.form}>
          <Form.Item
            className={styles.input}
            name="code"
            label="Введите код:"
            rules={[{ required: true, message: 'Введите код' }]}>
            <Input ref={inputRef} disabled={isLoading} autoFocus onBlur={handleClickScanner} />
          </Form.Item>
          <Button loading={isLoading} htmlType="submit">
            Отправить
          </Button>
        </Form>
      </Card>
    </div>
  );
};
