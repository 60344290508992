import { ClientStatus } from 'src/pages/profiles/clientsProfile';
import { Post } from 'src/pages/orders/order/types';
import { DateType } from 'src/shared/ui/datePicker';
import { TPavilion } from 'src/shared/api/queries/tasksApi';

export interface DetailedTask {
  task: {
    id: number;
    code: string;
    status: Status;
    status_name: string;
    count: number;
    amount?: number;
    created_at?: DateType;
    received_count: number;
    comment: string;
    product_offer_id: number;
    product_offer_image: string;
    take_in_work_date: DateType;
    order: {
      id: number;
      code: string;
      status: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
    };
    intermediary_id: number;
    client_id: number;
    supplier_pavilion_id: number;
    history: [
      {
        status: Status;
        user_type: UserType;
        user_id: number;
        created_at: DateType;
      },
    ];
  };
  offer: {
    id: number;
    price: number;
    characteristics: [
      {
        id: number;
        name: string;
        value: string;
        code: CharacteristicsType;
      },
    ];
    product: {
      id: number;
      name: string;
      images: string[];
      description: string;
      supplier_pavilion_id: number;
      status: 'active' | 'deactivated' | 'in_review';
    };
    status: 'active' | 'deactivated';
  };
  intermediary: TIntermediary;
  client: {
    id: number;
    name: string;
    email: string;
    phone: string;
    avatar_url: string;
    status: ClientStatus;
    created_at: DateType;
  };
  post: Post;
  supplier_pavilion: {
    id: number;
    supplier: {
      id: number;
      name: string;
      phone?: string;
      description: string;
      avatar: string;
    };
    pavilion: TPavilion;
  };
  history: TasksHistory[];
  chat: { id: number };
}

export type TIntermediary = {
    id: number;
    name: string;
    email: string;
    phone: string;
    avatar_url: string;
    pass_number: string;
    status: 'APPROVED' | 'REJECTED' | 'CHECKING';
    created_at: DateType;
  }

export interface TasksHistory {
  id?: string;
  status: Status;
  status_name: string;
  created_at: DateType;
  user: {
    id: number;
    type: UserType;
    name: string;
  };
}

export type Status = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export interface TaskTransfer {
  product_count: number;
}

export interface TaskQr {
  url: string;
}

export enum CharacteristicsType {
  size = 'size',
  price = 'price',
  picture = 'picture',
}

enum UserType {
  CLIENT = 'CLIENT',
  CLIENT_BANNED = 'CLIENT_BANNED',
  USER = 'USER',
  INTERMEDIARY_APPROVED = 'INTERMEDIARY_APPROVED',
  INTERMEDIARY_NOT_APPROVED = 'INTERMEDIARY_NOT_APPROVED',
}
