import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { NumberTypeEnum } from 'src/shared/types';
import { TableSettings } from 'src/entities/tableSettings';
import { Hooks , getIsoTimeZoneTime } from 'src/shared/lib';
import { getNumbers } from 'src/shared/lib/getNumbers';
import { Table, Input, Spin, Empty, Card, Tooltip } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/interface';

import {
  $intermediaries,
  $pageSize,
  $visibleColumns,
  changePageSize,
  changeRequestData,
  changeVisibleColumns,
  getIntermediariesFx,
} from './model';
import { columns, mainColumnName, PER_PAGE, statusOptions } from './config';
import { Intermediary } from './types';
import styles from './ProfilesIntermediaries.module.less';

export const ProfilesIntermediaries = () => {
  const [nameValue, setNameValue] = useState('');
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const visibleColumnsKeys = useStore($visibleColumns);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const intermediaries = useStore($intermediaries);
  const isLoading = useStore(getIntermediariesFx.pending);
  const pageSize = useStore($pageSize);
  const [searchParams, setSearchParams] = Hooks.useSearchParamsObject();

  useEffect(() => {
    const { page, statuses, ...filters } = searchParams;
    const resultStatuses = statuses ? statuses.split(',') : [];
    const currPage = page ? +page : 1;

    changeRequestData({ ...filters, statuses: resultStatuses, pagination: { page: currPage, per_page: pageSize } });
  }, [searchParams, pageSize]);

  useEffect(() => {
    const resultVisibleColumns: ColumnsType<Intermediary> = [];

    columns.forEach(column => {
      if (
        column.key &&
        (!Object.prototype.hasOwnProperty.call(visibleColumnsKeys, column.key) || visibleColumnsKeys[column.key])
      ) {
        resultVisibleColumns.push(column);
      }
    });

    setVisibleColumns(resultVisibleColumns);
  }, [visibleColumnsKeys]);

  const searchNameHandler = () => {
    setSearchParams({ ...searchParams, name: nameValue, page: 1 });
  };

  const searchEmailHandler = (value: string) => {
    setSearchParams({ ...searchParams, email: value || null, page: 1 });
  };

  const searchPhoneHandler = (value: string) => {
    setSearchParams({ ...searchParams, phone: value ? getNumbers(value) : null, page: 1 });
  };

  const searchCreatedDateHandler = (data: { date_after: string; date_to: string }) => {
    setSearchParams({
      ...searchParams,
      created_at_from: data?.date_after ? getIsoTimeZoneTime({ day: data.date_after }, 'from') : null,
      created_at_to: data?.date_to ? getIsoTimeZoneTime({ day: data.date_after }, 'to') : null,
      page: 1,
    });
  };

  const handleSelectStatus = (value: string[]) =>
    setSearchParams({ ...searchParams, statuses: value || null, page: 1 });

  const getEmailSearch = Hooks.useColumnSearch<Intermediary>(
    searchEmailHandler,
    'email',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.email,
  );

  const getPhoneSearch = Hooks.useColumnSearch<Intermediary>(
    searchPhoneHandler,
    NumberTypeEnum.PHONE,
    isActiveFilter,
    setIsActiveFilter,
    searchParams.phone,
  );

  const getDateSearch = Hooks.useColumnSearchDate<Intermediary>(
    searchCreatedDateHandler,
    isActiveFilter,
    setIsActiveFilter,
  );

  const getStatusSearch = Hooks.useColumnMultiSelect<Intermediary>(
    handleSelectStatus,
    statusOptions,
    'Выбрать статус',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.status,
  );

  const filteredColumns: ColumnsType<Intermediary> = visibleColumns.map(column => {
    switch (column.key) {
      case 'email':
        return { ...column, ...getEmailSearch(column.key, column.title) };
      case 'phone':
        return { ...column, ...getPhoneSearch(column.key, column.title) };
      case 'created_at':
        return { ...column, ...getDateSearch(column.key, column.title) };
      case 'status':
        return { ...column, ...getStatusSearch(column.key, column.title) };
      default:
        return column;
    }
  });

  const handleChangePage = (value: number) => {
    setSearchParams({ ...searchParams, page: value.toString() });
  };

  const handleChangePageSize = (currentSize: number, size: number) => {
    changePageSize(size);
  };

  const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value && nameValue) {
      const { name, ...filteredReqData } = searchParams;
      setSearchParams({ ...filteredReqData, page: 1 });
    }
    setNameValue(e.target.value);
  };

  const handleResetFilters = () => {
    setSearchParams({ page: 1 });
    setIsActiveFilter(true);
    setNameValue('');
  };

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return intermediaries ? (
    <Card className={styles.body}>
      <div className={styles.searchSettings}>
        <Input.Search
          value={nameValue}
          className={styles.search}
          placeholder="Поиск"
          onChange={handleSearchName}
          onSearch={searchNameHandler}
          allowClear
        />
        <div className={styles.wrapper}>
          <Tooltip overlayStyle={{ position: 'fixed' }} title="Очистить все фильтры">
            <ClearOutlined className={styles.clear} onClick={handleResetFilters} />
          </Tooltip>
          <TableSettings
            columns={columns}
            disabledColumns={[mainColumnName]}
            visibleColumnsKeys={visibleColumnsKeys}
            setVisibleColumnsKeys={changeVisibleColumns}
          />
        </div>
      </div>

      <Table
        rowKey={record => record.id}
        bordered
        columns={filteredColumns}
        dataSource={intermediaries?.items || []}
        scroll={{ x: 1200 }}
        pagination={{
          onChange: handleChangePage,
          onShowSizeChange: handleChangePageSize,
          current: intermediaries?.pagination?.current_page || 1,
          pageSize: intermediaries?.pagination?.per_page || PER_PAGE,
          total: intermediaries?.pagination?.total || 0,
          showSizeChanger: true,
        }}
        locale={{ emptyText: <Empty description="Нет данных" /> }}
      />
    </Card>
  ) : (
    <Empty description="Нет данных" />
  );
};
