import { useEffect } from 'react';
import { useStore } from 'effector-react';
import { Empty, Menu, Spin } from 'antd';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useIsAdmin } from 'src/widgets/header';
import { ProfilesCard } from 'src/entities/profilesCard';
import { BackArrow } from 'src/entities/backArrow';
import { LatestTasks } from 'src/features/latestTasks';
import { Posts } from 'src/pages/products/posts';

import {
  $isOpenModal,
  $isPrevPath,
  $isArchiveError,
  initSuppliersProfilePage,
  setIsPrevPath,
  getSupplierFx,
  handleModal,
  useSupplier,
  editSupplierFx,
  submitEditSupplier,
  resetArchiveError,
} from '../../model';
import { EditModal } from '../../../suppliers/ui';
import styles from './SuppliersProfile.module.less';
import { SupplierProfile } from '../../types';
import { SuppParser } from '../suppParser';
import { SuppProductsList } from '../suppProductsList';

interface SuppliersProfileProps {
  id?: string;
}

export const SuppliersProfile = ({ id }: SuppliersProfileProps) => {
  const location = useLocation();
  const prevPath = useLocation().state as { prevPath: boolean };
  const profile = useSupplier();
  const isAdmin = useIsAdmin();
  const isLoading = useStore(getSupplierFx.pending);
  const loadingEdit = useStore(editSupplierFx.pending);
  const isOpen = useStore($isOpenModal);
  const isPrevPath = useStore($isPrevPath);
  const isArchiveError = useStore($isArchiveError);

  useEffect(() => {
    const prevPathData = prevPath?.prevPath || isPrevPath || false;
    setIsPrevPath(prevPathData);
    if (id) {
      initSuppliersProfilePage(id);
    }
  }, [id]);

  const editSupplier = (data: SupplierProfile) => {
    submitEditSupplier({ id: id || '', data });
  };

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {isPrevPath ? <BackArrow /> : null}
      {profile ? (
        <>
          <div className={styles['cards-wrapper']}>
            <ProfilesCard
              data={profile}
              allowEdit={isAdmin}
              editHandler={() => handleModal(true)}
              isArchiveError={isArchiveError}
              resetArchiveError={resetArchiveError}
            />
          </div>
          <div>
            <div className={styles.content}>
              <Menu disabledOverflow mode="horizontal" className={styles.menu} selectedKeys={[location.pathname]}>
                <Menu.Item key={`/profile/supplier/${id}`}>
                  <Link to={`/profile/supplier/${id}`} replace>
                    Список товаров
                  </Link>
                </Menu.Item>
                <Menu.Item key={`/profile/supplier/${id}/tasks`}>
                  <Link to={`/profile/supplier/${id}/tasks`} replace>
                    Задания
                  </Link>
                </Menu.Item>
                {/* Todo: add when analytics will be done */}
                {/* {isAdmin ? (
                  <Menu.Item key={`/profile/supplier/${id}/statistic`}>
                    <Link to={`/profile/supplier/${id}/statistic`} replace>
                      Статистика продаж
                    </Link>
                  </Menu.Item>
                ) : null} */}
                {isAdmin ? (
                  <Menu.Item key={`/profile/supplier/${id}/parser`}>
                    <Link to={`/profile/supplier/${id}/parser`} replace>
                      Парсер
                    </Link>
                  </Menu.Item>
                ) : null}
                <Menu.Item key={`/profile/supplier/${id}/posts`}>
                  <Link to={`/profile/supplier/${id}/posts`} replace>
                    Посты
                  </Link>
                </Menu.Item>
              </Menu>
            </div>

            <Routes>
              <Route path="*" element={<SuppProductsList idType="supplier_id" id={id} />} />
              <Route path="/tasks" element={<LatestTasks id={id} type="supplier_id" />} />
              {isAdmin ? <Route path="/statistic" element={<Empty description="В разработке" />} /> : null}
              {isAdmin ? <Route path="/parser" element={<SuppParser id={id} pavilions={profile.pavilions} />} /> : null}
              <Route path="/posts" element={<Posts id={id} />} />
            </Routes>
          </div>
          <EditModal
            isOpen={isOpen}
            loading={loadingEdit}
            profile={profile}
            onClose={() => handleModal(false)}
            handleSupplier={editSupplier}
          />
        </>
      ) : (
        <Empty description="Профиль не найден" />
      )}
    </div>
  );
};
