import { AxiosError } from 'axios';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { Storage } from 'src/shared/lib';
import { legalEntitiesApi, suppliersApi, usersApi } from 'src/shared/api';
import { Paginated } from 'src/shared/types';
import { setError, setSuccess } from 'src/features/notifications';
import { archiveErrorStatus } from 'src/shared/config';

import { LegalEntityData } from './types';
import { Supplier } from '../suppliers/types';
import { User } from '../../employees/types';
import { initColumnSettings, initState, PER_PAGE } from './config';

export const $legalEntities = createStore<LegalEntityData | null>(null);
export const $requestData = createStore<legalEntitiesApi.LegalEntitiesReqData>(initState);
export const $visibleColumns = createStore(initColumnSettings);
Storage.persist.entity($visibleColumns, { slice: 'legalEntities', key: 'tableSettings' });
export const $pageSize = createStore<number>(PER_PAGE);
Storage.persist.entity($pageSize, { slice: 'legalEntities', key: 'pageSize' });
export const $isArchiveError = createStore(false);
export const $suppliers = createStore<Paginated<Supplier> | null>(null);
export const $users = createStore<Paginated<User> | null>(null);

export const changeVisibleColumns = createEvent<Record<string, boolean>>();
export const changeRequestData = createEvent<legalEntitiesApi.LegalEntitiesReqData>();
export const changePageSize = createEvent<number>();
export const submitArchLegalReqData = createEvent<string | number>();
export const resetArchiveError = createEvent();
export const submitChangeSuppData = createEvent<suppliersApi.SuppRequestData>();
export const submitChangeUsersData = createEvent<usersApi.UsersRequestData>();

export const getLegalEntitiesFx = createEffect(legalEntitiesApi.getLegalEntities);
export const createLegalEntityFx = createEffect(legalEntitiesApi.createLegalEntity);
export const archiveLegalEntityFx = createEffect<string | number, null, AxiosError<{ message: string }>>(
  legalEntitiesApi.archiveLegalEntity,
);
export const getSuppliersFx = createEffect(suppliersApi.getSuppList);
export const getUsersFx = createEffect(usersApi.getUsersList);

$legalEntities.on(getLegalEntitiesFx.doneData, (_, data: LegalEntityData) => {
  const resultData = (data?.items || []).map(item => {
    const creator =
      item?.creator_type === 'SUPPLIER' ? data?.suppliers?.[item.creator_id!] : data?.users?.[item.creator_id!];
    return { ...item, creator };
  });
  return { ...data, items: resultData };
});
$requestData.on(changeRequestData, (_, data) => data);
$isArchiveError.reset(resetArchiveError);
$suppliers.on(getSuppliersFx.doneData, (_, data) => data);
$users.on(getUsersFx.doneData, (_, data) => data);

sample({
  clock: changeVisibleColumns,
  target: $visibleColumns,
});

sample({
  clock: changeRequestData,
  source: $requestData,
  target: getLegalEntitiesFx,
});

sample({
  clock: changePageSize,
  target: $pageSize,
});

sample({
  clock: submitChangeSuppData,
  target: getSuppliersFx,
});

sample({
  clock: submitChangeUsersData,
  target: getUsersFx,
});

sample({
  clock: submitArchLegalReqData,
  target: archiveLegalEntityFx,
});

sample({
  clock: archiveLegalEntityFx.doneData,
  source: $requestData,
  target: getLegalEntitiesFx,
});

sample({
  clock: archiveLegalEntityFx.failData,
  filter: data => data.response?.status === archiveErrorStatus,
  fn: () => true,
  target: $isArchiveError,
});

sample({
  clock: archiveLegalEntityFx.doneData,
  fn: () => 'Юр.лицо успешно отправлено в архив',
  target: setSuccess,
});

sample({
  clock: archiveLegalEntityFx.failData,
  filter: data => data.response?.status !== archiveErrorStatus,
  fn: data => data.response?.data?.message || 'Ошибка при отправке юр.лица в архив',
  target: setError,
});
