import { useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useStore } from 'effector-react';
import { Empty, Menu, Spin } from 'antd';
import { ProfilesBalance } from 'src/entities/profilesBalance';
import { LatestTasks } from 'src/features/latestTasks';
import { BackArrow } from 'src/entities/backArrow';
import { ProfilesPass } from 'src/entities/profilesPass';
import { useIsAdmin } from 'src/widgets/header';
import { ConfirmForm } from 'src/shared/ui';

import styles from './IntermediariesProfile.module.less';
import { PassEditModal } from './ui/passEditModal';
import { ProfilePass } from './types';
import { IntermedProfilesCard } from './ui/intermedProfilesCard';
import {
  $balance,
  $isPrevPath,
  initIntermedProfilePage,
  setIsPrevPath,
  getProfileFx,
  useProfile,
  submitChangeStatusForm,
  submitPassEdition,
  submitPayBalanceReqData,
  submitDeleting,
  submitForceDeleting,
} from './model';

interface ProfileProps {
  id?: string;
}

export const IntermediariesProfile = ({ id }: ProfileProps) => {
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const location = useLocation();
  const prevPath = useLocation().state as { prevPath: boolean };
  const profile = useProfile();
  const balance = useStore($balance);
  const isLoading = useStore(getProfileFx.pending);
  const isAdmin = useIsAdmin();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenFull, setIsOpenFull] = useState(false);
  const isPrevPath = useStore($isPrevPath);

  useEffect(() => {
    const prevPathData = prevPath?.prevPath || isPrevPath || false;
    setIsPrevPath(prevPathData);
    if (id) {
      initIntermedProfilePage(id);
    }
  }, [id]);

  const handleChangeStatus = (value: string): void => {
    if (id) {
      submitChangeStatusForm({ id, type: value });
    }
  };

  const handlePayoutBalance = (): void => {
    if (id) {
      submitPayBalanceReqData(id);
    }
  };

  const submitDeletingIntermediary = () => {
    if (id) {
      submitDeleting(id);
      setIsOpen(false);
    }
  };

  const submitForceDeletingIntermediary = () => {
    if (id) {
      submitForceDeleting(id);
      setIsOpenFull(false);
    }
  };

  const editPassHandler = (data: ProfilePass) => {
    if (profile) {
      submitPassEdition({
        id: profile.id,
        name: profile.name,
        email: profile.email,
        status: profile.status,
        phone: profile.phone,
        ...data,
      });
      setIsOpenEdit(false);
    }
  };

  if (isLoading) {
    return <Spin size="large" />;
  }

  return (
    <div className={styles.wrapper}>
      {isPrevPath ? <BackArrow /> : null}
      {profile ? (
        <>
          <div className={styles['cards-wrapper']}>
            <IntermedProfilesCard
              allowEdit={isAdmin}
              data={profile}
              handleChangeStatus={handleChangeStatus}
              deleteProfile={() => setIsOpen(true)}
              forceDeleteProfile={() => setIsOpenFull(true)}
            />
            <ProfilesPass allowEdit={isAdmin} data={profile} editPassHandler={() => setIsOpenEdit(true)} />
            <ProfilesBalance allowAction={isAdmin} data={balance} payoutHandler={handlePayoutBalance} />
          </div>

          <div>
            <div className={styles.content}>
              <Menu disabledOverflow mode="horizontal" className={styles.menu} selectedKeys={[location.pathname]}>
                <Menu.Item key={`/profile/intermediary/${id}`}>
                  <Link to={`/profile/intermediary/${id}`} replace>
                    Задания
                  </Link>
                </Menu.Item>
                {/* Todo: add when analytics will be done */}
                {/* {isAdmin ? (
                  <Menu.Item key={`/profile/intermediary/${id}/statistic/`}>
                    <Link to={`/profile/intermediary/${id}/statistic/`} replace>
                      Статистика выкупов
                    </Link>
                  </Menu.Item>
                ) : null} */}
              </Menu>
            </div>

            <Routes>
              <Route path="*" element={<LatestTasks id={id} type="intermediary_id" />} />
              {isAdmin ? <Route path="/statistic" element={<Empty description="В разработке" />} /> : null}
            </Routes>
          </div>
        </>
      ) : (
        <Empty description="Профиль не найден" />
      )}
      <PassEditModal
        isOpen={isOpenEdit}
        pass={profile}
        closeHandler={() => setIsOpenEdit(false)}
        submitHandler={editPassHandler}
      />

      {isAdmin ? (
        <>
          <ConfirmForm
            isOpen={isOpen}
            title="Вы собираетесь удалить профиль посредника. Восстановление профиля в дальнейшем невозможно, пользователь не сможет более войти в свой профиль. Вы действительно хотите удалить профиль посредника?"
            closeHandler={() => setIsOpen(false)}
            confirmHandler={submitDeletingIntermediary}
          />
          <ConfirmForm
            isOpen={isOpenFull}
            title="Вы уверены? Данная операция необратима"
            closeHandler={() => setIsOpenFull(false)}
            confirmHandler={submitForceDeletingIntermediary}
          />
        </>
      ) : null}
    </div>
  );
};
