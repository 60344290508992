import type { ColumnsType } from 'antd/es/table';

import { Naming } from './types';

export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export const columns: ColumnsType<Naming> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: data => data || '-',
    width: '3%',
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    render: data => data || '-',
  },
  {
    title: 'Маски',
    dataIndex: 'masks',
    key: 'masks',
    render: data => (data?.length ? data.join(', ') : '-'),
  },
];
