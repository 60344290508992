import { Avatar, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { Task } from 'src/pages/orders';
import dayjs from 'dayjs';
import { PictureOutlined } from '@ant-design/icons';

import styles from './ReceptionPointTable.module.less';

export const getColumns = ({
  onReturn,
  isGTS,
  onPrintQr,
  onPreview,
}: {
  onReturn: (value: Task) => void;
  isGTS: boolean;
  onPrintQr: (value: { code: string; id: number; count: number }) => void;
  onPreview: (value: Task) => void;
}) => {
  const columns: ColumnsType<Task> = [
    {
      title: 'Задание',
      dataIndex: 'code',
      key: 'code',
      render: (_, data) => (
        <div className={styles.cell}>
          <Avatar
            shape='circle'
            size='large'
            icon={<PictureOutlined />}
            src={data.product_offer_image}
            onClick={() => onPreview(data)}
          />
          {(!data.code && '-') || (isGTS && data.code) || (
            <Link to={`/task/${data.id}`} state={{ prevPath: true }}>
              {data.code}
            </Link>
          )}
        </div>
      ),
    },
    {
      title: 'Количество товаров',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: 'Принято',
      dataIndex: 'created_at',
      key: 'created_at',
      render: data => (data ? dayjs(data).format('DD.MM.YYYY HH:mm') : ''),
    },
    {
      title: 'Поставщик',
      dataIndex: 'supplier',
      key: 'supplier',
      render: data =>
        data ? (
          <Link to={`/profile/supplier/${data?.id}`} state={{ prevPath: true }}>
            {data?.name}
          </Link>
        ) : (
          '-'
        ),
    },
    {
      title: 'Павильон',
      dataIndex: 'pavilion',
      key: 'pavilion',
    },
    {
      title: 'Действия',
      key: 'actions',
      render: data => (
        <div className={styles.actions}>
          <Button onClick={() => onPrintQr({ code: data.code, id: data.id, count: data.count || 1 })}>Печать QR</Button>
          <Button onClick={() => onReturn(data)}>Вернуть</Button>
        </div>
      ),
    },
  ];

  const initColumnSettings = columns.reduce((prev, curr) => {
    if (curr.key) {
      prev[curr.key] = true;
    }
    return prev;
  }, {} as Record<string, boolean>);

  return {
    columns,
    initColumnSettings,
  };
};
