import { Button, Table } from 'antd';

import styles from './AnalyticsTopMediatorTable.module.less';
import { dataMock, columns } from './config';

export const AnalyticsTopMediatorTable = () => (
  <article className={styles.AnalyticsTopMediatorTable}>
    <div className={styles.AnalyticsTopMediatorTableHeader}>
      <h3>ТОП Посредников</h3>
      <Button onClick={() => { }}>Сформировать отчет</Button>
    </div>

    <Table
      rowKey={record => record.id}
      columns={columns}
      dataSource={dataMock}
    />

    <div className={styles.AnalyticsTopMediatorTableFooter}>
      <Button type="link" onClick={() => { }}>Посмотреть все</Button>
    </div>
  </article>
);