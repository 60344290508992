import React from 'react';
import { CloseOutlined } from '@ant-design/icons';

import styles from './Preview.module.less';
import { useReceptionPoint } from '../../hooks/useRecipientPoint';

export const Preview: React.FC = () => {
  const { preview, setPreview } = useReceptionPoint();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <CloseOutlined onClick={() => setPreview?.(undefined)} />
      </div>
      <div className={styles.container}>
        <img src={preview?.product_offer_image} alt='' />
      </div>
    </div>
  );
};
