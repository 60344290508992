import { useEffect } from 'react';
import { useStore } from 'effector-react';
import { MoreOutlined } from '@ant-design/icons';
import { Card, Dropdown, Table, Empty, TreeSelect } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { MainModal } from 'src/shared/ui';
import { UpdateSource } from 'src/entities/updateSource';
import { treeSelectFieldNames } from 'src/shared/config';

import {
  $requestData,
  $sources,
  changeRequestData,
  $categories,
  initSourcesPage,
  getSourcesFx,
  getCategoriesFx,
  $activeSource,
  setActiveSource,
} from './model';
import { columns, initState, PER_PAGE } from './config';
import styles from './Sources.module.less';

export const Sources = () => {
  const requestData = useStore($requestData);
  const isLoading = useStore(getSourcesFx.pending);
  const isLoadingCategories = useStore(getCategoriesFx.pending);
  const sources = useStore($sources);
  const categories = useStore($categories);
  const activeSource = useStore($activeSource);
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get('category');

  useEffect(() => {
    initSourcesPage();
  }, []);

  useEffect(() => {
    if (categoryId) {
      changeRequestData({ ...initState, category_id: +categoryId });
    } else {
      changeRequestData(initState);
    }
  }, [categoryId]);

  const handleChangePage = (value: number, size: number) => {
    changeRequestData({ ...requestData, pagination: { page: value, per_page: size } });
  };

  const closeHandler = () => {
    setActiveSource(null);
  };

  const handleChangeSearch = (value: number) => {
    setSearchParams({ category: value.toString() });
  };

  const clearSearch = () => {
    setSearchParams({});
  };

  return (
    <div className={styles.synonyms}>
      <Card className={styles.card}>
        <h2 className={styles.title}>Источники</h2>
        <div className={styles.wrapper}>
          <TreeSelect
            value={requestData.category_id}
            loading={isLoadingCategories}
            disabled={isLoadingCategories}
            style={{
              width: '100%',
            }}
            fieldNames={treeSelectFieldNames}
            dropdownStyle={{
              maxHeight: 400,
              overflow: 'auto',
            }}
            treeData={categories?.items}
            placeholder="Поиск по категории"
            allowClear
            onChange={handleChangeSearch}
            onClear={clearSearch}
          />
        </div>
        <Table
          rowKey={record => record.id}
          bordered
          loading={isLoading}
          columns={[
            ...columns,
            {
              title: '',
              key: 'settings',
              render: (_, data) => (
                <Dropdown
                  overlayClassName={styles.menu}
                  placement="bottom"
                  menu={{ items: [{ key: 'edit', label: 'Редактировать', onClick: () => setActiveSource(data) }] }}>
                  <MoreOutlined />
                </Dropdown>
              ),
              width: '3%',
            },
          ]}
          dataSource={sources?.items}
          pagination={{
            onChange: handleChangePage,
            current: sources?.pagination?.current_page || 1,
            pageSize: sources?.pagination?.per_page || PER_PAGE,
            total: sources?.pagination?.total || 0,
            showSizeChanger: true,
          }}
          locale={{ emptyText: <Empty description="Нет данных" /> }}
        />
      </Card>

      <MainModal isOpen={!!activeSource} closeHandler={() => setActiveSource(null)}>
        <UpdateSource closeHandler={closeHandler} activeSource={activeSource} />
      </MainModal>
    </div>
  );
};
