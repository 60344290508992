import { createStore, createEffect, createEvent, sample } from 'effector';
import { usersApi } from 'src/shared/api';
import { UpdatedUserRequestData } from 'src/shared/api/queries/usersApi';
import { setError, setSuccess } from 'src/features/notifications';

import { User } from '../types';

export const $user = createStore<User | null>(null);

export const getUser = createEvent<string>();
export const updateUser = createEvent<UpdatedUserRequestData>();

export const getUserFx = createEffect(usersApi.getUser);
export const updateUserFx = createEffect(usersApi.updateUser);

$user.on(getUserFx.doneData, (_, data) => data);

sample({
  clock: getUser,
  target: getUserFx,
});

sample({
  clock: updateUser,
  target: updateUserFx,
});

sample({
  clock: updateUserFx.doneData,
  fn: ({ id }) => id,
  target: getUserFx,
});

sample({
  clock: updateUserFx.failData,
  fn: ({ message }) => message,
  target: setError,
});

sample({
  clock: updateUserFx.doneData,
  fn: () => 'Сотрудник успешно отредактирован',
  target: setSuccess,
});
