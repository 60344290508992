import { Image } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import styles from './ProductImages.module.less';

interface ProductImagesProps {
  images: string[];
}

export const ProductImages = ({ images }: ProductImagesProps) => (
  <div className={styles.productImages}>
    <Image.PreviewGroup>
      {images.map(image => (
        <Image
          preview={{
            mask: (
              <>
                <EyeOutlined /> <span>Предпросмотр</span>
              </>
            ),
          }}
          key={image}
          src={image}
          placeholder
        />
      ))}
    </Image.PreviewGroup>
  </div>
);
