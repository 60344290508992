import { PostStatusType } from 'src/pages/products/posts/types';

import api from '../api';

export interface PostsReqData {
  status?: PostStatusType;
  statuses?: string[];
  description?: string;
  error?: string;
  source_id?: number;
  supplier_id?: number | string;
  url?: string;
  post_date?: {
    from: string;
    to: string;
  };
  pagination: {
    per_page: number;
    page: number;
  };
}

export const getPosts = (data: PostsReqData) => api.get('/posts', { params: data }).then(response => response.data);

export const getProductParsingPost = (id: string | number) =>
  api.get(`/product/${id}/post/parse`).then(response => response.data);

export const getActualPostInfo = (id: string | number) => api.get(`/post/${id}/parse`).then(response => response.data);

export const parsePost = (id: string | number) => api.post(`/post/${id}/parse`).then(response => response.data);
