import axios from 'axios';
import qs from 'qs';

import { BASE_URL } from '../config';
import { $refreshToken, $token, refreshTokenFx, setRefreshToken } from './model';
import { RefreshToken } from './types';

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  config => {
    const token = $token.getState();
    config.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    if (token && config?.url !== '/authentication/refresh') {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.paramsSerializer = params =>
      qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false,
      });
    return config;
  },
  error => Promise.reject(error),
);

let refreshTokenRequest = <Promise<RefreshToken> | null>null;

const getRefreshToken = async (refreshToken: string) => {
  if (!refreshTokenRequest) {
    refreshTokenRequest = new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await refreshTokenFx(refreshToken);
          refreshTokenRequest = null;
          resolve(res);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  return refreshTokenRequest;
};

instance.interceptors.response.use(
  res => res,
  async err => {
    const originalConfig = err.config;
    if (originalConfig?.url !== '/authentication/refresh') {
      // Access Token was expired
      if (err.response?.status === 401 && !originalConfig?.retry) {
        originalConfig.retry = true;
        try {
          const token = $refreshToken.getState();
          const result = await getRefreshToken(token);
          setRefreshToken(result);
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  },
);

export default instance;
