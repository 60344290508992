import { DetailedOrder } from 'src/pages/orders/order/types';
import { CourierDates } from 'src/pages/orders/ordersList/types';
import { OrderMeasurements } from 'src/shared/types';
import { TSimpleStatusObject } from 'src/utils';

import api from '../api';
import { TAddress } from './addressApi';

export interface OrdersRequestData {
  code?: number | string;
  price?: number | string;
  client_id?: number | string;
  created_at?: string;
  status?: number | string;
  statuses?: string[] | number[];
  pagination: {
    per_page: number;
    page: number;
  };
}

export interface LabelRequestData {
  id: number;
  type: string;
}

export interface LabelsRequestData {
  ids: number[];
}

export interface ReturnOrderReqData {
  id: number | string;
  description: string;
}

export interface RefundOrderReqData {
  admin_id: number;
  items: {
    name: string;
    quantity: number;
    price: number;
    order_id: number;
    client_id: number;
    type: string;
    task_id: number;
  }[];
}

export interface RefundOrderReqDataWithId extends RefundOrderReqData {
  id: string;
}

export interface TUpdateOrderBody {
  id: number | string;
  data: {
    dimensions?: OrderMeasurements;
    adminDeliveryEditRequest?: {
      recipient: Partial<TRecipientEntity> & {
        tariffId: string;
      };
      recipient_address: TAddress;
      delivery: TDeliveryEntity;
      parcels: TParcelEntity[];
    };
  };
}

export type TUpdateOrderResponse = {
  code: string;
  status: number;
  status_name: string;
  created_at: string;
  client_id: number;
  dispatch_number: string;
  delivery_number: string;
  delivery: TDeliveryEntity;
  address: TAddressEntity;
  recipient: TRecipientEntity;
  items: TOrderItemEntity[];
  prices: TOrderPrices;
  history: TOrdersHistory[];
  dimensions: OrderMeasurements;
  parcels: TParcelEntity[];
  payment_type: string;
  referral_id: number;
  referral_margin: number;
  referral_price: number;
  referral_prices: TOrderPrices;
  check: TCheckEntity[];
};

export type TCheckEntity = {
  name: string;
  quantity: number;
  refunded: number;
  price: number;
  order_id: number;
  client_id: number;
  type: ECheckTypes;
  task_id: number;
};

export enum ECheckTypes {
  delivery = 'delivery',
  commission = 'commission',
  product = 'product',
}

export type TParcelEntity = {
  dimensions: OrderMeasurements;
  dispatch_number: string;
  delivery_number?: string;
};

export enum ETaskStatus {
  notPaid = 1,
  readyForWork = 2,
  inWork = 3,
  redeemed = 4,
  inspection = 5,
  inStock = 6,
  canceled = 0,
}

export enum EOrderStatus {
  notPaid = 1,
  paid = 2,
  inWork = 3,
  redeemed = 4,
  purchased = 4,
  inStock = 5,
  assembly = 6,
  deliveryCreated = 7,
  delivery = 8,
  shipping = 8,
  delivered = 9,
  done = 10,
  debitSuccessfull = 11,
  debitFail = 12,
  courierWaiting = 13,
  rejection = 14,
  refund = 15,
  outOfStock = 16,
  refundError = 18,
  unfreezError = 19,
  canceled = 0,
}

export type TUserType = 'CLIENT' | 'USER' | 'INTERMEDIARY' | 'SUPPLIER' | 'SELLER';

export type TOrdersHistory = {
  status: EOrderStatus;
  status_name: string;
  user_type: TUserType;
  user_id: number;
  created_at: string;
  description: string;
};

export type TOrderPrices = {
  at_the_beginning: {
    products: number;
    delivery: number;
    margin: number;
    margin_percent: number;
    total: number;
  };
  now: {
    products: number;
    delivery: number;
    margin: number;
    margin_percent: number;
    total: number;
  };
};

export type TOrderItemEntity = {
  id: number;
  code: string;
  name: string;
  quantity: number;
  received_quantity: number;
  price: number;
  amount: number;
  status: number;
  status_name: string;
  image: string;
  product_offer_id: number;
  supplier_pavilion_id: number;
  intermediary_id: number;
  take_in_work_date: string;
  legal_entity_id: number;
};

type TDeliveryCombineBody = {
  id: string;
  data: {
    orderId: number;
    clientId: number;
  };
};

export type TAddressEntity = {
  id: number;
  locality: string;
  street_house_flat: string;
  full: string;
  index: string;
  client_id: number;
  fias: string;
  kladr: string;
  lat: number;
  lon: number;
  street: string;
  house: string;
  flat: string;
  updated_at: string;
  city: string;
  zip?: string;
};

export type TRecipientEntity = {
  id?: number;
  name: string;
  phone: string;
  email: string;
};

export type TDeliveryEntity = {
  id: string;
  tariff_id: string;
  code: string;
  provider: string;
  name: string;
  price_type: number;
  price: number;
  price_pure: number;
  delivery_period: string;
  address: string;
  provID: number;
  provName: string;
  calcUID: string;
};

type TDeliveryCombineResponse = {
  delivery: TDeliveryEntity;
  statusId: number;
  status_name: string;
  status_description: string;
};

type TSetOrderStatusRequest = {
  orders: TSimpleStatusObject[];
};

export type TUpdateOrderPaymentMethodBody = {
  order_id: number;
  payment_method_id: number;
};

export type TOrderStatusEntity = {
  status: number;
  status_name: string;
};

export const getOrdersList = (data: OrdersRequestData) => api.post('/orders', data).then(response => response.data);

export const getOrder = (id: string | number) => api.get(`/order/${id}`).then(response => response.data);

export const getOrderRefunds = (id: string | number) =>
  api.get(`/order/refunds`, { params: { order_id: id } }).then(response => response.data);

export const updateOrder = (data: TUpdateOrderBody) =>
  api.post(`/order/${data.id}`, data.data).then(response => response.data);

export const getOrderPromise = (id: string) => api.get(`/order/${id}`);

export const moveOrderToAssembly = (id: string): Promise<DetailedOrder> =>
  api.post(`/order/${id}/assembly`).then(response => response.data);

export const createDelivery = (id: number | string) =>
  api.post(`/order/${id}/create-delivery`).then(response => response.data);

export const sendToDelivery = (ids: number[]) =>
  api.post(`/orders/send-to-delivery`, { ids }).then(response => response.data);

export const getOrdersLabel = (data: LabelRequestData) =>
  api.get(`/order/${data.id}/${data.type}`).then(response => response.data);

export const getOrdersLabels = (data: LabelsRequestData) =>
  api.post('/orders/label', data).then(response => response.data);

export const retryDebit = (id: number) => api.post(`/order/${id}/debit`).then(response => response.data);

export const callCourier = (data: CourierDates | null) =>
  api.post(`/orders/call-courier`, data).then(response => response.data);

export const takeDelivery = (ids: number[]) =>
  api.post(`/orders/take-delivery`, { ids }).then(response => response.data);

export const cancelOrder = (id: string) => api.post(`/order/${id}/cancel`).then(response => response.data);

export const forwardOrder = (id: string) => api.post(`/order/${id}/forward`).then(response => response.data);

export const payPurchaseOrder = (id: string) =>
  api.post(`/../order/order/${id}/pay-purchase`).then(response => response.data);

export const returnOrder = ({ id, description }: ReturnOrderReqData) =>
  api.post(`/order/${id}/return`, { description }).then(response => response.data);

export const refundOrder = (data: RefundOrderReqData) => api.post(`/order/refund/create`, data);

export const getOrderTransactions = (id: string) =>
  api.get(`/order/${id}/transactions`).then(response => response.data);

export const getOrderStatuses = () => api.get('/order/statuses').then(response => response.data);

export const getAssemblyLabel = (id: string) => api.get(`/order/${id}/assembly/label`).then(response => response.data);

export const deliveryCombine = ({ id, data }: TDeliveryCombineBody): Promise<TDeliveryCombineResponse> =>
  api.post(`/order/${id}/delivery/combine`, data).then(response => response.data);

export const setOrderStatus = (data: TSetOrderStatusRequest): Promise<void> =>
  api.post(`/orders/set-status`, data).then(response => response.data);

export const setOrderPaymentMethod = (data: TUpdateOrderPaymentMethodBody): Promise<void> =>
  api.post('/order-set-payment-method', data).then(response => response.data);
