import {Card, Form, Switch} from 'antd';
import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {Title} from 'src/shared/ui';

import {$setting, $tariffs, getSettingsFx, initDeliverySetting, changeSettingValue, getTariffsFx} from './model';
import styles from './Delivery.module.less';

export const Delivery = () => {
  const settingValue = useStore($setting);
  const isLoading = useStore(getSettingsFx.pending);
  const isLoadingTariffs = useStore(getTariffsFx.pending);

  useEffect(() => {
    initDeliverySetting();
  }, []);

  const handleSubmitData = (key: string, value: boolean) => {
    const idsList = settingValue?.split(',') ?? [];
    if (value) {
      idsList?.splice(idsList.indexOf(key), 1);
    } else {
      idsList?.push(key);
    }
    changeSettingValue(idsList.length ? idsList.join(',') : null);
  };

  return (
    <Card className={styles.wrapper}>
      <Title>Доставка</Title>
      <Form>
        {$tariffs.getState().map((tariff) => (
          <div className="ant-row ant-form-item-row">
            <Form.Item
              key={tariff.tariffId}
            >
              <Switch
                checked={!settingValue?.split(',').includes(tariff.tariffId)}
                loading={isLoading || isLoadingTariffs}
                onChange={fn => handleSubmitData(tariff.tariffId, fn)}
              />
            </Form.Item>
            <div className="ant-col ant-form-item-label">
              <label htmlFor="split">
                {tariff.displayName}
              </label>
            </div>
          </div>))}
      </Form>

    </Card>
  );
};
