import { notification } from 'antd';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { sellerBillingApi } from 'src/shared/api';
import { SelectOptions } from 'src/shared/types';
import { TSimpleNameObject } from 'src/utils';

export const useSellerOperationTypes = () => {
  const [types, setTypes] = useState<TSimpleNameObject[]>([]);
  const [typeOptions, setTypeOptions] = useState<SelectOptions>([]);

  const fetch = async () => {
    try {
      const response = await sellerBillingApi.getSellerOperationTypes();
      if (response) {
        setTypes(response.types);
        setTypeOptions(
          response.types.map((item: TSimpleNameObject) => ({
            value: item.id,
            label: item.name,
          })),
        );
      }
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({ message: error.response?.data.message || 'Ошибка загрузки типов транзакций' });
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    types,
    typeOptions,
  };
};
