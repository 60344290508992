import { ColumnsType } from 'antd/lib/table';
import { TSellerBalanceEntity } from 'src/shared/api/queries/sellerBillingApi';
import classNames from 'classnames';
import { getFormattedPhone } from 'src/shared/lib';
import { PropsWithChildren } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

import styles from './PaymentsWithdrawalPage.module.less';

const CopyText = ({ children, text, className }: PropsWithChildren<{ text: string; className?: string }>) => (
  // eslint-disable-next-line
  <span className={classNames([className, styles.copy])} onClick={() => navigator.clipboard.writeText(text)}>
    {children}
  </span>
);

export const getColumns = ({ onWithdrawal }: { readonly onWithdrawal: (value: TSellerBalanceEntity) => void }) => {
  const columns: ColumnsType<TSellerBalanceEntity> = [
    {
      title: 'Сумма',
      dataIndex: 'balance',
      key: 'balance',
      render: record => (
        <CopyText className={styles.nowrap} text={String(record / 100)}>
          {new Intl.NumberFormat().format(record / 100)} ₽
        </CopyText>
      ),
    },
    {
      title: 'Павильон',
      dataIndex: 'pavilionName',
      key: 'pavilionName',
    },
    {
      title: 'Телефон',
      dataIndex: 'tel',
      key: 'tel',
      render: record =>
        record ? (
          <CopyText className={styles.nowrap} text={record}>
            {getFormattedPhone(record)}
          </CopyText>
        ) : (
          '-'
        ),
    },
    {
      title: 'Поставщик',
      key: 'userName',
      render: record => (
        <Link to={`/profile/supplier/${record.userId}`} className={styles.link}>
          {record.userName}
        </Link>
      ),
    },
    {
      title: 'Действие',
      key: 'actions',
      render: record => (
        <Button onClick={() => onWithdrawal(record)} type='primary'>
          Списать
        </Button>
      ),
    },
  ];

  const initColumnSettings = columns.reduce((prev, curr) => {
    if (curr.key) {
      prev[curr.key] = true;
    }
    return prev;
  }, {} as Record<string, boolean>);

  return {
    columns,
    initColumnSettings,
  };
};
