import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Image, Input } from 'antd';
import { MainModal, Title } from 'src/shared/ui';
import { useReceptionPoint } from 'src/pages/receptionPoint/hooks/useRecipientPoint';

import styles from './CheckCount.module.less';

type TFormValues = {
  readonly count: number;
};

export const CheckCountModal: React.FC = () => {
  const { taskCheckCount, movedPickupPoint, setTaskCheckCount } = useReceptionPoint();
  const { task } = taskCheckCount || {};
  const [form] = Form.useForm<TFormValues>();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState<number>(task?.count || 1);

  const handleSubmit = async () => {
    if (!task?.id) {
      return;
    }

    setLoading(true);
    await movedPickupPoint?.({
      code: task.code,
      id: task.id,
      count,
    });
    setLoading(false);
  };

  const handleClose = () => {
    setTaskCheckCount?.(undefined);
  };

  useEffect(() => {
    if (task?.count) {
      form.setFieldsValue({
        count: task?.count,
      });
    }
  }, [taskCheckCount]);

  return (
    <MainModal isOpen closeHandler={handleClose}>
      <div className={styles.wrapper} role='presentation'>
        <Card>
          <Title>Проверьте количество</Title>
          <div className={styles.info}>
            <Image preview={false} src={task?.product_offer_image} />
            <span>{task?.count} шт заказано</span>
          </div>
          <Form form={form} layout='vertical' onFinish={handleSubmit} className={styles.form}>
            <Form.Item
              className={styles.input}
              name='count'
              label='Кол-во наклеек с QR к печати:'
              rules={[{ required: true, message: 'Введите кол-во' }]}
            >
              <Input
                disabled={loading}
                autoFocus
                defaultValue={count}
                onChange={e => {
                  if (Number(e.target.value)) {
                    setCount(Number(e.target.value));
                  }
                }}
              />
            </Form.Item>
            <div className={styles.buttons}>
              <Button loading={loading} htmlType='submit' type='primary'>
                Подтвердить
              </Button>
              <Button onClick={handleClose} className={styles.button} disabled={loading}>
                Отмена
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </MainModal>
  );
};
