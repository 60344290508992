import { AxiosError } from 'axios';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { characteristicsApi, categoriesApi } from 'src/shared/api';
import { setSuccess, setError } from 'src/features/notifications';
import { Sizes } from 'src/pages/settings/sizes/types';

import { initSizeState } from './config';

export const $categoryInitData = createStore<characteristicsApi.GetCharacteristicsReqData>(initSizeState);
export const $sizeValues = createStore<string[] | null>(null);
export const $isSizesUpdated = createStore(false);

export const initSizePage = createEvent<characteristicsApi.GetCharacteristicsReqData>();
export const submitEditCategoryChar = createEvent<categoriesApi.EditCategoryChar>();
export const resetIsSizesUpdated = createEvent();
export const initCategoryId = createEvent<number | string>('');

export const getCharacteristicsFx = createEffect(characteristicsApi.getCharacteristics);
export const editCategoryCharFx = createEffect<categoriesApi.EditCategoryChar, null, AxiosError<{ message: string }>>(
  categoriesApi.editCategoryChar,
);

$sizeValues.on(getCharacteristicsFx.doneData, (_, data: Sizes) => {
  const resultData = data.values.map(item => item?.value || '');
  return resultData;
});
$isSizesUpdated.on(editCategoryCharFx.doneData, () => true).reset(resetIsSizesUpdated);
$categoryInitData.on(initSizePage, (_, data) => data);

sample({
  clock: initSizePage,
  target: getCharacteristicsFx,
});

sample({
  clock: submitEditCategoryChar,
  target: editCategoryCharFx,
});

sample({
  clock: editCategoryCharFx.doneData,
  source: $categoryInitData,
  target: initSizePage,
});

sample({
  clock: editCategoryCharFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при изменении размера',
  target: setError,
});

sample({
  clock: editCategoryCharFx.doneData,
  fn: () => 'Размер успешно изменен',
  target: setSuccess,
});
