import { AxiosError } from 'axios';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { Storage } from 'src/shared/lib';
import { paymentsApi, clientsApi, legalEntitiesApi } from 'src/shared/api';
import { Paginated } from 'src/shared/types';
import { LegalEntity } from 'src/pages/profiles/legalEntities/types';
import { Client } from 'src/pages/profiles/clients/types';
import { nullLegalOption, disabledLegalOption } from 'src/pages/profiles/suppliers/config';
import { PER_PAGE_LEGAL } from 'src/shared/config';
import { setError } from 'src/features/notifications';

import { initColumnSettings, PER_PAGE, waitStatus, initReqData } from './config';
import { ClientPayment, LegalEntityPayment, LegalEntitiesPayments } from './types';

export const $clientsPayments = createStore<Paginated<ClientPayment> | null>(null);
export const $legalEntitiesPayments = createStore<Paginated<LegalEntityPayment> | null>(null);
export const $isReportPending = createStore(false);
export const $reportReqData = createStore<paymentsApi.GetReportReqData>(initReqData);
export const $legalEntitiesReport = createStore<any>(null);
export const $clients = createStore<Paginated<Client> | null>(null);
export const $legalEntities = createStore<LegalEntity[]>([nullLegalOption]);
export const $isExcel = createStore<boolean>(false);

export const $clientsVisibleColumns = createStore(initColumnSettings);
export const $legalsVisibleColumns = createStore(initColumnSettings);
Storage.persist.entity($clientsVisibleColumns, { slice: 'clientsPayments', key: 'tableSettings' });
Storage.persist.entity($legalsVisibleColumns, { slice: 'legalsPayments', key: 'tableSettings' });
export const $clientsPageSize = createStore<number>(PER_PAGE);
export const $legalsPageSize = createStore<number>(PER_PAGE);
Storage.persist.entity($clientsPageSize, { slice: 'clientsPayments', key: 'pageSize' });
Storage.persist.entity($legalsPageSize, { slice: 'legalsPayments', key: 'pageSize' });

export const changeClientsVisibleColumns = createEvent<Record<string, boolean>>();
export const changeLegalsVisibleColumns = createEvent<Record<string, boolean>>();
export const changeClientsPageSize = createEvent<number>();
export const changeLegalsPageSize = createEvent<number>();
export const submitClientsPaymentsReqData = createEvent<paymentsApi.GetClientsPaymentsReqData>();
export const submitLegalPaymentsReqData = createEvent<paymentsApi.GetLegalEntitiesPaymentsReqData>();
export const submitClientsReqData = createEvent<clientsApi.ClientsRequestData>();
export const submitLegalReqData = createEvent<legalEntitiesApi.LegalEntitiesReqData>();
export const submitReportReqData = createEvent<paymentsApi.GetReportReqData>();
export const submitGetExcel = createEvent();
export const submitExcelData = createEvent();

export const getClientsPaymentsFx = createEffect(paymentsApi.getClientsPayments);
export const getLegalEntitiesPaymentsFx = createEffect(paymentsApi.getLegalEntitiesPayments);
export const getReportFx = createEffect<paymentsApi.GetReportReqData, { url: string }, AxiosError<{ message: string }>>(
  paymentsApi.getPaymentsReport,
);
export const getClientsFx = createEffect(clientsApi.getClientsList);
export const getLegalEntitiesFx = createEffect(legalEntitiesApi.getLegalEntities);
export const retryGetReportData = createEffect((data: paymentsApi.GetReportReqData) => {
  setTimeout(() => getReportFx(data), 5000);
});
const getExcelFx = createEffect((excel: { url: string }) => {
  if (window && excel?.url) {
    window.location.replace(excel.url);
  }
});

$clientsPayments.on(getClientsPaymentsFx.doneData, (_, data) => data);
$legalEntitiesPayments.on(getLegalEntitiesPaymentsFx.doneData, (_, data: LegalEntitiesPayments) => {
  const resultItems = (data?.items || []).map(item => ({
    ...item,
    client: data?.clients[item?.client_id],
  }));
  return { ...data, items: resultItems };
});
$isExcel.on(submitGetExcel, () => true);
$clients.on(getClientsFx.doneData, (_, data) => data);
$legalEntities.on(getLegalEntitiesFx.doneData, (_, data: Paginated<LegalEntity>) => {
  const resultLegalOptions =
    data?.items && data.items.length > PER_PAGE_LEGAL - 1
      ? [nullLegalOption, ...data.items.slice(0, PER_PAGE_LEGAL - 1), disabledLegalOption]
      : (data?.items && [nullLegalOption, ...data.items]) || [nullLegalOption];

  return resultLegalOptions;
});

$reportReqData.on(submitReportReqData, (_, data) => data);

sample({
  clock: changeClientsVisibleColumns,
  target: $clientsVisibleColumns,
});

sample({
  clock: changeLegalsVisibleColumns,
  target: $legalsVisibleColumns,
});

sample({
  clock: changeClientsPageSize,
  target: $clientsPageSize,
});

sample({
  clock: changeLegalsPageSize,
  target: $legalsPageSize,
});

sample({
  clock: submitClientsPaymentsReqData,
  target: getClientsPaymentsFx,
});

sample({
  clock: submitLegalPaymentsReqData,
  target: getLegalEntitiesPaymentsFx,
});

sample({
  clock: submitClientsReqData,
  target: getClientsFx,
});

sample({
  clock: submitLegalReqData,
  target: getLegalEntitiesFx,
});

sample({
  clock: submitReportReqData,
  target: getReportFx,
});

sample({
  clock: submitReportReqData,
  fn: () => true,
  target: $isReportPending,
});

sample({
  clock: [getReportFx.doneData, getReportFx.failData],
  fn: () => false,
  target: $isExcel,
});

sample({
  clock: getReportFx.failData,
  filter: data => data.response?.status !== waitStatus,
  fn: data => data.response?.data?.message || 'Ошибка при получении отчета',
  target: setError,
});

sample({
  clock: getReportFx.failData,
  filter: data => data.response?.status !== waitStatus,
  fn: () => false,
  target: $isReportPending,
});

sample({
  clock: getReportFx.doneData,
  fn: () => false,
  target: $isReportPending,
});

sample({
  clock: getReportFx.doneData,
  target: getExcelFx,
});

sample({
  clock: getReportFx.failData,
  filter: data => data.response?.status === waitStatus,
  target: submitExcelData,
});

sample({
  clock: submitExcelData,
  source: $reportReqData,
  target: retryGetReportData,
});
