import { Layout } from 'antd';
import { BrowserRouter } from 'react-router-dom';

import { Header } from '../widgets/header';
import { Menu } from '../widgets/menu';
import { Footer } from '../widgets/footer';
import { NotificationItem } from '../features/notifications';
import { Content } from './content';

export const App = () => (
  <BrowserRouter>
    <Layout>
      <NotificationItem />
      <Header />
      <Menu />
      <Content />
      <Footer />
    </Layout>
  </BrowserRouter>
);
