import { Card } from 'antd';
import { MainButton } from 'src/shared/ui/mainButton';
import React, { useState } from 'react';
import { TClientChangeBalanceResponse } from 'src/shared/api/queries/billing';

import styles from './styles.module.less';
import { useClient } from '../../model';
import { TModalType } from './types';
import { BalanceModal } from './components';
import { Money } from '../../../../../utils';


export const ClientBalanceCard: React.FC = () => {
  const client = useClient();
  const [modalType, setModalType] = useState<TModalType>();
  const [balance, setBalance] = useState<string>(String(client?.balance?.balance));

  const onFinish = (value: TClientChangeBalanceResponse) => {
    setBalance(String(value.balance));
  };

  return (
    <>
      <Card className={styles.card}>
        <div className={styles.wrapper}>
          <div className={styles.data}>
            <p className={styles.title}>Баланс</p>
            <div className={styles.block}>
              <div className={styles.row}>
                <span>Всего:</span>{' '}
                <span>
                  {Money.addCommas({
                    summa: balance,
                    format: true,
                    isKopek: true,
                  })}
                </span>
              </div>
            </div>
          </div>

          <div className={styles.buttons}>
            <MainButton onClick={() => setModalType('up')} type="primary" className={styles.button}>
              Пополнить
            </MainButton>
            <MainButton onClick={() => setModalType('withdrawal')} type="default" className={styles.button}>
              Списать
            </MainButton>
          </div>
        </div>
      </Card>
      {modalType && <BalanceModal onFinish={onFinish} type={modalType} onClose={() => setModalType(undefined)} />}
    </>
  );
};
