import React from 'react';

import { Content } from './components';
import { PaymentsWithdrawalProvider } from './hooks/usePaymentsWithdrawal';

export const PaymentsWithdrawalPage: React.FC = () => (
  <PaymentsWithdrawalProvider>
    <Content />
  </PaymentsWithdrawalProvider>
);
