export interface ClientProfile {
  id: string;
  name: string;
  email: string;
  phone: string;
  avatar_url: string;
  status: JSX.Element | '';
  originalStatus: ClientStatus;
  balance: {
    balance: number;
    assetBalances: Record<number, number>
  }
}

export enum ClientStatus {
  ACTIVE = 'ACTIVE',
  BANNED = 'BANNED',
  DELETED = 'DELETED',
  FULL_DELETED = 'FULL_DELETED',
  NOT_REGISTERED = 'NOT_REGISTERED',
}