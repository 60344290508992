import { Button, Card, Form, Input, InputRef } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Title } from 'src/shared/ui';
import { QrcodeOutlined } from '@ant-design/icons';
import { useReceptionPoint } from 'src/pages/receptionPoint/hooks/useRecipientPoint';

import styles from './ScanCode.module.less';

type TFormValues = {
  readonly code: string;
};

export const ScanCode: React.FC = () => {
  const [form] = Form.useForm<TFormValues>();
  const [loading, setLoading] = useState(false);
  const { getTaskByCode } = useReceptionPoint();
  const inputRef = useRef<InputRef>(null);

  const handleSubmit = async (values: TFormValues) => {
    setLoading(true);
    await getTaskByCode?.({
      code: values.code,
    });
    setLoading(false);
  };

  const handleClickScanner = () => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    handleClickScanner()
  }, [])

  return (
    <div className={styles.wrapper} role='presentation'>
      <Card>
        <div className={styles.info}>
          <Title>Добавить вручную</Title>
          <div className={styles.scan}>
            <QrcodeOutlined className={styles.icon} />
            <p className={styles.title}>Отсканируйте QR-код на заказе</p>
          </div>
          <p className={styles.subtitle}>или</p>
        </div>
        <Form form={form} layout='vertical' onFinish={handleSubmit} className={styles.form}>
          <Form.Item
            className={styles.input}
            name='code'
            label='Введите код:'
            rules={[{ required: true, message: 'Введите код' }]}
          >
            <Input ref={inputRef} disabled={loading} autoFocus onBlur={handleClickScanner}/>
          </Form.Item>
          <Button loading={loading} htmlType='submit' type='primary'>
            Отправить
          </Button>
        </Form>
      </Card>
    </div>
  );
};
