import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Table } from 'antd';
import { useStore } from 'effector-react';
import { useEffect } from 'react';

import { columns, MenuTableEnum } from '../config';
import { $childrenDocuments, expandRow, getSubRowsFx } from '../model';
import { Document } from '../types';
import styles from './ExtendedRows.module.less';

interface PropsType {
  record: Document;
  setDocumentForEdit: (id: number) => void;
}

export const ExtendedRows = ({ record, setDocumentForEdit }: PropsType) => {
  const childrenDocs = useStore($childrenDocuments);
  const isLoading = useStore(getSubRowsFx.pending);
  const expandedRowRender = (record: Document) => (
    <ExtendedRows key={record.id} record={record} setDocumentForEdit={setDocumentForEdit} />
  );

  useEffect(() => {
    if (record) {
      expandRow(record.id);
    }
  }, [record]);

  const settingsColumn = [
    {
      title: '',
      key: 'settings',
      render: (data: Document) => (
        <Dropdown
          overlayClassName={styles.menu}
          menu={{
            items: [
              {
                key: MenuTableEnum.EDIT,
                label: 'Изменить',
                onClick: () => setDocumentForEdit(data.id),
              },
            ],
          }}>
          <MoreOutlined />
        </Dropdown>
      ),
      width: '3%',
    },
  ];

  return (
    <Table
      key={`child-${record.id}`}
      rowKey={rec => rec.id}
      columns={[...columns, ...settingsColumn]}
      dataSource={childrenDocs[record.id]}
      expandable={{
        expandedRowRender,
        rowExpandable: (data: Document) => data.has_children,
      }}
      bordered
      pagination={false}
      showHeader={false}
      loading={isLoading && !childrenDocs[record.id]?.length}
    />
  );
};
