import React, { useState } from 'react';
import { MainModal, Title } from 'src/shared/ui';
import { Button, Form, Input, Select } from 'antd';
import { TSellerBalanceEntity } from 'src/shared/api/queries/sellerBillingApi';
import { useSellerOperationTypes } from 'src/shared/lib/hooks/useSellerOperationTypes';

import styles from './WithdrawalModal.module.less';
import { usePaymentsWithdrawal } from '../../hooks/usePaymentsWithdrawal';
import { TWithdrawalFormValues } from '../../PaymentsWithdrawalPage.types';

interface IProps {
  readonly onClose: () => void;
  readonly item: TSellerBalanceEntity;
}

const required = {
  required: true,
  message: 'Обязательное поле',
};

const disabledTypes = [5, 6];

export const WithdrawalModal: React.FC<IProps> = ({ onClose, item }) => {
  const { onWithdrawal } = usePaymentsWithdrawal();
  const [loading, setLoading] = useState(false);
  const { typeOptions } = useSellerOperationTypes();

  const handleSubmit = async (values: TWithdrawalFormValues) => {
    setLoading(true);
    await onWithdrawal?.({
      data: {
        ...values,
        amount: Number(values.amount) * 100,
        userId: item.userId,
      },
      callback: onClose,
    });
    setLoading(false);
  };

  return (
    <MainModal isOpen closeHandler={onClose}>
      <div className={styles.container}>
        <Title>Списать с баланса</Title>
        <Form name='basic' layout='vertical' onFinish={handleSubmit} autoComplete='off'>
          <Form.Item rules={[required]} label='Сумма списания, в ₽' name='amount'>
            <Input />
          </Form.Item>

          <Form.Item rules={[required]} label='Тип транзакции' name='operation_type_id'>
            <Select>
              {typeOptions
                .filter(i => !disabledTypes.includes(Number(i.value)))
                .map(i => (
                  <Select.Option value={i.value} key={i.value}>
                    {i.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item rules={[required]} label='Описание' name='reason'>
            <Input />
          </Form.Item>

          <div className={styles.buttons}>
            <Form.Item>
              <Button htmlType='submit' className={styles.button} type='primary' disabled={loading} loading={loading}>
                Подтвердить
              </Button>
            </Form.Item>
            <Button onClick={onClose} className={styles.button} disabled={loading}>
              Отменить
            </Button>
          </div>
        </Form>
      </div>
    </MainModal>
  );
};
