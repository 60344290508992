import { useEffect } from 'react';
import { Title } from 'src/shared/ui';
import { Form, Input, Button } from 'antd';
import { AddAddressReqData } from 'src/shared/api/queries/addressApi';

import styles from './AddAddress.module.less';

interface AddAddressProps {
  title?: string;
  data?: AddAddressReqData | null;
  successHandler: (value: AddAddressReqData) => void;
  closeHandler: () => void;
}

export const AddAddress = ({ title, data, closeHandler, successHandler }: AddAddressProps) => {
  const [form] = Form.useForm();

  const formSubmitHandler = (values: AddAddressReqData): void => {
    successHandler(values);
    closeHandler();
  };

  useEffect(() => {
    form.setFieldsValue(data);
    if (!data) {
      form.resetFields();
    }
  }, [form, data]);

  const handleCancelClose = () => {
    form.resetFields();
    closeHandler();
  };

  return (
    <div className={styles.addAddress}>
      <Title>{title}</Title>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={data || {}}
        onFinish={formSubmitHandler}
        autoComplete="off">
        <Form.Item
          label="Название"
          name="name"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите название!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Населенный пункт"
          name="locality"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите населенный пункт!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Улица, дом, квартира"
          name="street_house_flat"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите улицу, дом, квартиру!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Полный адрес"
          name="full"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите полный адрес!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Индекс"
          name="index"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите индекс!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Фиас"
          name="fias"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите фиас!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Кладр"
          name="kladr"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите кладр!',
            },
          ]}>
          <Input />
        </Form.Item>

        <div className={styles.buttons}>
          <Form.Item>
            <Button htmlType="submit" className={styles.button} type="primary">
              Готово
            </Button>
          </Form.Item>

          <Button onClick={handleCancelClose} className={styles.button}>
            Отмена
          </Button>
        </div>
      </Form>
    </div>
  );
};
