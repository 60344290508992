import { Link } from 'react-router-dom';
import { paymentsApi } from 'src/shared/api';
import { ColumnsType } from 'antd/es/table';
import { Utils , getFinalPrice } from 'src/shared/lib';
import { DateType } from 'src/shared/ui/datePicker';
import { v4 as uuid } from 'uuid';

import { ClientPayment, LegalEntityPayment } from './types';

export const uniqueLegalColumns = [
  'legalEntity.full_name',
  'client_payment_id',
  'legal_entity_amount',
  'market_place_commission',
  'legalEntity.point_id',
];

export const uniqueClientsColumns = ['id'];

export const mainColumnName = 'terminal_name';

export const waitStatus = 425;

export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export const initReqData: paymentsApi.GetReportReqData = {
  type: 'clients',
  payload: { uuid: uuid(), filter: { ...initState }, columns: [] },
};

export const columns: ColumnsType<ClientPayment | LegalEntityPayment> = [
  {
    title: 'Название терминала',
    dataIndex: 'terminal_name',
    key: 'terminal_name',
    render: data => data || '-',
  },
  {
    title: 'Клиент',
    dataIndex: 'client',
    key: 'client.name',
    render: client =>
      client?.id ? (
        <Link to={`/profile/client/${client.id}`} state={{ prevPath: true }}>
          {client?.name || '-'}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Номер заказа',
    dataIndex: 'order_id',
    key: 'order_id',
    render: data =>
      data ? (
        <Link to={`/order/${data}`} state={{ prevPath: true }}>
          {data || '-'}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Дата создания транзакции',
    dataIndex: 'init_at',
    key: 'init_at',
    render: (data: DateType) => (data ? Utils.dateFormat(data) : '-'),
  },
  {
    title: 'Время операции создания транзакции',
    dataIndex: 'initTime',
    key: 'init_at:time',
    render: (_, data) => (data?.init_at ? Utils.getFormatDate(data.init_at, 'HH:mm:ss') : '-'),
  },
  {
    title: 'Дата резервирования операции',
    dataIndex: 'authorized_at',
    key: 'authorized_at',
    render: (data: DateType) => (data ? Utils.dateFormat(data) : '-'),
  },
  {
    title: 'Дата подтверждения операции',
    dataIndex: 'confirmed_at',
    key: 'confirmed_at',
    render: (data: DateType) => (data ? Utils.dateFormat(data) : '-'),
  },
  {
    title: 'Юр.лицо',
    dataIndex: 'legalEntity',
    key: 'legalEntity.full_name',
    render: legalEntity =>
      legalEntity?.id ? (
        <Link to={`/profile/legalEntity/${legalEntity.id}`} state={{ prevPath: true }}>
          {legalEntity?.name || '-'}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Код предприятия',
    dataIndex: 'shopId',
    key: 'legalEntity.point_id',
    render: (_, data) => data.legalEntity?.point_id || '-',
  },
  {
    title: 'Сумма операции (₽)',
    dataIndex: 'amount',
    key: 'amount',
    render: data => (data || data === 0 ? getFinalPrice(data) : '-'),
  },
  {
    title: 'Комиссия банка (₽)',
    dataIndex: 'bank_commission',
    key: 'bank_commission',
    render: data => (data || data === 0 ? getFinalPrice(data) : '-'),
  },
  {
    title: 'Комиссия МП (₽)',
    dataIndex: 'market_place_commission',
    key: 'market_place_commission',
    render: data => (data || data === 0 ? getFinalPrice(data) : '-'),
  },
  {
    title: 'К перечислению МП (₽)',
    dataIndex: 'market_place_amount',
    key: 'market_place_amount',
    render: data => (data || data === 0 ? getFinalPrice(data) : '-'),
  },
  {
    title: 'К перечислению организации (₽)',
    dataIndex: 'legal_entity_amount',
    key: 'legal_entity_amount',
    render: data => (data || data === 0 ? getFinalPrice(data) : '-'),
  },
  {
    title: 'Payment ID',
    dataIndex: 'id',
    key: 'id',
    render: data => data || '-',
  },
  {
    title: 'Payment ID',
    dataIndex: 'client_payment_id',
    key: 'client_payment_id',
    render: data => data || '-',
  },
];

export const clientsColumns = columns.filter(({ key }) => key && !uniqueLegalColumns.includes(key.toString()));

export const legalColumns = columns.filter(({ key }) => key && !uniqueClientsColumns.includes(key.toString()));

export const initColumnSettings = columns.reduce((prev, curr) => {
  if (curr.key) {
    prev[curr.key] = true;
  }
  return prev;
}, {} as Record<string, boolean>);
