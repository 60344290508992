import { useStore } from 'effector-react';
import { getSupplierStatuses } from 'src/shared/api/queries/suppliersApi'
import { useEffect } from 'react';

import { SupplierModels } from '.';


export const useSupplierStatuses = () => {
    const supplierStatuses = useStore(SupplierModels.$supplierStatuses);
    
    const getStatuses = async () => {
        const response = await getSupplierStatuses();
        SupplierModels.saveSupplierStatuses(response.items);
    }

    useEffect(() => {
        if (!supplierStatuses?.length) {
            getStatuses();
        }
    }, [])

    return {
        supplierStatuses
    }
}