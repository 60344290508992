import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { MoreOutlined } from '@ant-design/icons';
import { Card, Dropdown, Table, Spin, Button, Empty } from 'antd';
import { Title , ConfirmForm } from 'src/shared/ui';
import { AddBuilding } from 'src/entities/addBuildingForm';
import { MainModal } from 'src/shared/ui/mainModal';

import { EditPavilions } from './ui/editPavilions';
import {
  $buildingsTreeData,
  $buildings,
  initPavilionsPage,
  getBuildingsFx,
  submitEditBuildingReqData,
  submitEditFloorReqData,
  submitEditLineReqData,
  submitEditPavilionReqData,
  submitRemoveBuildingReqData,
  submitRemoveFloorReqData,
  submitRemoveLineReqData,
  submitRemovePavilionReqData,
} from './model';
import { ActiveData } from './types';
import styles from './Pavilions.module.less';

export const Pavilions = () => {
  const [modalState, setModalState] = useState<{ isAdd: boolean; isEdit: boolean; isRemove: boolean }>({
    isAdd: false,
    isEdit: false,
    isRemove: false,
  });
  const [activeData, setActiveData] = useState<ActiveData>({
    title: '',
    type: '',
    id: 0,
    parentId: 0,
    buildingId: 0,
  });

  const buildings = useStore($buildings);
  const buildingsTreeData = useStore($buildingsTreeData);
  const isLoading = useStore(getBuildingsFx.pending);

  useEffect(() => {
    initPavilionsPage();
  }, []);

  const handleClose = (type: string): void => setModalState({ ...modalState, [type]: false });

  const handleOpen = (type: string): void => setModalState({ ...modalState, [type]: true });

  const handleEdit = ({ title, type, id, parentId, buildingId }: ActiveData, modalType: string): void => {
    setActiveData({ title, type, id, parentId, buildingId });
    setModalState({ ...modalState, [modalType]: true });
  };

  const handleRemove = () => {
    switch (activeData.type) {
      case 'building':
        submitRemoveBuildingReqData(activeData.id);
        break;
      case 'floor':
        submitRemoveFloorReqData(activeData.id);
        break;
      case 'line':
        submitRemoveLineReqData(activeData.id);
        break;
      case 'pavilion':
        submitRemovePavilionReqData(activeData.id);
        break;
      default:
        break;
    }
    setModalState({ ...modalState, isRemove: false });
  };

  const handleEditPavilion = ({ name }: { name: string }) => {
    switch (activeData.type) {
      case 'building':
        submitEditBuildingReqData({ id: activeData.id, data: { name } });
        break;
      case 'floor':
        if (activeData?.parentId)
          submitEditFloorReqData({ id: activeData.id, data: { name, building_id: activeData.parentId } });
        break;
      case 'line': {
        const reqData = activeData?.parentId
          ? { floor_id: activeData.parentId }
          : { building_id: activeData.buildingId };

        submitEditLineReqData({
          id: activeData.id,
          data: { name, ...reqData },
        });
        break;
      }
      case 'pavilion':
        if (activeData?.parentId)
          submitEditPavilionReqData({ id: activeData.id, data: { name, line_id: activeData.parentId } });
        break;
      default:
        break;
    }
    setModalState({ ...modalState, isEdit: false });
  };

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.pavilions}>
      <Card className={styles.card}>
        <div className={styles.wrapper}>
          <Title>Павильоны</Title>
          <div className={styles.buttonAdd}>
            <Button type="primary" onClick={() => handleOpen('isAdd')}>
              Добавить
            </Button>
          </div>
        </div>
        <Table
          showHeader={false}
          dataSource={buildingsTreeData}
          className={styles.table}
          columns={[
            {
              title: 'Название',
              dataIndex: 'title',
              key: 'title',
            },
            {
              title: '',
              key: 'settings',
              render: (_, { id, parentId, title, type, buildingId }) => (
                <Dropdown
                  overlayClassName={styles.menu}
                  placement="bottom"
                  menu={{
                    items: [
                      {
                        key: 'edit',
                        label: 'Редактировать',
                        onClick: () => handleEdit({ title, type, id, parentId, buildingId }, 'isEdit'),
                      },
                      {
                        key: 'remove',
                        label: 'Удалить',
                        onClick: () => handleEdit({ title, type, id, parentId, buildingId }, 'isRemove'),
                      },
                    ],
                  }}>
                  <MoreOutlined />
                </Dropdown>
              ),
              width: '3%',
            },
          ]}
          pagination={{
            showSizeChanger: true,
          }}
          locale={{ emptyText: <Empty description="Нет данных" /> }}
        />
      </Card>

      <MainModal isOpen={modalState.isAdd} closeHandler={() => handleClose('isAdd')}>
        <AddBuilding buildings={buildings} closeHandler={() => handleClose('isAdd')} />
      </MainModal>

      <MainModal isOpen={modalState.isEdit} closeHandler={() => handleClose('isEdit')}>
        <EditPavilions
          editingItem={activeData.title}
          confirmHandler={handleEditPavilion}
          closeHandler={() => handleClose('isEdit')}
        />
      </MainModal>

      <ConfirmForm
        isOpen={modalState.isRemove}
        title={`Вы уверены, что хотите удалить ${activeData.title}?`}
        closeHandler={() => handleClose('isRemove')}
        confirmHandler={handleRemove}
      />
    </div>
  );
};
