import { ColumnsType } from 'antd/lib/table';
import { Avatar } from 'antd';
import { PictureOutlined } from '@ant-design/icons';

import { Category } from './types';
import styles from './Categories.module.less';

export enum MenuTableEnum {
  EDIT = 'edit',
  REMOVE = 'remove',
  NAMING = 'naming',
}

export const columns: ColumnsType<Category> = [
  {
    title: 'Название',
    key: 'name',
    render: (data: Category) => (
      <div className={styles.name}>
        <Avatar shape="square" size="large" src={data?.image} icon={<PictureOutlined />} />
        <span className={styles.text}>{data.name}</span>
      </div>
    ),
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '3%',
  },
];
