import { notification } from 'antd';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Supplier } from 'src/pages/profiles/suppliers/types';
import { suppliersApi } from 'src/shared/api';
import { SuppRequestData } from 'src/shared/api/queries/suppliersApi';
import { Paginated } from 'src/shared/types';
import { useDebounce } from 'src/utils';

export const useSearchSupplier = () => {
  const [suppliers, setSuppliers] = useState<Paginated<Supplier>>();
  const [params, setParams] = useState<SuppRequestData>();
  const [loading, setLoading] = useState(false);
  const debouncedValue = useDebounce(params);

  const fetch = async () => {
    if (!debouncedValue) {
      return;
    }

    setLoading(true);

    try {
      const response = await suppliersApi.getSuppList(debouncedValue);
      if (response) {
        setSuppliers(response);
      }
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({ message: error.response?.data.message || 'Ошибка загрузки поставщиков' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [debouncedValue]);

  return {
    suppliers,
    loading,
    setParams,
  };
};
