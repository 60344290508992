import { useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useStore } from 'effector-react';
import { Empty, Menu, Spin } from 'antd';
import { BackArrow } from 'src/entities/backArrow';
import { LatestTasks } from 'src/features/latestTasks';
import { useIsAdmin } from 'src/widgets/header';
import { ConfirmForm } from 'src/shared/ui';

import { SuppProductsList } from '../suppliersProfile/ui/suppProductsList';
import { LatestOrders } from '../profile/ui';
import { ClientsAddress } from './ui/clientsAddress';
import {
  $isPrevPath,
  getClientFx,
  initClientProfilePage,
  setIsPrevPath,
  submitChangeStatusForm,
  submitDeleting,
  submitForceDeleting,
  useClient,
} from './model';
import styles from './ClientsProfile.module.less';
import { ClientBalanceCard, ClientProfilesCard } from './ui';

interface ClientsProfileProps {
  id?: string;
}

export const ClientsProfile = ({ id }: ClientsProfileProps) => {
  const location = useLocation();
  const prevPath = useLocation().state as { prevPath: boolean };
  const client = useClient();
  const isLoading = useStore(getClientFx.pending);
  const isAdmin = useIsAdmin();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenFull, setIsOpenFull] = useState(false);
  const isPrevPath = useStore($isPrevPath);

  useEffect(() => {
    const prevPathData = prevPath?.prevPath || isPrevPath || false;
    setIsPrevPath(prevPathData);
    if (id) {
      initClientProfilePage(id);
    }
  }, [id]);

  const handleChangeStatus = (value: string): void => {
    if (id) {
      submitChangeStatusForm({ id, type: value });
    }
  };

  const submitDeletingClient = () => {
    if (id) {
      submitDeleting(id);
      setIsOpen(false);
    }
  };

  const submitForceDeletingClient = () => {
    if (id) {
      submitForceDeleting(id);
      setIsOpenFull(false);
    }
  };

  if (isLoading) {
    return <Spin size="large" />;
  }

  return (
    <div className={styles.wrapper}>
      {isPrevPath ? <BackArrow /> : null}
      {client ? (
        <>
          <div className={styles['cards-wrapper']}>
            <ClientProfilesCard
              data={client}
              handleChangeStatus={handleChangeStatus}
              allowEdit={isAdmin}
              deleteProfile={() => setIsOpen(true)}
              forceDeleteProfile={() => setIsOpenFull(true)}
            />
            <ClientBalanceCard />
          </div>

          <div>
            <div className={styles.content}>
              <Menu disabledOverflow mode="horizontal" className={styles.menu} selectedKeys={[location.pathname]}>
                <Menu.Item key={`/profile/client/${id}`}>
                  <Link to={`/profile/client/${id}`} replace>
                    Заказы
                  </Link>
                </Menu.Item>
                <Menu.Item key={`/profile/client/${id}/tasks`}>
                  <Link to={`/profile/client/${id}/tasks`} replace>
                    Задания
                  </Link>
                </Menu.Item>
                {/* Todo: add when done page */}
                {/* {isAdmin ? (
                  <Menu.Item key={`/profile/client/${id}/statistic`}>
                    <Link to={`/profile/client/${id}/statistic`} replace>Статистика покупок</Link>
                  </Menu.Item>
                ) : null} */}
                {isAdmin ? (
                  <Menu.Item key={`/profile/client/${id}/address`}>
                    <Link to={`/profile/client/${id}/address`} replace>
                      Адреса
                    </Link>
                  </Menu.Item>
                ) : null}
                {isAdmin ? (
                  <Menu.Item key={`/profile/client/${id}/favorites`}>
                    <Link to={`/profile/client/${id}/favorites`} replace>
                      Избранное
                    </Link>
                  </Menu.Item>
                ) : null}
              </Menu>
            </div>

            <Routes>
              <Route path="*" element={<LatestOrders id={id} />} />
              <Route path="/tasks" element={<LatestTasks id={id} type="client_id" />} />
              {isAdmin ? <Route path="/statistic" element={<Empty description="В разработке" />} /> : null}
              {isAdmin ? <Route path="/address" element={<ClientsAddress id={id} />} /> : null}
              {isAdmin ? (
                <Route path="/favorites" element={<SuppProductsList idType="favorite_for_client_id" id={id} />} />
              ) : null}
            </Routes>
            {isAdmin ? (
              <>
                <ConfirmForm
                  isOpen={isOpen}
                  title="Вы собираетесь удалить профиль клиента. Восстановление профиля в дальнейшем невозможно, пользователь не сможет более войти в свой профиль. Вы действительно хотите удалить профиль клиента?"
                  closeHandler={() => setIsOpen(false)}
                  confirmHandler={submitDeletingClient}
                />
                <ConfirmForm
                  isOpen={isOpenFull}
                  title="Вы уверены? Данная операция необратима"
                  closeHandler={() => setIsOpenFull(false)}
                  confirmHandler={submitForceDeletingClient}
                />
              </>
            ) : null}
          </div>
        </>
      ) : (
        <Empty description="Профиль не найден" />
      )}
    </div>
  );
};
