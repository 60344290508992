import { ColumnsType } from 'antd/lib/table';
import { Tag } from 'antd';

import { ParserHistory } from '../../types';

export const initTotal = { addition: 'total', weight: 0, reason: 'итого' };

export const columns: ColumnsType<ParserHistory> = [
  {
    title: '',
    dataIndex: 'weight',
    key: 'weight',
    render: (weight, data) => {
      const isAddition = data?.addition;
      if (isAddition === 'total') {
        return <Tag color="geekblue">{weight}</Tag>;
      }
      return (
        <Tag color={isAddition ? 'green' : 'red'}>
          {isAddition ? '+' : '-'}
          {weight}
        </Tag>
      );
    },
    width: '10%',
  },
  {
    title: '',
    dataIndex: 'reason',
    key: 'id',
    render: reason => reason || '-',
  },
];
