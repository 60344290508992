import type { ColumnsType } from 'antd/es/table';

import { Address } from './types';

export const initReqData = { client_id: '0' };

export const columns: ColumnsType<Address> = [
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Населенный пункт',
    dataIndex: 'locality',
    key: 'locality',
  },
  {
    title: 'Улица, дом, квартира',
    dataIndex: 'street_house_flat',
    key: 'street_house_flat',
  },
  {
    title: 'Полный адрес',
    dataIndex: 'full',
    key: 'full',
  },
  {
    title: 'Индекс',
    dataIndex: 'index',
    key: 'index',
  },
  {
    title: 'Фиас',
    dataIndex: 'fias',
    key: 'fias',
  },
  {
    title: 'Кладр',
    dataIndex: 'kladr',
    key: 'kladr',
  },
];
