import React from 'react';

import { ReceptionPointProvider } from './hooks/useRecipientPoint';
import { ReceptionPointTable } from './components';

export const ReceptionPoint: React.FC = () => (
    <ReceptionPointProvider>
      <ReceptionPointTable />
    </ReceptionPointProvider>
  );
