import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { tasksApi } from 'src/shared/api';
import { Hooks , getFinalFilterPrice } from 'src/shared/lib';
import { Table, Spin, Tooltip, Empty } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { TableSettings } from 'src/entities/tableSettings';
import type { TableRowSelection } from 'antd/es/table/interface';
import { NumberTypeEnum } from 'src/shared/types';

import { columns, mainColumnName, initState, PER_PAGE } from './config';
import {
  $tasks,
  $tasksStatusOptions,
  $requestData,
  submitChangeRequestData,
  getTasksFx,
  initPage,
  initLatestTasksPage,
  $visibleColumns,
  changeVisibleColumns,
} from './model';
import { Task } from './types';
import styles from './LatestTasks.module.less';

interface LatestTasksProps {
  id?: string;
  type: 'client_id' | 'intermediary_id' | 'supplier_id' | 'legal_entity_id';
}

export const LatestTasks = ({ id, type: profilesType }: LatestTasksProps) => {
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const visibleColumnsKeys = useStore($visibleColumns);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const tasks = useStore($tasks);
  const taskOptions = useStore($tasksStatusOptions);
  const requestData = useStore($requestData);
  const isLoading = useStore(getTasksFx.pending);

  useEffect(() => {
    if (id) {
      initLatestTasksPage({ [profilesType]: id, ...initState });
    }
    initPage();
  }, [id, profilesType]);

  useEffect(() => {
    const resultVisibleColumns: ColumnsType<Task> = [];

    columns.forEach(column => {
      if (
        column.key &&
        column.key !== profilesType &&
        (!Object.prototype.hasOwnProperty.call(visibleColumnsKeys, column.key) || visibleColumnsKeys[column.key])
      ) {
        resultVisibleColumns.push(column);
      }
    });

    setVisibleColumns(resultVisibleColumns);
  }, [visibleColumnsKeys, profilesType]);

  const handleSelectChange = (selectedRowKeys: React.Key[], selectedRows: Task[]) => {};

  const handleSelect = (record: Task, selected: boolean, selectedRows: Task[]) => {};

  const handleSelectAll = (selected: boolean, selectedRows: Task[], changeRows: Task[]) => {};

  const rowSelection: TableRowSelection<Task> = {
    onChange: handleSelectChange,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
  };

  const searchFieldHandler = (value: string, type?: string) => {
    if (value && type) {
      const resultValue = type === 'price' ? getFinalFilterPrice(value) : value;
      submitChangeRequestData({ ...requestData, [type]: resultValue, [profilesType]: id, ...initState });
    } else {
      const filteredReqData = Object.entries(requestData).reduce((acc, [key, value]) => {
        if (key !== type) {
          acc[key as keyof typeof requestData] = value;
        }
        return acc;
      }, {} as tasksApi.TasksRequestData);
      submitChangeRequestData({ ...filteredReqData, [profilesType]: id, ...initState });
    }
  };

  const handleSelectStatus = (value: string[]) => {
    submitChangeRequestData({ ...requestData, [profilesType]: id, statuses: value || [], ...initState });
  };

  const handleCreateDateSearch = (obj: { [key: string]: string }) => {
    submitChangeRequestData({ ...requestData, [profilesType]: id, ...obj, ...initState });
  };

  const searchCreatedDateHandler = (value: string) => {
    if (value) {
      handleCreateDateSearch?.({
        created_at: new Date(value).toISOString(),
      });
    } else {
      const { created_at: createdAt, ...filteredReqData } = requestData;
      submitChangeRequestData({ ...filteredReqData, [profilesType]: id, ...initState });
    }
  };

  const handleChangePage = (value: number, size: number) => {
    submitChangeRequestData({ ...requestData, [profilesType]: id, pagination: { page: value, per_page: size } });
  };

  const handleResetFilters = () => {
    submitChangeRequestData({ [profilesType]: id, ...initState });
    setIsActiveFilter(true);
  };

  const getCodeSearch = Hooks.useColumnSearch<Task>(searchFieldHandler, 'code', isActiveFilter, setIsActiveFilter);
  const getPriceSearch = Hooks.useColumnSearch<Task>(
    searchFieldHandler,
    NumberTypeEnum.PRICE,
    isActiveFilter,
    setIsActiveFilter,
  );
  const getCountSearch = Hooks.useColumnSearch<Task>(
    searchFieldHandler,
    NumberTypeEnum.COUNT,
    isActiveFilter,
    setIsActiveFilter,
  );
  const getDateSearch = Hooks.useColumnSearch<Task>(
    searchCreatedDateHandler,
    'date',
    isActiveFilter,
    setIsActiveFilter,
  );
  const getStatusSearch = Hooks.useColumnMultiSelect<Task>(
    handleSelectStatus,
    taskOptions,
    'Выбрать статус',
    isActiveFilter,
    setIsActiveFilter,
  );

  const filteredColumns: ColumnsType<Task> = visibleColumns.map(column => {
    switch (column.key) {
      case 'code':
        return { ...column, ...getCodeSearch(column.key, column.title) };
      case 'price':
        return { ...column, ...getPriceSearch(column.key, column.title) };
      case 'count':
        return { ...column, ...getCountSearch(column.key, column.title) };
      case 'created_at':
        return { ...column, ...getDateSearch(column.key, column.title) };
      case 'status':
        return { ...column, ...getStatusSearch(column.key, column.title) };
      default:
        return column;
    }
  });

  const filteredOptColumns = columns.filter(({ key }) => !(key === profilesType));

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <div className={styles.options}>
        <Tooltip overlayStyle={{ position: 'fixed' }} title="Очистить все фильтры">
          <ClearOutlined className={styles.clear} onClick={handleResetFilters} />
        </Tooltip>
        <TableSettings
          disabledColumns={[mainColumnName]}
          columns={filteredOptColumns}
          visibleColumnsKeys={visibleColumnsKeys}
          setVisibleColumnsKeys={changeVisibleColumns}
        />
      </div>
      <Table
        className={styles.tasks}
        rowKey={record => record.id}
        bordered
        columns={filteredColumns}
        rowSelection={{ ...rowSelection }}
        dataSource={tasks?.items || []}
        pagination={{
          onChange: handleChangePage,
          current: tasks?.pagination?.current_page || 1,
          pageSize: tasks?.pagination?.per_page || PER_PAGE,
          total: tasks?.pagination?.total || 0,
          showSizeChanger: true,
        }}
        scroll={{ x: 1200 }}
        locale={{ emptyText: <Empty description="Нет данных" /> }}
      />
    </div>
  );
};
