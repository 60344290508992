import { Card, Button } from 'antd';
import { getFinalPrice } from 'src/shared/lib';
import { IntermedBalance } from 'src/pages/profiles/intermediariesProfile/types';

import styles from './ProfilesBalance.module.less';

interface ProfilesBalanceProps {
  data?: IntermedBalance | null;
  allowAction?: boolean;
  payoutHandler: () => void;
}

export const ProfilesBalance = ({ data, allowAction, payoutHandler }: ProfilesBalanceProps) => (
  <div className={styles.profilesBalance}>
    <Card className={styles.card}>
      <div className={styles.wrapper}>
        <div className={styles.data}>
          <p className={styles.title}>Баланс</p>

          <p>
            <strong>Баланс: </strong> {data?.balance ? getFinalPrice(data.balance) : '0'}
          </p>
          <p>
            <strong>Выкупы: </strong> {data?.buyout ? getFinalPrice(data.buyout) : '0'}
          </p>

          <p>
            <strong>Доход: </strong> {data?.income ? getFinalPrice(data.income) : '0'}
          </p>
        </div>

        {allowAction ? (
          <div className={styles.buttons}>
            <Button className={styles.button} onClick={payoutHandler} disabled={!data?.balance}>
              Выплатить
            </Button>
          </div>
        ) : null}
      </div>
    </Card>
  </div>
);
