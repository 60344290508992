export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
    total: 0,
  },
  sorting: {
    column: 'updated_at',
    direction: 'desc',
  },
};

export const TASK_STATUS = 17;
