import { createEffect, createEvent, createStore, sample } from 'effector';
import { setError, setSuccess } from 'src/features/notifications';
import { settingApi } from 'src/shared/api';

import { Setting } from './types';

export const $setting = createStore<string | null>(null);

export const initParserTimePage = createEvent();
export const submitChanges = createEvent<string>();

export const getSettingsFx = createEffect(settingApi.getSettings);
export const updateSettingFx = createEffect(settingApi.updateSetting);

sample({
  clock: initParserTimePage,
  target: getSettingsFx,
});

sample({
  clock: getSettingsFx.doneData,
  fn: ({ settings }: { settings: Setting[] }) => {
    const frequencySetting = settings.find(item => item.key === settingApi.SettingKeys.frequency_of_checking_new_posts);
    return frequencySetting?.value || null;
  },
  target: $setting,
});

sample({
  clock: submitChanges,
  fn: (value: string) => ({ key: settingApi.SettingKeys.frequency_of_checking_new_posts, value }),
  target: updateSettingFx,
});

sample({
  clock: updateSettingFx.doneData,
  fn: () => 'Параметр успешно обновлен',
  target: setSuccess,
});

sample({
  clock: updateSettingFx.failData,
  fn: () => 'Ошибка обновления',
  target: setError,
});
