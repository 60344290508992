import { useEffect, useState } from 'react';
import { useLocation, Routes, Route, Link } from 'react-router-dom';
import { useStore } from 'effector-react';
import { Spin, Empty, Menu, Button, Card } from 'antd';
import { LatestTasks } from 'src/features/latestTasks';
import { BackArrow } from 'src/entities/backArrow';
import { ConfirmForm } from 'src/shared/ui';
import { Utils } from 'src/shared/lib';
import { EditOutlined } from '@ant-design/icons';

import {
  $legalEntity,
  $isPrevPath,
  $tinkoffError,
  $isArchiveError,
  initLegalEntityPage,
  submitTinkoffReqData,
  submitDeleteLegalEntity,
  submitArchLegalReqData,
  submitExtrLegalReqData,
  regLegalTinkoffFx,
  deleteLegalEntityFx,
  resetTinkoffError,
  resetArchiveError,
  setIsPrevPath,
  getLegalEntityFx,
} from './model';
import { LegalEntitiesProfilesCard } from './ui/legalEntitiesProfilesCard';
import { ProfilesSuppliers } from '../suppliers';
import { ArchiveErrorModal } from '../legalEntities/ui/archiveErrorModal';
import { initModalState } from './config';
import { LegalEntityCardType, LegalEntityModalType } from './types';
import styles from './LegalEntitiesProfile.module.less';
import { EditInternalModal } from './ui/EditInternalModal';

interface ProfileProps {
  id?: string;
}

export const LegalEntitiesProfile = ({ id }: ProfileProps) => {
  const location = useLocation();
  const prevPath = useLocation().state as { prevPath: boolean };
  const legalEntity = useStore($legalEntity);
  const tinkoffError = useStore($tinkoffError);
  const isLoading = useStore(getLegalEntityFx.pending);
  const isPrevPath = useStore($isPrevPath);
  const isTinkoffLoading = useStore(regLegalTinkoffFx.pending);
  const isDeleteLoading = useStore(deleteLegalEntityFx.pending);
  const isArchiveError = useStore($isArchiveError);
  const [showEditInternalModal, setShowEditInternalModal] = useState<boolean>(false)
  const [modalState, setModalState] = useState<{
    isRegistration: boolean;
    isDelete: boolean;
    isArchive: boolean;
    isExtract: boolean;
  }>(initModalState);

  const isElementAvailable = !isTinkoffLoading && !legalEntity?.legalEntity?.point_id;
  const isProfileArchived = !!legalEntity?.legalEntity?.archived_at;

  useEffect(() => {
    const prevPathData = prevPath?.prevPath || isPrevPath || false;
    setIsPrevPath(prevPathData);
    if (id) {
      initLegalEntityPage(id);
      resetTinkoffError();
    }
  }, [id]);

  const handleSubmitTinkoffData = () => {
    if (id) {
      submitTinkoffReqData(id);
      setModalState({ ...modalState, isRegistration: false });
      resetTinkoffError();
    }
  };

  const handleAddToArchive = () => {
    if (id) {
      submitArchLegalReqData(id);
      setModalState({ ...modalState, isArchive: false });
    }
  };

  const handleExtrFromArchive = () => {
    if (id) {
      submitExtrLegalReqData(id);
      setModalState({ ...modalState, isExtract: false });
    }
  };

  const handleOpenModal = (type: LegalEntityModalType) => setModalState({ ...modalState, [type]: true });

  const handleCloseModal = (type: LegalEntityModalType) => setModalState({ ...modalState, [type]: false });

  const handleSubmitDeleteLegalEntity = () => {
    if (id) {
      submitDeleteLegalEntity(id);
      setModalState({ ...modalState, isDelete: false });
    }
  };

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {isPrevPath ? <BackArrow /> : null}

      {legalEntity ? (
        <>
          {isProfileArchived ? (
            <div className={styles.headBlock}>
              <div className={styles.archiveTitle}>
                <p className={styles.blockTitle}>
                  Архивировано {Utils.getFormatDate(legalEntity?.legalEntity?.archived_at || '', 'DD MMM YYYY')}
                </p>
                <p className={styles.resposible}>
                  Архивировал:{' '}
                  {legalEntity?.legalEntity?.creator_id && legalEntity?.legalEntity?.creator_type === 'SUPPLIER' ? (
                    <Link
                      className={styles.link}
                      to={`/profile/supplier/${legalEntity?.legalEntity.creator_id}`}
                      state={{ prevPath: true }}>
                      {legalEntity?.creator_name || '-'}
                    </Link>
                  ) : (
                    <span>{legalEntity?.creator_name || '-'}</span>
                  )}
                </p>
              </div>
              <div className={styles.buttons}>
                <Button htmlType="button" onClick={() => handleOpenModal('isExtract')}>
                  Разархивировать
                </Button>
              </div>
            </div>
          ) : null}
          <div className={styles.headBlock}>
            {!legalEntity?.legalEntity?.point_id && !isTinkoffLoading && !tinkoffError ? (
              <p className={styles.blockTitle}>Готово к регистрации в Тинькофф</p>
            ) : null}
            {legalEntity?.legalEntity?.point_id && !isTinkoffLoading && !tinkoffError ? (
              <>
                <p className={`${styles.blockTitle} ${styles.blockTitleSuccess}`}>
                  Зарегистрировано {Utils.getFormatDate(legalEntity?.legalEntity?.registered_at || '', 'DD MMM YYYY')}
                </p>
                <p className={`${styles.blockTitle} ${styles.blockTitleSuccess} ${styles.pointId}`}>
                  ID: {legalEntity?.legalEntity?.point_id || '-'}
                </p>
              </>
            ) : null}
            {tinkoffError && !isTinkoffLoading ? (
              <p className={`${styles.blockTitle} ${styles.blockTitleError}`}>{tinkoffError}</p>
            ) : null}
            {isTinkoffLoading ? <p className={styles.blockTitle}>Ожидается ответ от банка</p> : null}
            <div className={styles.buttons}>
              {!isProfileArchived ? (
                <Button htmlType="button" onClick={() => handleOpenModal('isArchive')}>
                  Архивировать
                </Button>
              ) : null}
              {isElementAvailable ? (
                <Link to={`/profile/legal/edit/${id}`}>
                  <Button htmlType="button">Редактировать</Button>
                </Link>
              ) : null}
              {isElementAvailable ? (
                <Button onClick={() => handleOpenModal('isRegistration')} type="primary" htmlType="submit">
                  Зарегистрироваться в Тинькофф
                </Button>
              ) : null}
              {isElementAvailable ? (
                <Button
                  disabled={isDeleteLoading}
                  loading={isDeleteLoading}
                  danger
                  htmlType="button"
                  onClick={() => handleOpenModal('isDelete')}>
                  Удалить
                </Button>
              ) : null}
            </div>
          </div>
          <div className={styles['cards-wrapper']}>
            <LegalEntitiesProfilesCard data={legalEntity?.legalEntity} type={LegalEntityCardType.ORGANIZATION} />
            <LegalEntitiesProfilesCard data={legalEntity?.legalEntity} type={LegalEntityCardType.CEO} />
            <LegalEntitiesProfilesCard data={legalEntity?.legalEntity} type={LegalEntityCardType.BANK} />
          </div>
          <div className={styles['cards-wrapper']}>
            <LegalEntitiesProfilesCard data={legalEntity?.legalEntity} type={LegalEntityCardType.CONTACTS} />
            <LegalEntitiesProfilesCard data={legalEntity?.legalEntity} type={LegalEntityCardType.ADDRESS} />
          </div>
          <Card>
            <div className={styles.data}>
              <strong>Тип ЮЛ: </strong>
              {
                `${legalEntity?.legalEntity.is_internal ? 'Внутреннее' : 'Внешнее'} `
              }
              <EditOutlined onClick={() => setShowEditInternalModal(true)} />
            </div>
          </Card>
          <div className={styles.content}>
            <Menu disabledOverflow mode="horizontal" className={styles.menu} selectedKeys={[location.pathname]}>
              <Menu.Item key={`/profile/legalEntity/${id}`}>
                <Link to={`/profile/legalEntity/${id}`} replace>
                  Поставщики
                </Link>
              </Menu.Item>
              <Menu.Item key={`/profile/legalEntity/${id}/tasks`}>
                <Link to={`/profile/legalEntity/${id}/tasks`} replace>
                  Задания
                </Link>
              </Menu.Item>
            </Menu>
          </div>
          <Routes>
            <Route path="*" element={<ProfilesSuppliers id={id} legalProfile={legalEntity?.legalEntity} />} />
            <Route path="/tasks" element={<LatestTasks id={id} type="legal_entity_id" />} />
          </Routes>
        </>
      ) : (
        <Empty description="Профиль не найден" />
      )}

      <ConfirmForm
        isOpen={modalState.isDelete}
        title="Вы действительно хотите удалить профиль юридического лица?"
        closeHandler={() => handleCloseModal('isDelete')}
        confirmHandler={handleSubmitDeleteLegalEntity}
      />
      <ConfirmForm
        isOpen={modalState.isRegistration}
        title="Внимание! После регистрации изменить данные юридического лица будет невозможно. Продолжить?"
        closeHandler={() => handleCloseModal('isRegistration')}
        confirmHandler={handleSubmitTinkoffData}
      />
      <ConfirmForm
        isOpen={modalState.isArchive}
        title="Вы действительно хотите отправить юридическое лицо в архив?"
        closeHandler={() => handleCloseModal('isArchive')}
        confirmHandler={handleAddToArchive}
      />
      <ConfirmForm
        isOpen={modalState.isExtract}
        title="Вы действительно хотите вернуть юридическое лицо из архива?"
        closeHandler={() => handleCloseModal('isExtract')}
        confirmHandler={handleExtrFromArchive}
      />

      <ArchiveErrorModal
        isArchive
        isOpen={isArchiveError}
        legalEntity={legalEntity?.legalEntity || null}
        closeHandler={() => resetArchiveError()}
        isLegalProfile
      />
      {showEditInternalModal
        && <EditInternalModal
          isOpen={showEditInternalModal}
          legalEntity={legalEntity?.legalEntity || null}
          onClose={() => setShowEditInternalModal(false)}
        />
      }
    </div>
  );
};
