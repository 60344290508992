import { AxiosError } from 'axios';
import { createStore, createEffect, createEvent, sample, combine } from 'effector';
import { chatsApi, clientsApi, intermediariesApi } from 'src/shared/api';
import { renderClientsStatus } from 'src/pages/profiles/clientsProfile';
import { renderIntermediaryStatus } from 'src/pages/profiles/intermediaries/config';
import { setError, setSuccess } from 'src/features/notifications';
import { getMonthName } from 'src/shared/lib';

import { initChatInfoData, sizeCharacteristic } from './config';
import {
  ChatInfo,
  AttachmentCount,
  ChatInfoFiltered,
  ImagesData,
  chatUsersEnum,
  resultImagesData,
  Characteristic,
} from './types';

const $activeChatId = createStore<string | number>('');
export const $chatInfo = createStore<ChatInfoFiltered | null>(null);
export const $attachCount = createStore<AttachmentCount | null>(null);
export const $attachments = createStore<ImagesData | null>(null);
export const $resultImagesData = combine({ $attachCount, $attachments }, ({ $attachCount, $attachments }) => {
  const resultData: resultImagesData | null =
    $attachments && $attachCount?.types?.length
      ? $attachCount.types.reduce(
          (acc, countItem) => {
            let attachCounter = 0;

            countItem.months.forEach(month => {
              if ($attachments?.items && $attachments.items.slice(attachCounter, attachCounter + month.count).length) {
                acc.images = [
                  ...acc.images,
                  {
                    [`${getMonthName(month?.month)} ${month?.year || ''}`]: $attachments.items.slice(
                      attachCounter,
                      attachCounter + month.count,
                    ),
                  },
                ];
              }
              attachCounter += month.count;
            });
            acc.totalCount = countItem?.count || 0;
            acc.currentPage = $attachments?.pagination?.current_page || 0;
            acc.lastPage = $attachments?.pagination?.last_page || 0;
            acc.totalPages = $attachments?.pagination?.total || 0;
            return acc;
          },
          { images: [], totalCount: 0, currentPage: 0, lastPage: 0, totalPages: 0 } as resultImagesData,
        )
      : null;
  return resultData;
});

export const initChatInfo = createEvent<string | number>();
export const initAttach = createEvent<chatsApi.AttachmentsReqData>();
export const submitChangeClientStatus = createEvent<clientsApi.ClientsStatusReqData>();
export const submitChangeIntermedStatus = createEvent<intermediariesApi.IntermedStatusReqData>();

export const getChatInfoFx = createEffect(chatsApi.getChatInfo);
export const getAttachCountFx = createEffect(chatsApi.getAttachmentCount);
export const getAttachFx = createEffect(chatsApi.getAttachments);
export const changeClientStatusFx = createEffect<
  clientsApi.ClientsStatusReqData,
  null,
  AxiosError<{ message: string }>
>(clientsApi.setClientsStatus);
export const changeIntermedStatusFx = createEffect<
  intermediariesApi.IntermedStatusReqData,
  null,
  AxiosError<{ message: string }>
>(intermediariesApi.setIntermedStatus);

$activeChatId.on(initChatInfo, (_, data) => data);
$attachCount.on(getAttachCountFx.doneData, (_, data: AttachmentCount) => data).reset(initChatInfo);
$attachments
  .on(getAttachFx.doneData, (oldData: ImagesData | null, data: ImagesData) => {
    if (!oldData) return data;
    const resultData: ImagesData = { ...data, items: [...oldData.items, ...data.items] };
    return resultData;
  })
  .reset(initChatInfo);

$chatInfo.on(getChatInfoFx.doneData, (_, data: ChatInfo) => {
  const users = data?.chat?.members
    ? data.chat.members.reduce(
        (acc, item) => {
          if (
            item?.member?.user_type === chatUsersEnum.CLIENT &&
            item?.member?.user_id &&
            data?.clients[item.member.user_id]
          ) {
            const clientStatus = renderClientsStatus(data.clients[item.member.user_id]?.status || '');
            acc.clients = [
              ...acc.clients,
              {
                ...data.clients[item.member.user_id],
                status: clientStatus,
                originalStatus: data.clients[item.member.user_id]?.status || '',
              },
            ];
          }
          if (
            item?.member?.user_type === chatUsersEnum.INTERMEDIARY &&
            item?.member?.user_id &&
            data?.intermediaries[item.member.user_id]
          ) {
            const intermedStatus = renderIntermediaryStatus(data.intermediaries[item.member.user_id]?.status || '');
            acc.intermediaries = [
              ...acc.intermediaries,
              {
                ...data.intermediaries[item.member.user_id],
                status: intermedStatus,
                originalStatus: data.intermediaries[item.member.user_id]?.status || '',
              },
            ];
          }
          return acc;
        },
        { ...initChatInfoData },
      )
    : initChatInfoData;

  const characteristic: Characteristic =
    data?.offer?.characteristics?.find(item => item?.code === sizeCharacteristic) || ({} as Characteristic);

  return { ...users, task: data?.task, characteristic };
});

sample({
  clock: initChatInfo,
  target: [getChatInfoFx, getAttachCountFx],
});

sample({
  clock: initAttach,
  target: getAttachFx,
});

sample({
  clock: [changeClientStatusFx.doneData, changeIntermedStatusFx.doneData],
  source: $activeChatId,
  target: getChatInfoFx,
});

sample({
  clock: submitChangeClientStatus,
  target: changeClientStatusFx,
});

sample({
  clock: submitChangeIntermedStatus,
  target: changeIntermedStatusFx,
});

sample({
  clock: [changeClientStatusFx.doneData, changeIntermedStatusFx.doneData],
  fn: () => 'Статус успешно изменен',
  target: setSuccess,
});

sample({
  clock: [changeClientStatusFx.failData, changeIntermedStatusFx.failData],
  fn: data => data.response?.data?.message || 'Ошибка при изменении статуса',
  target: setError,
});
