import { createEffect, createEvent, createStore, sample } from 'effector';
import { useStore } from 'effector-react';
import { imageApi, intermediariesApi } from 'src/shared/api';
import { setError, setSuccess } from 'src/features/notifications';
import dayjs from 'dayjs';
import { AxiosError } from 'axios';

import { IntermediaryProfile, IntermedBalance } from './types';

export const $profile = createStore<IntermediaryProfile | null>(null);
export const $balance = createStore<IntermedBalance | null>(null);
export const $isPrevPath = createStore(false);

export const initIntermedProfilePage = createEvent<string>();
export const reupdateProfile = createEvent();
export const submitChangeStatusForm = createEvent<intermediariesApi.IntermedStatusReqData>();
export const submitPassEdition = createEvent<intermediariesApi.IntermediaryChangeData>();
export const submitPayBalanceReqData = createEvent<string>();
export const submitDeleting = createEvent<string>();
export const submitForceDeleting = createEvent<string>();
export const setIsPrevPath = createEvent<boolean>();

export const getProfileFx = createEffect(intermediariesApi.getIntermediary);
export const changeStatusFx = createEffect(intermediariesApi.setIntermedStatus);
export const editProfileFx = createEffect(intermediariesApi.editIntermediary);
export const getFileFx = createEffect(imageApi.getFileFromUrl);
export const getBalanceFx = createEffect(intermediariesApi.getIntermediaryBalance);
export const payBalanceFx = createEffect(intermediariesApi.payIntermediaryBalance);
export const deleteIntermediaryFx = createEffect<string, null, AxiosError<{ message: string }>>(
  intermediariesApi.deleteIntermediary,
);
export const forceDeleteIntermediaryFx = createEffect<string, null, AxiosError<{ message: string }>>(
  intermediariesApi.forceDeleteIntermediary,
);

$profile.on(getProfileFx.doneData, (_, data) => ({
  ...data,
  pass_valid_until: data.pass_valid_until ? dayjs(data.pass_valid_until).format('YYYY-MM-DD') : null,
}));
$balance.on(getBalanceFx.doneData, (_, data) => data);
$isPrevPath.on(setIsPrevPath, (_, data) => data);

export const useProfile = () => useStore($profile);

sample({
  clock: initIntermedProfilePage,
  target: [getProfileFx, getBalanceFx],
});

sample({
  clock: submitChangeStatusForm,
  target: changeStatusFx,
});

sample({
  clock: submitPassEdition,
  target: editProfileFx,
});

sample({
  clock: submitPayBalanceReqData,
  target: payBalanceFx,
});

sample({
  clock: [changeStatusFx.failData, editProfileFx.failData, payBalanceFx.failData],
  fn: ({ message }) => message,
  target: setError,
});

sample({
  clock: changeStatusFx.doneData,
  fn: () => 'Статус успешно изменен',
  target: [setSuccess, reupdateProfile],
});

sample({
  clock: editProfileFx.doneData,
  fn: () => 'Пропуск успешно изменен',
  target: [setSuccess, reupdateProfile],
});

sample({
  clock: payBalanceFx.doneData,
  fn: () => 'Баланс успешно выплачен',
  target: [setSuccess, reupdateProfile],
});

sample({
  clock: reupdateProfile,
  source: $profile,
  filter: data => !!data?.id,
  fn: data => data?.id || '',
  target: [getProfileFx, getBalanceFx],
});

sample({
  clock: submitDeleting,
  target: deleteIntermediaryFx,
});

sample({
  clock: deleteIntermediaryFx.doneData,
  fn: () => 'Посредник успешно удален',
  target: setSuccess,
});

sample({
  clock: deleteIntermediaryFx.doneData,
  source: submitDeleting,
  target: [getProfileFx, getBalanceFx],
});

sample({
  clock: deleteIntermediaryFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при удалении',
  target: setError,
});

sample({
  clock: submitForceDeleting,
  target: forceDeleteIntermediaryFx,
});

sample({
  clock: forceDeleteIntermediaryFx.doneData,
  fn: () => 'Посредник успешно удален',
  target: setSuccess,
});

sample({
  clock: forceDeleteIntermediaryFx.doneData,
  source: submitForceDeleting,
  target: [getProfileFx, getBalanceFx],
});

sample({
  clock: forceDeleteIntermediaryFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при удалении',
  target: setError,
});
