import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Card, Select, Switch, notification } from 'antd';
import { getFormattedPhone } from 'src/shared/lib';
import { UserOutlined } from '@ant-design/icons';
import { renderSuppStatus } from 'src/pages/profiles/suppliers/config';
import { MainButton } from 'src/shared/ui/mainButton';
import { SupplierProfile } from 'src/pages/profiles/suppliersProfile';
import { ArchiveErrorModal } from 'src/pages/profiles/legalEntities/ui/archiveErrorModal';
import { useSupplierStatuses } from 'src/pages/profiles/suppliers/hooks';
import { ESupplierStatus } from 'src/pages/profiles/suppliers/types';
import { submitChangeStatusForm } from 'src/pages/profiles/suppliersProfile/model';
import { Icons } from 'src/img/icons';
import { MainModal } from 'src/shared/ui';
import { suppliersApi } from 'src/shared/api';
import { AxiosError } from 'axios';

import styles from './ProfilesCard.module.less';

interface ProfilesCardProps {
  data: SupplierProfile;
  allowEdit?: boolean;
  isArchiveError?: boolean;
  editHandler: () => void;
  resetArchiveError: () => void;
}

export const ProfilesCard = ({
  data,
  allowEdit,
  isArchiveError,
  editHandler,
  resetArchiveError,
}: ProfilesCardProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<ESupplierStatus>(data.status);
  const { supplierStatuses } = useSupplierStatuses();
  const [isTop, setIsTop] = useState(data.top_status || false);
  const [is10Percent, setIs10Percent] = useState(data.has_10_percent_commission || false);
  const [isSelfCollection, setIsSelfCollection] = useState(data.self_collection || false);
  const [changeTopType, setChangeTopType] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const handleEditStatus = () => setIsEdit(true);

  const handleSelectStatus = (value: ESupplierStatus) => setSelectedStatus(value);

  const handleSaveStatus = async () => {
    submitChangeStatusForm({
      id: data.id,
      data: {
        status: selectedStatus,
      },
    });

    return false;
  };

  const handleCancelEdit = () => {
    setIsEdit(false);
  };

  const handleChangeTop = (type: string) => {
    setChangeTopType(type);
  };

  const handleSelfCollection = async () => {
    try {
      setIsLoading(true);
      await suppliersApi.toggleSelfCollection(Number(data.id));
      setIsSelfCollection(prev => !prev)
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({
        message: error.response?.data.message || `Ошибка сохранения самосбора`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeTop = async () => {
    try {
      setIsLoading(true);
      if (changeTopType === 'top') {
        await suppliersApi.changeTopStatus({
          activate: !isTop,
          supplier_id: Number(data.id),
        });
        setIsTop(prev => !prev);
      } else {
        await suppliersApi.change10PercentCommission({
          activate: !is10Percent,
          supplier_id: Number(data.id),
        });
        setIs10Percent(prev => !prev);
      }
      setChangeTopType(undefined);
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({
        message:
          error.response?.data.message ||
          `Ошибка изменения ${changeTopType === 'top' ? 'ТОП статуса' : '10% комиссии'}`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setChangeTopType(undefined);
  };

  return (
    <div className={styles.content}>
      <Card className={styles.card}>
        <div className={styles.wrapper}>
          <div className={styles.avatar}>
            <Avatar shape='square' size={150} icon={<UserOutlined />} src={data?.avatar} />
          </div>

          <div className={styles.body}>
            <div>
              <p className={styles.name}>
                {data?.name || ''}
                {data?.is_registered && <Icons.StatusActive />}
              </p>
              <div className={styles.block}>
                <p className={styles.data}>
                  <strong>Email: </strong> {data?.email || '-'}
                </p>
              </div>
              <div className={styles.block}>
                <p className={styles.data}>
                  <strong>Телефон: </strong> {data?.phone ? getFormattedPhone(data.phone) : '-'}
                </p>
              </div>
              <div className={styles.block}>
                <p className={styles.data}>
                  <strong>Юридическое лицо: </strong>
                  {data?.legal_entity?.id ? (
                    <Link to={`/profile/legalEntity/${data.legal_entity.id}`} state={{ prevPath: true }}>
                      {data?.legal_entity?.full_name || '-'}
                    </Link>
                  ) : (
                    '-'
                  )}
                </p>
              </div>
              <div className={styles.block}>
                <div className={styles.data}>
                  <strong>Статус: </strong>
                  {!isEdit ? (
                    renderSuppStatus(data?.status)
                  ) : (
                    <Select className={styles.select} onChange={handleSelectStatus} defaultValue={data.status}>
                      {supplierStatuses.map(item => (
                        <Select.Option value={item.status} key={`status-${item.status}`}>
                          <div
                            className={`${styles.option} ${
                              styles[`${item.status.toLowerCase()}`]
                            } ${item.status.toLowerCase()}`}
                          >
                            {item.status_name}
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </div>
              </div>
            </div>
            {allowEdit ? (
              <div className={styles.buttons}>
                {isEdit ? (
                  <>
                    <MainButton onClick={handleSaveStatus} className={styles.button}>
                      Сохранить
                    </MainButton>
                    <MainButton onClick={handleCancelEdit} className={styles.button}>
                      Отмена
                    </MainButton>
                  </>
                ) : (
                  <MainButton onClick={handleEditStatus} className={styles.button}>
                    Редактировать статус
                  </MainButton>
                )}

                <MainButton className={styles.button} onClick={editHandler}>
                  Редактировать профиль
                </MainButton>
              </div>
            ) : null}
          </div>
        </div>
      </Card>
      <Card className={styles.card}>
        <div className={styles.data}>
          <Switch
            size='small'
            checked={isTop}
            disabled={data.legal_entity?.is_internal}
            onChange={() => handleChangeTop('top')}
          />
          <strong>ТОП</strong>
        </div>

        <div className={styles.data}>
          <Switch
            size='small'
            checked={is10Percent}
            disabled={data.legal_entity?.is_internal}
            onChange={() => handleChangeTop('10percent')}
          />
          <strong>10% комиссия</strong>
        </div>
        <div>
          ТОП — это функция продвижения товаров поставщика внутри приложения. За использование функции взымается 10%
          комиссии с каждого проданного товара. Функция недоступна, если юридическое лицо внутреннее
        </div>
      </Card>
      <Card className={styles.card}>
        <div className={styles.data}>
          <Switch
            size='small'
            checked={isSelfCollection}
            disabled={data.legal_entity?.is_internal || isLoading}
            onChange={handleSelfCollection}
          />
          <strong>Самостоятельный сбор</strong>
        </div>
        <div>
          Когда включено, поставщик сам сдаёт товары в пункты GTS. Поставщик должен быть зарегистрирован
          в seller.allall.ru
        </div>
      </Card>

      <ArchiveErrorModal
        isArchive={false}
        legalEntity={data?.legal_entity}
        isOpen={!!isArchiveError}
        closeHandler={() => resetArchiveError()}
      />
      {changeTopType && (
        <MainModal isOpen closeHandler={handleCloseModal}>
          <>
            <div className={styles.name}>
              {changeTopType === 'top'
                ? `${isTop ? 'Выключить' : 'Включить'} ТОП для поставщика ${data.name}?`
                : `${is10Percent ? 'Выключить' : 'Включить'} 10% комиссию для поставщика ${data.name}?`}
            </div>
            <div className={styles.buttons}>
              <Button className={styles.button} type='primary' autoFocus onClick={onChangeTop} loading={isLoading}>
                Да, {isTop ? 'выключить' : 'включить'}
              </Button>
              <Button className={styles.button} type='text' onClick={handleCloseModal} disabled={isLoading}>
                Отмена
              </Button>
            </div>
          </>
        </MainModal>
      )}
    </div>
  );
};
