import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import { useReceptionPoint } from '../../hooks/useRecipientPoint';
import styles from './PrintQr.module.less';

export const PrintQr: React.FC = () => {
  const { printQrData, setPrintQrData } = useReceptionPoint();
  const { code, url, count } = printQrData || {};

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  useEffect(() => {
      reactToPrintFn();
      setPrintQrData?.(undefined)
  }, []);

  return (
    <div ref={contentRef} className={styles.container}>
      {Array(count)
        .fill('')
        .map(() => (
          <div className={styles.page}>
            <img src={url} alt='' />
            <strong>{code}</strong>
          </div>
        ))}
    </div>
  );
};
