import { notification } from 'antd';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Task } from 'src/pages/orders';
import { tasksApi } from 'src/shared/api';
import { AxiosError } from 'axios';
import { Client } from 'src/pages/profiles/clients/types';
import { OrderTaskStatuses } from 'src/pages/orders/ordersList/types';
import { SelectOptions, Paginated } from 'src/shared/types';
import { TasksRequestData, TTasksListResponse } from 'src/shared/api/queries/tasksApi';
import { ColumnsType } from 'antd/lib/table';
import { v4 as uuid } from 'uuid';
import { DetailedTask } from 'src/pages/task/types';
import { useIsGTS } from 'src/widgets/header/model';

import { initState, TASK_STATUS } from '../ReceptionPoint.const';
import { getColumns } from '../components/ReceptionPointTable/ReceptionPointTable.utils';
import { TGetTaskByCodeValues, TMovedPickupPointValues, TPrintQrValues } from '../ReceptionPoint.types';

type TReturnValues = {
  readonly tasks: Paginated<Task>;
  readonly clients: Client[];
  readonly taskStatuses: OrderTaskStatuses['items'];
  readonly taskStatusOptions: SelectOptions;
  readonly loading: boolean;
  readonly searchParams: TasksRequestData;
  readonly setSearchParams: React.Dispatch<React.SetStateAction<TasksRequestData>>;
  readonly totalCount: number;
  readonly returnModalData: Task;
  readonly setReturnModalData: React.Dispatch<React.SetStateAction<Task | undefined>>;
  readonly columns: ColumnsType<Task>;
  readonly initColumnSettings: Record<string, boolean>;
  readonly fetchReturnGoods: () => Promise<void>;
  readonly taskCheckCount: DetailedTask;
  readonly setTaskCheckCount: React.Dispatch<React.SetStateAction<DetailedTask | undefined>>;
  readonly getTaskByCode: (value: TGetTaskByCodeValues) => Promise<void>;
  readonly movedPickupPoint: (value: TMovedPickupPointValues) => Promise<void>;
  readonly showAddManualModal: boolean;
  readonly setShowAddManualModal: React.Dispatch<React.SetStateAction<boolean>>;
  readonly printQrData: TPrintQrValues;
  readonly setPrintQrData: React.Dispatch<React.SetStateAction<TPrintQrValues | undefined>>;
  readonly preview: Task;
  readonly setPreview: React.Dispatch<React.SetStateAction<Task | undefined>>;
};

const ReceptionPointContext = createContext<Partial<TReturnValues>>({});

const audioAlert = new Audio('/assets/sound/alert.mp3');
const audioAdded = new Audio('/assets/sound/added.mp3');

export const ReceptionPointProvider = ({ children }: { children: ReactNode }) => {
  const [tasks, setTasks] = useState<Paginated<Task>>();
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchParams, setSearchParams] = useState<TasksRequestData>({
    ...initState,
  });
  const [returnModalData, setReturnModalData] = useState<Task>();
  const [refetch, setRefetch] = useState<string>();
  const [taskCheckCount, setTaskCheckCount] = useState<DetailedTask>();
  const [showAddManualModal, setShowAddManualModal] = useState(false);
  const [printQrData, setPrintQrData] = useState<TPrintQrValues>();
  const [preview, setPreview] = useState<Task>();

  const isGTS = useIsGTS();

  const handlePrintQr = async ({ code, id, count }: { code: string; id: number; count: number }) => {
    try {
      const response = await tasksApi.getTasksQr(String(id));
      setPrintQrData({
        code,
        url: response.url,
        count,
      });
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({ message: error.response?.data.message || 'Ошибка печати QR кода' });
    }
  };

  const { columns, initColumnSettings } = getColumns({
    onReturn: (item: Task) => {
      setReturnModalData?.(item);
    },
    isGTS,
    onPrintQr: handlePrintQr,
    onPreview: setPreview,
  });

  const movedPickupPoint = useCallback(async ({ code, id, count }: TMovedPickupPointValues) => {
    try {
      await tasksApi.taskSupplierMovedPickupPoint(id);

      setTaskCheckCount?.(undefined);
      setShowAddManualModal(false);
      setRefetch(uuid());

      audioAdded.play();
      notification.success({ message: 'Товар принят' });

      handlePrintQr({ code, id, count });
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({ message: error.response?.data.message || 'Ошибка приемки товара' });
      audioAlert.play();
    }
  }, []);

  const getTaskByCode = useCallback(async ({ code }: TGetTaskByCodeValues) => {
    if (!code) {
      return;
    }

    try {
      const response = await tasksApi.getTaskByCode({
        code,
      });

      if (response?.task.count > 1) {
        setTaskCheckCount(response);
        setShowAddManualModal(false);
      } else {
        movedPickupPoint({
          code: response.task?.code,
          id: response.task?.id,
          count: response?.task.count,
        });
      }
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({ message: error.response?.data.message || 'Ошибка поиска задания' });
      audioAlert.play();
    }
  }, []);

  const fetchReturnGoods = useCallback(async () => {
    if (!returnModalData?.id) {
      return;
    }

    try {
      await tasksApi.taskSupplierVerification(Number(returnModalData?.id));
      setReturnModalData(undefined);
      setRefetch(uuid());
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({ message: error.response?.data.message || 'Ошибка возврата товаров' });
    }
  }, [returnModalData]);

  const fetchTasks = async () => {
    if (loading) {
      return;
    }

    try {
      setLoading(true);

      const response: TTasksListResponse = await tasksApi.getTasksList({
        ...searchParams,
        status: TASK_STATUS,
      });

      setTasks({
        pagination: response.pagination,
        items: response?.items?.map(order => {
          const client = response.clients[order.client_id];
          return {
            ...order,
            client: {
              id: Number(client.id),
              name: client.name,
            },
            supplier: response.suppliers[order.supplier_id],
            pavilion: response.suppliers[order.supplier_id]?.pavilions.map(i => i.name).join(', ') || '-',
            intermediary: response.intermediarys[order.intermediary_id],
          };
        }),
      });

      setTotalCount(response?.pagination?.total);
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({ message: error.response?.data.message || 'Ошибка загрузка заданий' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [searchParams, refetch]);

  const values = useMemo(
    () => ({
      tasks,
      loading,
      searchParams,
      setSearchParams,
      totalCount,
      returnModalData,
      setReturnModalData,
      columns,
      initColumnSettings,
      fetchReturnGoods,
      taskCheckCount,
      getTaskByCode,
      movedPickupPoint,
      setTaskCheckCount,
      showAddManualModal,
      setShowAddManualModal,
      printQrData,
      setPrintQrData,
      preview,
      setPreview,
    }),
    [
      tasks,
      loading,
      searchParams,
      totalCount,
      returnModalData,
      columns,
      initColumnSettings,
      taskCheckCount,
      showAddManualModal,
      printQrData,
      preview,
    ],
  );

  return <ReceptionPointContext.Provider value={values}>{children}</ReceptionPointContext.Provider>;
};

export const useReceptionPoint = (): Partial<TReturnValues> => ({ ...useContext(ReceptionPointContext) });
