import { Button, Radio } from 'antd';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

import { dataMock } from './config';
import styles from './AnalyticsRevenue.module.less';

export const AnalyticsRevenue = () => (
  <article className={styles.AnalyticsRevenue}>
    <div className={styles.AnalyticsRevenueHeader}>
      <div>
        <h3>Выручка</h3>

        <Radio.Group defaultValue="day" buttonStyle="solid">
          <Radio.Button value="day">День</Radio.Button>
          <Radio.Button value="week">Неделя</Radio.Button>
          <Radio.Button value="month">Месяц</Radio.Button>
        </Radio.Group>
      </div>
      <Button onClick={() => { }}>Сформировать отчет</Button>
    </div>

    <ResponsiveContainer
      width='100%'
      aspect={4.0 / 1.05}
    >
      <LineChart
        data={dataMock}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="pv" stroke="#C7D3DC" />
      </LineChart>
    </ResponsiveContainer>
  </article>
);