import React, { useState } from 'react';
import { MainModal } from 'src/shared/ui';
import { Button } from 'antd';

import { useReceptionPoint } from '../../hooks/useRecipientPoint';
import styles from './ReturnGoodsModal.module.less';

export const ReturnGoodsModal: React.FC = () => {
  const { setReturnModalData, fetchReturnGoods } = useReceptionPoint();
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (fetchReturnGoods) {
      setLoading(true);
      await fetchReturnGoods();
      setLoading(false)
    }
  };

  return (
    <MainModal isOpen closeHandler={() => setReturnModalData?.(undefined)} title='Вернуть товар поставщику?'>
      <div className={styles.container}>
        <div className={styles.modalText}>После подтверждения товар необходимо будет вернуть поставщику</div>
        <div className={styles.buttons}>
          <Button className={styles.button} type='primary' onClick={handleClick} disabled={loading} loading={loading}>
            Подтвердить
          </Button>
          <Button onClick={() => setReturnModalData?.(undefined)} className={styles.button} disabled={loading}>
            Отмена
          </Button>
        </div>
      </div>
    </MainModal>
  );
};
