import { AxiosError } from 'axios';
import { createStore, createEvent, createEffect, sample, combine } from 'effector';
import { categoriesApi, pavilionsApi, suppliersApi, sourceApi } from 'src/shared/api';
import { setSuccess, setError } from 'src/features/notifications';
import { AddSupPavResData } from 'src/pages/profiles/suppliersProfile/ui/suppParser/ui/addSource/types';
import { SelectOptions, Pavilion, Paginated } from 'src/shared/types';

import { sourceDataInitState, pavIdInitState } from './config';
import { FormData, DetailedSource, Categories } from './types';


export const $categories = createStore<Categories | null>(null);
export const $reloadFlag = createStore<boolean>(false);
export const $pavilionsOptions = createStore<SelectOptions>([]);
export const $formData = createStore<FormData | null>(null);
export const $sourceFormData = createStore<sourceApi.UpdateSourceReqData>(sourceDataInitState);
export const $pavId = createStore<{ data: { supplier_pavilion_id: number } }>(pavIdInitState);

export const initAddSourcePage = createEvent();
export const initFlag = createEvent();
export const submitUpdateSourceData = createEvent<FormData>();
export const submitUpdateSource = createEvent<sourceApi.UpdateSourceReqData>();
export const initPagePavilions = createEvent<pavilionsApi.getPavilionsReqData>();

export const getCategoriesFx = createEffect(categoriesApi.getCategories);
export const updateSourceFx = createEffect<
  sourceApi.UpdateSourceReqData,
  DetailedSource,
  AxiosError<{ message: string }>
>(sourceApi.updateSource);
export const getPavilionsFx = createEffect(pavilionsApi.getPavilions);
export const addSuppPavFx = createEffect<
  suppliersApi.AddSupplierPavReqData,
  AddSupPavResData,
  AxiosError<{ message: string }>
>(suppliersApi.addSupplierPavilion);

$categories.on(getCategoriesFx.doneData, (_, data) => data);
$reloadFlag.on(updateSourceFx.doneData, () => true);
$reloadFlag.on(initFlag, () => false);
$pavilionsOptions.on(getPavilionsFx.doneData, (_, data: Paginated<Pavilion>) => {
  const pavilionsOptions = (data?.items || []).map(({ id, name }) => ({ label: name, value: name, key: id, id }));
  return pavilionsOptions;
});
$formData.on(submitUpdateSourceData, (_, data) => data);
$sourceFormData.on(submitUpdateSourceData, (_, data) => data.sourceData);
$pavId.on(addSuppPavFx.doneData, (_, data) => ({
  data: { supplier_pavilion_id: data.id },
}));

sample({
  clock: initAddSourcePage,
  target: getCategoriesFx,
});

sample({
  clock: initPagePavilions,
  target: getPavilionsFx,
});

sample({
  clock: submitUpdateSource,
  target: updateSourceFx,
});

sample({
  clock: submitUpdateSourceData,
  fn: $formData => $formData.suppPavData,
  target: addSuppPavFx,
});

sample({
  clock: addSuppPavFx.doneData,
  source: combine($sourceFormData, $pavId, (sourceFormData, pavId) => ({
    id: sourceFormData.id,
    data: { ...sourceFormData.data, ...pavId.data },
  })),
  target: updateSourceFx,
});

sample({
  clock: updateSourceFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при редактировании источника',
  target: setError,
});

sample({
  clock: addSuppPavFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка привязки павильона к поставщику',
  target: setError,
});

sample({
  clock: updateSourceFx.doneData,
  fn: () => 'Источник успешно отредактирован',
  target: setSuccess,
});
