import { notification } from 'antd';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { pavilionsApi } from 'src/shared/api';
import { getPavilionsReqData } from 'src/shared/api/queries/pavilionsApi';
import { Paginated, Pavilion } from 'src/shared/types';
import { useDebounce } from 'src/utils';

export const useSearchPavilion = () => {
  const [pavilions, setPavilions] = useState<Paginated<Pavilion>>();
  const [params, setParams] = useState<getPavilionsReqData>();
  const [loading, setLoading] = useState(false);
  const debouncedValue = useDebounce(params);

  const fetch = async () => {
    if (!debouncedValue) {
      return;
    }

    setLoading(true);

    try {
      const response = await pavilionsApi.getPavilions(debouncedValue);
      if (response) {
        setPavilions(response);
      }
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({ message: error.response?.data.message || 'Ошибка загрузки павильонов' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [debouncedValue]);

  return {
    pavilions,
    loading,
    setParams,
  };
};
