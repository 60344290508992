import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { MoreOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { Card, Dropdown, Table, Empty, Button, TreeSelect } from 'antd';
import { Title, MainModal } from 'src/shared/ui';
import { MainButton } from 'src/shared/ui/mainButton';
import { treeSelectFieldNames } from 'src/shared/config';

import {
  $characteristics,
  submitCreationForm,
  getCharOnPageInit,
  getCharacteristicsFx,
  submitRemovalForm,
  submitEditingForm,
  $requestData,
  getCategoriesFx,
  $categories,
  changeRequestData,
} from './model';
import { columns, dropdownStyle, initState } from './config';
import { AddSize } from './ui/addSize';
import { Size, SizeData } from './types';
import styles from './Sizes.module.less';

export const Sizes = () => {
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenRemove, setIsOpenRemove] = useState(false);
  const [activeSize, setActiveSize] = useState<Size | null>(null);
  const characteristics = useStore($characteristics);
  const categories = useStore($categories);
  const requestData = useStore($requestData);
  const isLoading = useStore(getCharacteristicsFx.pending);
  const isLoadingCategories = useStore(getCategoriesFx.pending);
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get('category');

  useEffect(() => {
    getCharOnPageInit(initState);
  }, []);

  useEffect(() => {
    if (categoryId) {
      changeRequestData({ ...initState, filters: { category_id: +categoryId } });
    } else {
      changeRequestData(initState);
    }
  }, [categoryId]);

  const handleOpenAdd = () => setIsOpenAdd(true);

  const handleOpenRemove = () => setIsOpenRemove(true);

  const handleCloseAdd = () => {
    setActiveSize(null);
    setIsOpenAdd(false);
  };

  const handleCloseRemove = () => {
    setIsOpenRemove(false);
    setActiveSize(null);
  };

  const handleAddCharacteristic = (data: SizeData) => {
    submitCreationForm({ type: initState.code, data });
  };

  const handleEditCharacteristic = (data: SizeData) =>
    activeSize?.id && submitEditingForm({ type: initState.code, id: activeSize.id, data });

  const handleRemoveSize = (): void => {
    setIsOpenRemove(false);
    setActiveSize(null);
    if (activeSize?.id) {
      submitRemovalForm(activeSize?.id);
    }
  };

  const handleChangeSearch = (value: number) => {
    setSearchParams({ category: value.toString() });
  };

  const clearSearch = () => {
    setSearchParams({});
  };

  const filteredColumns = [
    ...columns,
    {
      width: '3%',
      title: '',
      key: 'settings',
      render: (data: Size) => (
        <Dropdown
          overlayClassName={styles.menu}
          placement="bottom"
          menu={{
            items: [
              {
                key: 'edit',
                label: 'Редактировать',
                onClick: () => {
                  setActiveSize(data);
                  handleOpenAdd();
                },
              },
              {
                key: 'remove',
                label: 'Удалить',
                onClick: () => {
                  setActiveSize(data);
                  handleOpenRemove();
                },
              },
            ],
          }}>
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.sizes}>
      <Card className={styles.card}>
        <div className={styles.wrapper}>
          <Title className={styles.title}>Размеры</Title>
          <div className={styles.buttonAdd}>
            <Button type="primary" onClick={handleOpenAdd}>
              Добавить
            </Button>
          </div>
        </div>
        <div className={styles.wrapper}>
          <TreeSelect
            value={requestData.filters?.category_id}
            loading={isLoadingCategories}
            disabled={isLoadingCategories}
            style={{
              width: '100%',
            }}
            fieldNames={treeSelectFieldNames}
            dropdownStyle={dropdownStyle}
            treeData={categories?.items}
            placeholder="Поиск по категории"
            allowClear
            onChange={handleChangeSearch}
            onClear={clearSearch}
          />
        </div>
        <Table
          className={styles.table}
          rowKey={record => record.id}
          bordered
          columns={filteredColumns}
          dataSource={characteristics?.values || []}
          pagination={{
            showSizeChanger: true,
          }}
          locale={{ emptyText: <Empty description="Нет данных" /> }}
          loading={isLoading}
        />
      </Card>

      <MainModal isOpen={isOpenAdd} closeHandler={handleCloseAdd}>
        <AddSize
          initSize={activeSize}
          closeHandler={handleCloseAdd}
          successHandler={handleAddCharacteristic}
          editHandler={handleEditCharacteristic}
        />
      </MainModal>

      <MainModal isOpen={isOpenRemove} closeHandler={handleCloseRemove}>
        <div className={styles.remove}>
          <h2 className={styles.removeTitle}>Вы уверены, что хотите удалить {activeSize?.value || ''} размер?</h2>
          <div className={styles.buttons}>
            <MainButton className={styles.button} onClick={handleRemoveSize} type="primary">
              Удалить
            </MainButton>
            <MainButton className={styles.button} onClick={handleCloseRemove}>
              Отменить
            </MainButton>
          </div>
        </div>
      </MainModal>
    </div>
  );
};
