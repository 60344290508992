import { Button, Form, Input } from 'antd';
import { useEffect } from 'react';

import { FormFields } from '../../types';
import styles from './WordModal.module.less';

interface WordModalProps {
  title: string;
  initialValue?: string;
  closeHandler: () => void;
  formSubmitHandler: (data: FormFields) => void;
}

export const WordModal = ({ title, initialValue, closeHandler, formSubmitHandler }: WordModalProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ word: initialValue });
  }, [form, initialValue]);

  const handleCancelClose = () => {
    form.resetFields();
    closeHandler();
  };

  const handleFormSubmit = (values: FormFields) => {
    formSubmitHandler(values);
    form.resetFields();
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{title}</h2>
      <Form
        form={form}
        name="basic"
        initialValues={{ word: initialValue }}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleFormSubmit}
        autoComplete="off">
        <Form.Item label="Исключение" name="word" rules={[{ required: true, message: 'Пожалуйста, введите слово!' }]}>
          <Input />
        </Form.Item>

        <div className={styles.buttons}>
          <Form.Item className={styles['button-container']}>
            <Button htmlType="submit" className={styles.button} type="primary">
              Готово
            </Button>
          </Form.Item>

          <Button onClick={handleCancelClose} className={styles.button}>
            Отмена
          </Button>
        </div>
      </Form>
    </div>
  );
};
