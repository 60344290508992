import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Select } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { UserOutlined } from '@ant-design/icons';
import { getFormattedPhone } from 'src/shared/lib';

import { notAllowedToEditStatuses } from '../../config';
import { StatusOptions, ChatClient, ChatIntermed, ProfileStatusesFiltered, chatUsers } from '../../types';
import styles from './ChatMemberInfo.module.less';

interface ChatMemberInfoProps {
  statusOptions: StatusOptions;
  data: ChatClient<ProfileStatusesFiltered> | ChatIntermed<ProfileStatusesFiltered>;
  memberType: chatUsers;
  handleChangeStatus: (profileType: chatUsers, id: number, value: string) => void;
}

export const ChatMemberInfo = ({ statusOptions, data, memberType, handleChangeStatus }: ChatMemberInfoProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const profileType = memberType === 'client' ? 'Клиент' : 'Посредник';
  const profileAvatar = data?.avatar_url || '';
  const profileName = data?.name || '-';
  const profileEmail = data?.email || '-';
  const profilePhone = data?.phone ? getFormattedPhone(data.phone) : '-';
  const profileStatus = data?.status || '-';

  const handleEditStatus = () => setIsEdit(true);

  const handleSelectStatus = (value: string) => setSelectedStatus(value);

  const handleSaveStatus = () => {
    if (selectedStatus && data?.id) {
      handleChangeStatus(memberType, data.id, selectedStatus);
    }
    setIsEdit(false);
  };

  return (
    <div className={styles.chatMemberInfo}>
      <div className={styles.title}>
        <Avatar shape="square" size={40} icon={<UserOutlined />} src={profileAvatar} />
        <div>
          <p className={styles.name}>{profileName}</p>
          <p>{profileType}</p>
        </div>
      </div>
      <div className={styles.body}>
        <div>
          <p className={styles.data}>
            <strong>Email: </strong> {profileEmail}
          </p>
          <p className={styles.data}>
            <strong>Телефон: </strong> {profilePhone}
          </p>
          <div className={styles.data}>
            <strong>Статус: </strong>
            {!isEdit ? (
              profileStatus
            ) : (
              <Select className={styles.select} onChange={handleSelectStatus}>
                {statusOptions.map(([key, value]) => (
                  <Select.Option value={key} key={key}>
                    <div className={`${styles.option} ${styles[`${key}`]}`}>{value}</div>
                  </Select.Option>
                ))}
              </Select>
            )}
          </div>
        </div>
        <div className={styles.buttons}>
          {data?.id ? (
            <Link to={`/profile/${memberType}/${data.id}`} state={{ prevPath: true }} target="_blank" rel="noreferrer">
              <Button className={styles.button}>Открыть профиль</Button>
            </Link>
          ) : null}
          {!notAllowedToEditStatuses.includes(data.originalStatus!) ? (
            <div>
              {!isEdit ? (
                <Button onClick={handleEditStatus} className={styles.button}>
                  Редактировать статус
                </Button>
              ) : (
                <Button onClick={handleSaveStatus} className={styles.button}>
                  Сохранить
                </Button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
