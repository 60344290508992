import { useRef } from 'react';
import { Image } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Hooks } from 'src/shared/lib';

import { resultImagesData } from '../../types';
import styles from './MediaInfo.module.less';

interface MediaInfoProps {
  imagesData: resultImagesData;
  handleChangePage: () => void;
}

export const MediaInfo = ({ imagesData, handleChangePage }: MediaInfoProps) => {
  const listRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isImages = imagesData?.images && imagesData.images.length;

  const { handleScroll } = Hooks.useInfinitePagination({
    fetcher: handleChangePage,
    listRef,
    wrapperRef,
    lastPage: imagesData.lastPage,
  });

  return (
    <div className={styles.mediaInfo}>
      <div className={styles.title}>
        <h2>Фото {imagesData?.totalCount || 0}</h2>
      </div>
      {isImages ? (
        <div className={styles.wrapper}>
          <div ref={wrapperRef} onScroll={handleScroll}>
            <div ref={listRef}>
              <Image.PreviewGroup>
                {imagesData.images.map(source => {
                  const imagesTitle = Object.keys(source)[0];
                  const imagesData = Object.values(source);
                  return (
                    <div key={imagesTitle}>
                      {imagesData.map(imageArr => (
                        <div className={styles.block} key={imagesTitle}>
                          <div className={styles.info}>
                            <p className={styles.month}>{imagesTitle}</p>
                            <p>{`${imageArr.length} фото`}</p>
                          </div>
                          <div className={styles.images}>
                            {imageArr.map(value => (
                              <Image
                                preview={{
                                  mask: (
                                    <>
                                      <EyeOutlined /> <span>Предпросмотр</span>
                                    </>
                                  ),
                                }}
                                key={value.id}
                                src={value.url}
                                placeholder
                              />
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                })}
              </Image.PreviewGroup>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.noMessagesWrapper}>
          <span className={styles.noMessageTitle}>Фотографии отсутствуют</span>
        </div>
      )}
    </div>
  );
};
