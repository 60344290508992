import React from 'react';

import { PaymentsSuppliersProvider } from './hooks/usePaymentsSuppliers';
import { Content } from './components';

export const PaymentsSuppliersPage: React.FC = () => (
    <PaymentsSuppliersProvider>
      <Content />
    </PaymentsSuppliersProvider>
  );
