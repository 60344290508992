import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { MoreOutlined } from '@ant-design/icons';
import { Card, Dropdown, Table, Input, Spin, Button, Empty } from 'antd';
import { ConfirmForm, MainModal } from 'src/shared/ui';

import {
  $requestData,
  $exceptions,
  changeRequestData,
  useLoading,
  initExceptionsPage,
  submitCreationForm,
  submitEditionForm,
  submitDeleteException,
} from './model';
import { PER_PAGE } from './config';
import { WordModal } from './ui';
import { Exception, FormFields } from './types';
import styles from './Exceptions.module.less';

const { Search } = Input;

export const Exceptions = () => {
  const [editedException, setEditedException] = useState<Exception | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deletingException, setDeletingException] = useState<number | null>(null);
  const requestData = useStore($requestData);
  const isLoading = useLoading();
  const exceptions = useStore($exceptions);

  useEffect(() => {
    initExceptionsPage();
  }, []);

  const handleChangePage = (value: number, size: number) => {
    changeRequestData({ ...requestData, pagination: { page: value, per_page: size } });
  };

  const searchNameHandler = (nameValue: string) => {
    changeRequestData({ ...requestData, search: nameValue });
  };

  const handleEditException = (exception: Exception) => {
    setEditedException(exception);
    setIsOpenModal(true);
  };

  const handleSubmitForm = ({ word }: FormFields) => {
    setIsOpenModal(false);
    if (editedException) {
      submitEditionForm({ ...editedException, word });
      setEditedException(null);
    } else {
      submitCreationForm(word);
    }
  };

  const confirmDelete = () => {
    if (deletingException) {
      submitDeleteException(deletingException);
    }
    setDeletingException(null);
  };

  const handleCloseUpdate = () => setIsOpenModal(false);

  const handleOpenAdd = () => {
    setIsOpenModal(true);
    setEditedException(null);
  };

  return (
    <div className={styles.exceptions}>
      <Card className={styles.card}>
        <h2 className={styles.title}>Исключения</h2>
        <div className={styles.wrapper}>
          <Search className={styles.search} placeholder="Поиск по названию" onSearch={searchNameHandler} allowClear />

          <div className={styles.button}>
            <Button type="primary" onClick={handleOpenAdd}>
              Добавить исключение
            </Button>
          </div>
        </div>
        {isLoading ? (
          <div className={styles.center}>
            <Spin size="large" />
          </div>
        ) : (
          <Table
            className={styles.table}
            rowKey={record => record.id}
            bordered
            columns={[
              {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '5%',
              },
              {
                title: 'Слово',
                dataIndex: 'word',
                key: 'word',
              },
              {
                title: '',
                key: 'settings',
                render: (_, exception) => (
                  <Dropdown
                    overlayClassName={styles.menu}
                    placement="bottom"
                    menu={{
                      items: [
                        {
                          key: 'edit',
                          label: 'Редактировать',
                          onClick: () => handleEditException(exception),
                        },
                        {
                          key: 'remove',
                          label: 'Удалить',
                          onClick: () => setDeletingException(exception.id),
                        },
                      ],
                    }}>
                    <MoreOutlined />
                  </Dropdown>
                ),
                width: '3%',
              },
            ]}
            dataSource={exceptions?.items || []}
            pagination={{
              onChange: handleChangePage,
              current: exceptions?.pagination?.current_page || 1,
              pageSize: exceptions?.pagination?.per_page || PER_PAGE,
              total: exceptions?.pagination?.total || 0,
              showSizeChanger: true,
            }}
            locale={{ emptyText: <Empty description="Нет данных" /> }}
          />
        )}
      </Card>

      <MainModal isOpen={isOpenModal} closeHandler={handleCloseUpdate}>
        <WordModal
          title={editedException ? 'Изменение исключения' : 'Создание исключения'}
          initialValue={editedException?.word}
          closeHandler={handleCloseUpdate}
          formSubmitHandler={handleSubmitForm}
        />
      </MainModal>

      <ConfirmForm
        isOpen={!!deletingException}
        title="Вы действительно хотите удалить исключение?"
        closeHandler={() => setDeletingException(null)}
        confirmHandler={confirmDelete}
      />
    </div>
  );
};
