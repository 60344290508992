import { Link } from 'react-router-dom';
import { Button } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { PictureFilled } from '@ant-design/icons';
import { getFinalPrice , Utils } from 'src/shared/lib';

import { ChatTask, Characteristic } from '../../types';
import styles from './TaskInfo.module.less';

interface TaskInfoProps {
  task: ChatTask;
  characteristic: Characteristic;
}

export const TaskInfo = ({ task, characteristic }: TaskInfoProps) => {
  const taskImageSrc = task?.product_offer_image || null;
  const taskCode = task?.code || '-';
  const taskSize = characteristic?.value || '-';
  const taskRecievedCount = task?.received_count || task.received_count === 0 ? task.received_count : '-';
  const taskCount = task?.count || task.count === 0 ? task.count : '-';
  const taskProdName = task?.product_offer_name || '-';
  const taskCreated = task?.created_at ? Utils.getFormatDate(task.created_at, 'DD.MM.YYYY HH:mm') : '-';
  const taskPrice = task?.price ? getFinalPrice(task.price) : '-';
  const taskAmount = task?.amount ? getFinalPrice(task.amount) : '-';
  const taskStatus = task?.status_name || '-';

  return (
    <div className={styles.taskInfo}>
      <div className={styles.mainInfo}>
        <div className={styles.infoWrapper}>
          <Avatar shape="square" size={80} icon={<PictureFilled />} src={taskImageSrc} />
          <div className={styles.info}>
            <div className={styles.title}>
              <strong>Задание {taskCode}</strong>
              <strong>
                {taskRecievedCount}/{taskCount}
              </strong>
            </div>
            <p className={styles.name}>{taskProdName}</p>
            <span className={styles.name}>Размер: {taskSize}</span>
          </div>
        </div>
      </div>

      <div className={styles.infoBlock}>
        <p>
          <strong>Создано:</strong>
          {taskCreated}
        </p>
        <p>
          <strong>Цена за ед:</strong> {taskPrice}
        </p>
        <p>
          <strong>Сумма задания:</strong> {taskAmount}
        </p>
        <p>
          <strong>Статус:</strong> {taskStatus}
        </p>
      </div>

      {task?.id ? (
        <Link to={`/task/${task.id}`} state={{ prevPath: true }} target="_blank" rel="noreferrer">
          <Button>Открыть задание</Button>
        </Link>
      ) : null}
    </div>
  );
};
