import { createEvent, createStore, sample, createEffect } from 'effector';
import { Storage } from 'src/shared/lib';
import { Categories } from 'src/pages/profiles/suppliersProfile/ui/suppParser/ui/addSource/types';
import { Supplier } from 'src/pages/profiles/suppliers/types';
import { Paginated } from 'src/shared/types';
import { categoriesApi, suppliersApi } from 'src/shared/api';

import { initColumnSettings } from './config';

export const $categories = createStore<Categories | null>(null);
export const $suppliers = createStore<Paginated<Supplier> | null>(null);
export const $visibleColumns = createStore(initColumnSettings);
Storage.persist.entity($visibleColumns, { slice: 'products', key: 'tableSettings' });

export const initPage = createEvent();
export const submitChangeSuppData = createEvent<suppliersApi.SuppRequestData>();
export const changeVisibleColumns = createEvent<Record<string, boolean>>();

export const getCategoriesFx = createEffect(categoriesApi.getCategories);
export const getSuppliersFx = createEffect(suppliersApi.getSuppList);

$categories.on(getCategoriesFx.doneData, (_, data) => data);
$suppliers.on(getSuppliersFx.doneData, (_, data) => data);

sample({
  clock: initPage,
  target: getCategoriesFx,
});

sample({
  clock: submitChangeSuppData,
  target: getSuppliersFx,
});

sample({
  clock: changeVisibleColumns,
  target: $visibleColumns,
});
