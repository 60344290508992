import { createEffect, createEvent, createStore, sample } from 'effector';
import { useStore } from 'effector-react';
import { apiModel , personalApi } from 'src/shared/api';

import { Profile } from './types';

export const getProfileFx = createEffect(personalApi.getProfile);
export const getProfileEvent = createEvent();
export const clearProfileEvent = createEvent();

export const $profile = createStore<Profile | null>(null);
export const $isAdmin = $profile.map(profile => profile?.role === 'ADMIN');
export const $isReceiver = $profile.map(profile => profile?.role === 'RECEIVER');
export const $isStorekeeper = $profile.map(profile => profile?.role === 'STOREKEEPER');
export const $isGTS = $profile.map(profile => profile?.role === 'GTS');

$profile.on(getProfileFx.doneData, (_, data) => data);
$profile.on(clearProfileEvent, () => null);

sample({
  clock: getProfileEvent,
  target: getProfileFx,
});

sample({
  clock: apiModel.$isAuthorized,
  target: clearProfileEvent,
  filter: isAuth => !isAuth,
});

export const useProfile = () => useStore($profile);
export const useIsAdmin = () => useStore($isAdmin);
export const useIsReceiver = () => useStore($isReceiver);
export const useIsStorekeeper = () => useStore($isStorekeeper);
export const useIsGTS = () => useStore($isGTS);
