import React from 'react';
import { Link } from 'react-router-dom';
import { renderStatus } from 'src/pages/settings/logs/helpers';
import type { ColumnsType } from 'antd/es/table';
import { ShortenedTooltip } from 'src/entities/shortenedTooltip';
import { Utils } from 'src/shared/lib';
import { DateType } from 'src/shared/ui';

import { PostData } from './types';

export const statuses = {
  0: 'Успешно',
  1: 'Ошибка',
  2: 'Подготовка',
  3: 'Подготовка к деактивации',
  4: 'Деактивирован',
};

export const statusOptions = Object.entries(statuses).map(([key, value]) => ({
  label: value,
  value: key,
}));

export const defaultStatuses = { statuses: ['0', '1'] };

export const mainColumnName = 'description';

const maxTextLength = 100;

export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

const handleLink = (e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation();

export const columns: ColumnsType<PostData> = [
  {
    title: 'Текст поста',
    dataIndex: 'description',
    key: 'description',
    render: data => <ShortenedTooltip data={data} length={maxTextLength} />,
  },
  {
    title: 'Ошибка',
    dataIndex: 'error',
    key: 'error',
    render: data => <ShortenedTooltip data={data} length={maxTextLength} />,
  },
  {
    title: 'Источник',
    dataIndex: 'source',
    key: 'source',
    render: data =>
      data?.url ? (
        <a onClick={handleLink} href={data.url} target="_blank" rel="noreferrer">
          {data?.name || '-'}
        </a>
      ) : (
        '-'
      ),
  },
  {
    title: 'Поставщик',
    dataIndex: 'supplier',
    key: 'supplier',
    render: data =>
      data ? (
        <Link onClick={handleLink} to={`/profile/supplier/${data?.id}`} state={{ prevPath: true }}>
          {data?.name}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Ссылка на пост',
    dataIndex: 'url',
    key: 'url',
    render: data =>
      data ? (
        <a onClick={handleLink} href={data} target="_blank" rel="noreferrer">
          {data}
        </a>
      ) : (
        '-'
      ),
  },
  {
    title: 'Дата создания поста',
    dataIndex: 'post_date',
    key: 'post_date',
    render: (data: DateType) => (data ? Utils.dateFormat(data) : '-'),
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    render: data => (data || data === 0 ? renderStatus(data) : '-'),
  },
];

export const initColumnSettings = columns.reduce((prev, curr) => {
  if (curr.key) {
    prev[curr.key] = true;
  }
  return prev;
}, {} as Record<string, boolean>);
