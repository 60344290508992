import { Card, Tabs } from 'antd';
import { getFormattedPhone , Utils } from 'src/shared/lib';

import { typeOptions } from '../../config';
import { LegalEntityDetailed, LegalEntityCardType } from '../../types';
import styles from './LegalEntitiesProfilesCard.module.less';

interface ProfilesCardProps {
  data: LegalEntityDetailed;
  type: LegalEntityCardType;
}

export const LegalEntitiesProfilesCard = ({ data, type }: ProfilesCardProps) => {
  const renderCard = (type: LegalEntityCardType, data: LegalEntityDetailed) => {
    switch (type) {
      case LegalEntityCardType.ORGANIZATION:
        return (
          <div>
            <p className={styles.name}>Организация</p>
            <p className={styles.data}>
              <strong>Полное название:</strong> {data?.full_name || '-'}
            </p>
            <p className={styles.data}>
              <strong>Сокращенное название:</strong> {data?.name || '-'}
            </p>
            <p className={styles.data}>
              <strong>На иностранном:</strong> {data?.billing_descriptor || '-'}
            </p>
            <p className={styles.data}>
              <strong>ИНН:</strong> {data?.inn || '-'}
            </p>
            <p className={styles.data}>
              <strong>КПП:</strong> {data?.kpp || '-'}
            </p>
            <p className={styles.data}>
              <strong>Самозанятый: </strong> {data?.smz ? 'Да' : 'Нет'}
            </p>
            <p className={styles.data}>
              <strong>ОГРН:</strong> {data?.ogrn || '-'}
            </p>
          </div>
        );

      case LegalEntityCardType.ADDRESS:
        return (
          <div className={styles.address}>
            <p className={styles.name}>Адрес</p>
            {data?.addresses && data.addresses.length ? (
              <Tabs>
                {data.addresses.map(({ id, zip, country, city, street, type: addressType }, index) => (
                  <Tabs.TabPane tab={index + 1} key={id}>
                    <p className={styles.data}>
                      <strong>Тип адреса: </strong> {type ? typeOptions[addressType as keyof typeof typeOptions] : '-'}
                    </p>
                    <p className={styles.data}>
                      <strong>Почтовый индекс: </strong> {zip || '-'}
                    </p>
                    <p className={styles.data}>
                      <strong>Город или населенный пункт: </strong> {city || '-'}
                    </p>
                    <p className={styles.data}>
                      <strong>Улица, дом, корпус, квартира, офис: </strong> {street || '-'}
                    </p>
                    <p className={styles.data}>
                      <strong>Код страны по ISO:</strong> {country || '-'}
                    </p>
                  </Tabs.TabPane>
                ))}
              </Tabs>
            ) : (
              <p className={styles.emptyAddress}>Адреса не найдены</p>
            )}
          </div>
        );

      case LegalEntityCardType.CONTACTS:
        return (
          <div>
            <p className={styles.name}>Контакты</p>
            <p className={styles.data}>
              <strong>Телефон: </strong> {data?.ceo?.phone ? getFormattedPhone(data.ceo.phone) : '-'}
            </p>
            <p className={styles.data}>
              <strong>Email: </strong> {data?.email || '-'}
            </p>
            <p className={styles.data}>
              <strong>Веб-сайт: </strong> {data?.site_url ? <a href={data.site_url}>{data.site_url}</a> : '-'}
            </p>
          </div>
        );

      case LegalEntityCardType.CEO:
        return (
          <div>
            <p className={styles.name}>Руководитель</p>

            <p className={styles.data}>
              <strong>Имя:</strong> {data?.ceo?.first_name || '-'}
            </p>
            <p className={styles.data}>
              <strong>Фамилия:</strong> {data?.ceo?.last_name || '-'}
            </p>
            <p className={styles.data}>
              <strong>Отчество:</strong> {data?.ceo?.middle_name || '-'}
            </p>
            <p className={styles.data}>
              <strong>Дата рождения: </strong> {data?.ceo?.birth_date ? Utils.dateFormat(data.ceo.birth_date) : '-'}
            </p>
            <p className={styles.data}>
              <strong>Гражданство: </strong> {data?.ceo?.country ? data.ceo.country.toUpperCase() : '-'}
            </p>
          </div>
        );

      case LegalEntityCardType.BANK:
        return (
          <div>
            <p className={styles.name}>Счёт</p>
            <p className={styles.data}>
              <strong>Реквизиты счета: </strong> {data?.bank_account?.account || '-'}
            </p>
            <p className={styles.data}>
              <strong>Банк: </strong> {data?.bank_account?.bank_name || '-'}
            </p>
            <p className={styles.data}>
              <strong>БИК: </strong> {data?.bank_account?.bik || '-'}
            </p>
            <p className={styles.data}>
              <strong>Назначение платежа: </strong> {data?.bank_account?.details || '-'}
            </p>
            <p className={styles.data}>
              <strong>Комиссия маркетплейсу: </strong>
              {data?.bank_account?.tax || data?.bank_account?.tax === 0 ? `${data.bank_account.tax}%` : '-'}
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Card className={styles.card}>
      <div className={styles.body}>{renderCard(type, data)}</div>
    </Card>
  );
};
