import { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { Title } from 'src/shared/ui';
import { removeWordsFromString } from 'src/shared/lib';

import { undesirableWords } from '../../config';
import styles from './EditPavilions.module.less';

interface EditPavilionsProps {
  editingItem: string;
  confirmHandler: ({ name }: { name: string }) => void;
  closeHandler: () => void;
}

export const EditPavilions = ({ editingItem, confirmHandler, closeHandler }: EditPavilionsProps) => {
  const [form] = Form.useForm();
  const resultItem = editingItem ? removeWordsFromString(undesirableWords, editingItem) : '';

  useEffect(() => {
    form.setFieldsValue({ name: resultItem });
  }, [form, resultItem]);

  const formSubmitHandler = (values: { name: string }) => {
    confirmHandler(values);
    closeHandler();
  };

  const handleCancelClose = () => {
    form.resetFields();
    closeHandler();
  };

  return (
    <div className={styles.editPavilions}>
      <Title>Редактирование</Title>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ name: resultItem }}
        onFinish={formSubmitHandler}
        autoComplete="off">
        <Form.Item
          label="Название"
          name="name"
          rules={[{ required: true, message: 'Пожалуйста, введите новое название' }]}>
          <Input />
        </Form.Item>

        <div className={styles.buttons}>
          <Form.Item>
            <Button className={styles.button} htmlType="submit" type="primary">
              Готово
            </Button>
          </Form.Item>

          <Button onClick={handleCancelClose} className={styles.button}>
            Отмена
          </Button>
        </div>
      </Form>
    </div>
  );
};
