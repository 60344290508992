import { Button, Table } from 'antd';

import styles from './AnalyticsTopProviderTable.module.less';
import { dataMock, columns } from './config';

export const AnalyticsTopProviderTable = () => (
  <article className={styles.AnalyticsTopProviderTable}>
    <div className={styles.AnalyticsTopProviderTableHeader}>
      <h3>ТОП Поставщиков</h3>
      <Button onClick={() => { }}>Сформировать отчет</Button>
    </div>

    <Table
      rowKey={record => record.id}
      columns={columns}
      dataSource={dataMock}
    />

    <div className={styles.AnalyticsTopProviderTableFooter}>
      <Button type="link" onClick={() => { }}>Посмотреть все</Button>
    </div>
  </article>
);