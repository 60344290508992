import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { Hooks , getFinalFilterPrice } from 'src/shared/lib';
import { Table, Spin, Input, Tooltip, Empty } from 'antd';
import { TableSettings } from 'src/entities/tableSettings';
import { ClearOutlined } from '@ant-design/icons';
import { NumberTypeEnum } from 'src/shared/types';
import { ColumnsType } from 'antd/es/table';


import {
  $tasks,
  $clients,
  $clientsData,
  $visibleColumns,
  initPage,
  $tasksStatusOptions,
  changeVisibleColumns,
  submitChangeClientsData,
  getTasksFx,
  submitChangeRequestData,
  $pageSize,
  changePageSize,
} from './model';
import { columns, PER_PAGE, mainColumnName } from './config';
import { Task } from './types';
import styles from './TasksList.module.less';

interface TaskListProps {
  status?: number;
}

export const TaskList = ({ status }: TaskListProps) => {
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const pageSize = useStore($pageSize);
  const visibleColumnsKeys = useStore($visibleColumns);
  const [nameValue, setNameValue] = useState('');
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const tasks = useStore($tasks);
  const taskOptions = useStore($tasksStatusOptions);
  const clientsData = useStore($clientsData);
  const clients = useStore($clients);
  const isLoading = useStore(getTasksFx.pending);
  const [searchParams, setSearchParams] = Hooks.useSearchParamsObject();

  useEffect(() => {
    initPage();
  }, []);

  useEffect(() => {
    const currPage = searchParams.page ? +searchParams.page : 1;
    const { page, statuses, ...filters } = searchParams;
    const statusesArray = statuses ? statuses.split(',') : [];
    const reqData = !statusesArray.length ? { status } : { statuses: statusesArray };

    submitChangeRequestData({
      ...filters,
      ...reqData,
      pagination: { page: currPage, per_page: pageSize },
    });
  }, [searchParams, pageSize, status]);

  useEffect(() => {
    const resultVisibleColumns: ColumnsType<Task> = columns.filter(
      column => column.key && (!(column.key in visibleColumnsKeys) || visibleColumnsKeys[column.key]),
    );
    setVisibleColumns(resultVisibleColumns);
  }, [visibleColumnsKeys]);

  const handleSelectClient = (value: number) => {
    setSearchParams({ ...searchParams, client_id: value.toString() || null, page: 1 });
  };

  const handleSelectStatus = (value: string[]) =>
    setSearchParams({ ...searchParams, statuses: value || null, page: 1 });

  const searchFieldHandler = (value: string, type: string) => {
    if (value) {
      const resultValue = type === 'price' ? getFinalFilterPrice(value) : value;
      setSearchParams({ ...searchParams, [type]: resultValue, page: 1 });
    } else {
      setSearchParams({ ...searchParams, [type]: null, page: 1 });
    }
  };

  const searchCreatedDateHandler = (value: string) => {
    setSearchParams({ ...searchParams, created_at: value ? new Date(value).toISOString() : null, page: 1 });
  };

  const searchClientHandler = (value: string) => {
    if (value) {
      submitChangeClientsData({ ...clientsData, name: value });
    }
  };

  const resetClientHandler = () => {
    setSearchParams({ ...searchParams, client_id: null, page: 1 });
  };

  const handleChangePage = (value: number) => {
    setSearchParams({ ...searchParams, page: value.toString() });
  };

  const handleChangePageSize = (currentSize: number, size: number) => {
    changePageSize(size);
  };

  const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => setNameValue(e.target.value);

  const searchNameHandler = (value: string) => {
    setSearchParams({ ...searchParams, code: value || null, page: 1 });
  };

  const handleResetFilters = () => {
    setSearchParams({ page: 1 });
    setIsActiveFilter(true);
    setNameValue('');
  };

  const getPriceSearch = Hooks.useColumnSearch<Task>(
    searchFieldHandler,
    NumberTypeEnum.PRICE,
    isActiveFilter,
    setIsActiveFilter,
    searchParams.price,
  );
  const getCountSearch = Hooks.useColumnSearch<Task>(
    searchFieldHandler,
    NumberTypeEnum.COUNT,
    isActiveFilter,
    setIsActiveFilter,
    searchParams.count,
  );
  const getClientSearch = Hooks.useColumnAutocomplete<Task>(
    searchClientHandler,
    handleSelectClient,
    resetClientHandler,
    clients?.items,
    isActiveFilter,
    setIsActiveFilter,
  );
  const getDateSearch = Hooks.useColumnSearch<Task>(
    searchCreatedDateHandler,
    'date',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.created_at,
  );

  const getStatusSearch = Hooks.useColumnMultiSelect<Task>(
    handleSelectStatus,
    taskOptions,
    'Выбрать статус',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.status,
  );

  const filteredColumns: ColumnsType<Task> = visibleColumns.map(column => {
    switch (column.key) {
      case 'price':
        return { ...column, ...getPriceSearch(column.key, column.title) };
      case 'client':
        return { ...column, ...getClientSearch(column.key, column.title) };
      case 'count':
        return { ...column, ...getCountSearch(column.key, column.title) };
      case 'created_at':
        return { ...column, ...getDateSearch(column.key, column.title) };
      case 'status':
        return { ...column, ...getStatusSearch(column.key, column.title) };
      default:
        return column;
    }
  });

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.tasksList}>
      <div className={styles.searchSettings}>
        <Input.Search
          value={nameValue || ''}
          className={styles.search}
          placeholder="Поиск"
          onChange={handleSearchName}
          onSearch={searchNameHandler}
          allowClear
        />
        <div className={styles.options}>
          <Tooltip overlayStyle={{ position: 'fixed' }} title="Очистить все фильтры">
            <ClearOutlined className={styles.clear} onClick={handleResetFilters} />
          </Tooltip>

          <TableSettings
            disabledColumns={[mainColumnName]}
            columns={columns}
            visibleColumnsKeys={visibleColumnsKeys}
            setVisibleColumnsKeys={changeVisibleColumns}
          />
        </div>
      </div>
      <Table
        rowKey={record => record.id}
        bordered
        columns={filteredColumns}
        dataSource={tasks?.items || []}
        scroll={{ x: 1200 }}
        pagination={{
          onChange: handleChangePage,
          onShowSizeChange: handleChangePageSize,
          current: tasks?.pagination?.current_page || 1,
          pageSize: tasks?.pagination?.per_page || PER_PAGE,
          total: tasks?.pagination?.total || 0,
          showSizeChanger: true,
        }}
        locale={{ emptyText: <Empty description="Нет данных" /> }}
      />
    </div>
  );
};
