import { Button, Form, Input, Space } from 'antd';
import { MainModal, Title } from 'src/shared/ui';

import { CourierDates } from '../types';
import styles from './AskCourierModal.module.less';

interface ModalProps {
  isOpen: boolean;
  closeHandler: () => void;
  onSubmit: (data: CourierDates) => void;
}

export const AskCourierModal = ({ isOpen, closeHandler, onSubmit }: ModalProps) => {
  const formSubmitHandler = (data: CourierDates) => {
    onSubmit({
      fromDate: data.fromDate ? new Date(data.fromDate).toISOString() : '',
      toDate: data.toDate ? new Date(data.toDate).toISOString() : '',
    });
    closeHandler();
  };

  return (
    <MainModal isOpen={isOpen} closeHandler={closeHandler}>
      <div className={styles.askCourier}>
        <Title>Выберите временные рамки для курьера</Title>
        <Form onFinish={formSubmitHandler} autoComplete="off" layout="vertical">
          <Form.Item label="Дата с" name="fromDate">
            <Input type="datetime-local" max="9999-12-31T00:00" />
          </Form.Item>
          <Form.Item label="Дата по" name="toDate">
            <Input type="datetime-local" max="9999-12-31T00:00" />
          </Form.Item>
          <Space>
            <Form.Item>
              <Button htmlType="submit" type="primary">
                Готово
              </Button>
            </Form.Item>
            <Form.Item>
              <Button onClick={closeHandler} htmlType="button">
                Отмена
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </div>
    </MainModal>
  );
};
