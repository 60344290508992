import { Navigate } from 'react-router-dom';
import { useIsAuth } from 'src/shared/api/model';
import { AuthForm } from 'src/features/authForm/ui';
import { Card } from 'antd';
import { useIsGTS } from 'src/widgets/header/model';

import styles from './Login.module.less';

export const LoginPage = () => {
  const isAuth = useIsAuth();
  const isGTS = useIsGTS();

  if (isAuth) {
    return isGTS ? <Navigate to='/reception-point' /> : <Navigate to='/orders' />;
  }
  return (
    <section className={styles.loginPage}>
      <Card className={styles.card}>
        <AuthForm />
      </Card>
    </section>
  );
};
