import { createEffect, createEvent, createStore, sample } from 'effector';
import { tasksApi , clientsApi } from 'src/shared/api';
import { Storage } from 'src/shared/lib';
import { Paginated , SelectOptions } from 'src/shared/types';
import { Client } from 'src/pages/profiles/clients/types';
import { initState as clientsInitState } from 'src/pages/profiles/clients/config';

import { IncomeTasks, Task } from './types';
import { defaultData, initState, initColumnSettings, PER_PAGE } from './config';
import { OrderTaskStatuses } from '../ordersList/types';


export const $tasks = createStore<Paginated<Task> | null>(null);
export const $tasksStatusOptions = createStore<SelectOptions>([]);
export const $clients = createStore<Paginated<Client> | null>(null);
export const $requestData = createStore<tasksApi.TasksRequestData>(initState);
export const $clientsData = createStore<clientsApi.ClientsRequestData>(clientsInitState);
export const $visibleColumns = createStore(initColumnSettings);
Storage.persist.entity($visibleColumns, { slice: 'tasks', key: 'tableSettings' });
export const $pageSize = createStore<number>(PER_PAGE);
Storage.persist.entity($pageSize, { slice: 'tasks', key: 'pageSize' });

export const submitChangeRequestData = createEvent<tasksApi.TasksRequestData>();
export const submitChangeClientsData = createEvent<clientsApi.ClientsRequestData>();
export const changeVisibleColumns = createEvent<Record<string, boolean>>();
export const changePageSize = createEvent<number>();
export const initPage = createEvent();

export const getTasksFx = createEffect(tasksApi.getTasksList);
export const getTaskStatusesFx = createEffect(tasksApi.getTaskStatuses);
export const getClientsFx = createEffect(clientsApi.getClientsList);

$tasks.on(getTasksFx.doneData, (_, data: IncomeTasks) => ({
  pagination: data.pagination,
  items: data.items.map(order => ({
    ...order,
    client: data.clients[order.client_id],
    supplier: data.suppliers[order.supplier_id],
  })),
}));
$tasks.on(getTasksFx.failData, () => defaultData);
$clients.on(getClientsFx.doneData, (_, data) => data);
$clientsData.on(submitChangeClientsData, (_, data) => data);
$requestData.on(submitChangeRequestData, (_, data) => data);
$tasksStatusOptions.on(getTaskStatusesFx.doneData, (_, data: OrderTaskStatuses) => {
  const statusOptions =
    data?.items.map(item => ({
      value: item?.status || item?.status === 0 ? item.status.toString() : '',
      label: item?.status_name || '',
    })) || [];
  return statusOptions;
});

sample({
  clock: initPage,
  target: getTaskStatusesFx,
});

sample({
  clock: changeVisibleColumns,
  target: $visibleColumns,
});

sample({
  clock: submitChangeRequestData,
  source: $requestData,
  target: getTasksFx,
});

sample({
  clock: submitChangeClientsData,
  source: $clientsData,
  target: getClientsFx,
});

// change page size

sample({
  clock: changePageSize,
  target: $pageSize,
});
